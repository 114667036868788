// http.js

//引入axios
import axios from 'axios'
// import {VUE_APP_BASE_API} from './env';
import Vue from 'vue';
import {Message} from 'element-ui';
// import store from '../store/index';// store为vuex，如不需要可删除相关代码
let store = {};

Vue.component(Message.name, Message);

// 设置请求url
axios.defaults.baseURL =  'https://shopapi.slzxgd.com/slgd/api';
// axios.defaults.baseURL =  'http://192.168.1.7:50002/slgd/api';

axios.defaults.paramsSerializer = (params) => {
  return Object.keys(params).filter(it => {
    return params.hasOwnProperty(it)
  }).reduce((pre, curr) => {
    return params[curr] !== '' && params[curr] !== null && params[curr] !== undefined ? (pre ? pre + '&' : '') + curr + '=' + encodeURIComponent(params[curr]) : pre;
  }, '');
};



// axios.defaults.withCredentials=true;
//设置默认请求头
axios.defaults.headers = {
    'X-Requested-With': 'XMLHttpRequest',
}
// 设置请求超时时间
axios.defaults.timeout = 10000;

let cancel ,promiseArr = {}
const CancelToken = axios.CancelToken;
// let overTime = true;
//请求拦截器
axios.interceptors.request.use(config => {
    //发起请求时，取消掉当前正在进行的相同请求
    // if(config.dis)store.commit('changeDis' ,true);
    if (promiseArr[`${config.baseURL}${config.url}`] && config.abort) {
        // promiseArr[config.url]('请勿重复点击');
        promiseArr[`${config.baseURL}${config.url}`]();
        // if(config.abort)store.commit('changeShowTip' ,true);

    } else {
        promiseArr[`${config.baseURL}${config.url}`] = cancel
    }

    return config
}, error => {
    return Promise.reject(error)
})

//响应拦截器即异常处理
axios.interceptors.response.use(response => {
//   if(response.config && response.config.dis){
//     store.commit('changeDis' ,false);
//   }
  promiseArr[response.config.url] = null;
  return response.data
}, err => {
  console.log(err);
//   store.commit('changeDis' ,false);
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '错误请求'
          break;
        case 401:
          err.message = '未授权，请重新登录'
          break;
        case 403:
          window.location.href='/#/login';
          sessionStorage.clear();
          err.message = '登陆已过期,请重新登陆'
          break;
        case 404:
          err.message = '请求错误,未找到该资源'
          break;
        case 405:
          err.message = '请求方法未允许'
          break;
        case 408:
          err.message = '请求超时'
          break;
        case 500:
          err.message = '服务器端出错'
          break;
        case 501:
          err.message = '网络未实现'
          break;
        case 502:
          err.message = '网络错误'
          break;
        case 503:
          err.message = '服务不可用'
          break;
        case 504:
          err.message = '网络超时'
          break;
        case 505:
          err.message = 'http版本不支持该请求'
          break;
        default:
          err.message = `连接错误${err.response.status}`
      }
    } else {
      if(err.message !=="请勿重复点击") {
        err.message = "连接到服务器失败"
      }
    }
    
    // if (err.message !=="请勿重复点击") {
    //   if(overTime && !store.state.isShowTip) {
    //     overTime = false;
    //     store.commit('changeShowTip' ,false);
    //     window.setTimeout(() => {
    //       overTime = true;
    //     }, 10000)
    //     Message({
    //       type: 'error',
    //       message: err.message
    //     })
    //   }

    // }
    Message({
        type: 'error',
        message: err.message
      })
    

      if (err.message ==="请勿重复点击") {
        return false;
      } else {
        return Promise.resolve(err.response)

      }

})

export default axios

  //get请求
  export const  get = (url,params={}, config={}) =>{
    if(sessionStorage.token)params = Object.assign(params,{token:sessionStorage.token}); // 这个是用来带登录信息了，我是放在请求地址上，这个需要你们自己根据你们的项目做不同的调整
    return new Promise((resolve,reject) => {
        axios({
          method: 'get',
          url,
          params,
          // ...config,
          cancelToken: new CancelToken(c => {
            cancel = c
          }),
        }).then(res => {
        //   if(config.dis)store.commit('changeDis' ,false);
          if (res) {
            const data = res.data ? res.data : res;
            resolve(data)
          } else {
            resolve({})

          }
        }).catch(err => {

          reject(err);
        })
      })
    };
  //post请求
  export const post = (url,data={}, config={}) =>{
    // if(sessionStorage.token)url+=`?token=${sessionStorage.token}`; // 这个是用来带登录信息了，我是放在请求地址上，这个需要你们自己根据你们的项目做不同的调整
    return new Promise((resolve,reject) => {
        axios({
          method: 'post',
          url,
          data,
          // ...config,
          cancelToken: new CancelToken(c => {
            cancel = c
          })
        }).then(res => {
          if(config.dis)store.commit('changeDis',false);

          if (res) {
            const data = res.data ? res.data : res;
            resolve(data);
          } else {
            resolve({})

          }

        }).catch(err => {
          reject(err);
        })
      })
     }


