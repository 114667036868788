<template>
  <div class="jt-foot-container" :class="{ 'white': flag }">
    <div class="jt-footbg contentBox ">
      <div class="jt-foottop">
        <div class="jt-foottop-l">
          <div class="jt-foottop-l-item" v-for="(item, indb) in navList" :key="indb">
            <div class="jt-foottop-l-item-tt">{{ item.name }}</div>
            <div class="line"></div>
            <div class="jt-foottop-l-item-son" v-for="(items, inds) in item.children" :key="inds"
              @click="goPages(items)">{{ items.name }}</div>
          </div>
          <div class="jt-foottop-l-item">
            <div class="jt-foottop-l-item-phone">TEL: 0769-8282-7524
            </div>
            <div class="address">{{ $t("common.base.address") }}</div>
            <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/code2.jpg"
              class="jt-foottop-l-item-code" />
          </div>
        </div>
      </div>
      <div class="jt-footmid">
        <div class="jt-footmid-t1">{{ $t("common.base.foot_compay") }}:</div>
        <div class="jt-footmid-t2">
          <div v-for="(item, ind) in listCompany" :key="ind">{{ item.name }}</div>
        </div>
      </div>
      <div class="jt-footbtm">
        <div>© CopyRight 2023 {{ $t("common.base.shengli") }} All Rights Reserved <span
            class="ml10">广东盛利高新能源科技有限公司</span></div>
        <div>备案号：<a class="gray747" href="https://beian.miit.gov.cn/"
            target="_blank">粤ICP备2023056569号-1</a></div>
      </div>
    </div>
  </div>
</template>

<script>
import { categoryList } from "@/utils/api";
export default {
  name: "PcPage",
  props: {
    category: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      flag: '',
      categoryLists: []
    };
  },
  computed: {
    navList() {
      return [
        {
          // name: '关于盛利',
          name: this.$t("common.base.nav_aboutus"),
          url: "/introduction",
          children: [
            {
              name: this.$t("common.base.nav_aboutus_intoduction"),
              url: "/introduction",
            },
            {
              name: this.$t("common.base.nav_aboutus_course"),
              url: "/course",
            },
            {
              name: this.$t("common.base.nav_aboutus_certificate"),
              url: "/certificate",
            },
            {
              name: this.$t("common.base.nav_aboutus_culture"),
              url: "/culture",
            },
          ],
        },
        {
          name: this.$t("common.base.nav_products"),
          url: "/product?type=0",
          children: this.category
        },
        {
          name: this.$t("common.base.nav_news"),
          url: "/news?type=0",
          children: [
            {
              name: this.$t("common.base.nav_news_companynews"),
              url: "/news?type=1",
            },
            {
              name: this.$t("common.base.nav_news_technical"),
              url: "/news?type=2",
            },
            {
              name: this.$t("common.base.nav_news_problem"),
              url: "/news?type=3",
            },
          ],
        },
        {
          name: this.$t("common.base.nav_resources"),
          url: "/source",
          children: [
            {
              name: this.$t("common.base.nav_resources_talent"),
              url: "/source",
            },
            {
              name: this.$t("common.base.nav_resources_social"),
              url: "/social",
            },
            {
              name: this.$t("common.base.nav_resources_campus"),
              url: "/campus",
            },
            {
              name: this.$t("common.base.nav_resources_staff"),
              url: "/man",
            },
          ],
        },
        {
          name: this.$t("common.base.nav_contact"),
          url: "/contact",
          children: [
            {
              name: this.$t("common.base.nav_contact_contact"),
              url: "/contact",
            },
            {
              name: this.$t("common.base.nav_contact_message"),
              url: "/message",
            },
          ],
        },
      ];
    },
    listCompany() {
      return [
        {
          name: this.$t("common.base.foot_compay_name1"),
        },
        {
          name: this.$t("common.base.foot_compay_name2"),
        },
        {
          name: this.$t("common.base.foot_compay_name3"),
        },
        {
          name: this.$t("common.base.foot_compay_name4"),
        },
        {
          name: this.$t("common.base.foot_compay_name5"),
        },
        // {
        //   name: this.$t('common.base.foot_compay_name6')
        // },
        // {
        //   name: this.$t('common.base.foot_compay_name7')
        // },
        // {
        //   name: this.$t('common.base.foot_compay_name8')
        // },
      ];
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        if (route.path == '/') {
          this.flag = true
        } else {
          this.flag = false
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    // this.getCategoryList()
  },
  methods: {
    getCategoryList() {
      this.categoryLists = []
      categoryList({ language: this.$i18n.locale }).then(res => {
        if (res.code === "000000") {
          res.data.forEach(item => {
            item.child.forEach(it => {
              this.categoryLists.push({
                name: it.productTypeName,
                id: it.id,
                sort: it.sort
              })
            })
          });
          this.categoryLists.forEach((item, index) => {
            item.url = `/product?type=${index + 1}`
          })
          this.categoryLists.sort((a, b) => a.sort - b.sort)
          this.categoryLists.pop()
          console.log(this.categoryLists, 'categoryLists');
        }
      })
    },
    goPages(item) {
      this.$router.push({
        path: item.url,
        query: {
          id: item.id
        }
      });
    },
  },
  created() {
    console.log('this.$router.fullPath', this.$router.history.current.fullPath)
  }
};
</script>

<style lang="scss" scoped>
.white {
  background: #fff !important;
}

.gray747 {
  color: #747A90
}

.ml10 {
  margin-left: 10px
}

.jt-foot-container {
  background: #F9F9F9;
  text-align: center;
  color: #747a90;
  padding-top: 50px;
}

.jt-footbg {
  .jt-foottop {
    display: flex;

    .jt-foottop-l {
      display: flex;
      text-align: left;

      .jt-foottop-l-item {
        width: 235px;
        min-height: 215px;

        .address {
          font-size: 12px;
          font-weight: 400;
          color: #747a90;
          width: 175px;
          display: -webkit-box;
          /* Safari */
          -webkit-line-clamp: 2;
          /* Safari and Chrome */
          -webkit-box-orient: vertical;
          /* Safari and Chrome */
          overflow: hidden;
          margin-bottom: 12px;
          line-height: 23px;
        }

        .jt-foottop-l-item-code {
          width: 88px; 
          height: 88px; 
          display: block
        }

        .jt-foottop-l-item-tt {
          font-size: 14px;
          font-weight: 600;
          color: #060606;
        }

        .line {
          width: 17px;
          height: 4px;
          background: #36bf6a;
          border-radius: 3px;
          margin: 10px 0;
        }

        .jt-foottop-l-item-son {
          font-size: 12px;
          font-weight: 400;
          color: #747a90;
          line-height: 28px;
          cursor: pointer;
        }

        .jt-foottop-l-item-phone {
           color: #36bf6a; 
           font-size: 18px; 
           font-weight: bold; 
           margin-bottom: 6px
        }
      }
    }

    .jt-foottop-r {
      flex: 1;
      display: flex;

      .jt-foottop-r-n {
        flex: 1;
        opacity: 0;
      }

      .jt-foottop-r-l {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .jt-foottop-r-la {
          display: flex;
          align-items: center;

          img {
            width: 0.16rem;
            height: 0.16rem;
            display: block;
            margin-right: 0.08rem;
          }

          div {
            font-size: 0.14rem;
            color: #747a90;
          }
        }

        .jt-foottop-r-lb {
          font-size: 0.2rem;
          color: #747a90;
          font-weight: bold;
          margin-top: 0.06rem;
          margin-bottom: 0.14rem;
        }

        .jt-foottop-r-lc {
          font-size: 0.14rem;
          color: #747a90;
          text-align: right;
        }
      }

      .jt-foottop-r-r {
        margin-left: 0.2rem;

        img {
          width: 1.2rem;
          height: 1.2rem;
          display: block;
        }

        div {
          font-size: 0.14rem;
          color: #747a90;
        }
      }
    }
  }

  .jt-footmid {
    font-size: 0.18rem;
    color: #747a90;
    display: flex;
    border-bottom: 1px solid #F3F1F1;
    padding-bottom: 8px;

    .jt-footmid-t1 {
      font-weight: bold;
      font-size: 14px;
      color: #060606;
      line-height: 29px;
      margin-right: 20px;
    }

    .jt-footmid-t2 {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      div {
        font-size: 12px;
        margin-right: 40px;
        line-height: 29px;
      }
    }
  }

  .jt-footbtm {
    display: flex;
    align-items: center;
    margin-top: 24px;
    justify-content: space-between;

    div {
      font-size: 12px;
      color: #747A90;
    }
  }
}
</style>
