// common模块里面的base2部分(页面)数据
const info = {
  ZH: {
    // 中文
    testC: "测试文字C",
    ideaTitle1: "做到事业留人",
    ideaTitle2: "做到待遇留人",
    ideaTitle3: "做到文化留人",
    ideas: "人才理念",
    unfold_details:'展开详情',
    pack_details:'收起详情',
    ideaDesc1: "公司坚持人尽其才，人尽其能的用人原则，为人才的成长营造一个公平竞争的平台和实现自身价值的发展空间",
    ideaDesc2: "公司按照不同岗位的特点，执行不同分配模式，为不同层次人才提供相应的公司报酬，管理通道、专业技术通道双通道发展",
    ideaDesc3: "留住优秀的员工，用心留人。这里的用心，不仅仅是领导的情感关怀，还包括平时很多的活动，贯穿在工作中和管理中。",
    mainLocation:'核心定位',

    testD: "测试文字D",
    msgName: "姓名",
    msgMail: "邮箱",
    msgPhone: "手机",
    msgAddress: "地址",
    msgNote: "介绍",
    msgYesBtn: "提交",
    msgNullBtn: "重置",
    msgBotText:
      "温馨提示：请您填写真实的姓名和联系方式，专业客服团队将在15分钟内联系您",

    list5: [
      {
        title: "湖南盛利高新能源科技有限公司",
        phone: "TEL：0746-263-6988",
        address: "地址：湖南省永州市江华县高新区兴业北路9号",
      },
      {
        title: "湖南盛武能源科技有限公司",
        phone: "TEL：0746-263-6988",
        address: "地址：湖南省永州市江华县高新区兴业北路9号",
      },
      {
        title: "东莞市盛利能源科技有限公司(总部)",
        phone: "TEL：0769-8282-7542",
        address: "地址：广东省东莞市常平镇桥沥南门路40号4栋101室",
      },
    ],
    list4: [
      "他们在本职岗位上，既创造了丰富的物质价值，也创造了宝贵的精神财富，在他们身上，汇聚着盛利集团一线普通员工的闪光点,体现中华文化“做人做事”文化理念塑造人、感染人的人才队伍建设成果。",
      "让我们从这些最美的一线员工的成长历程与故事中，寻找平凡岗位上的感动，在感动中相互学习借鉴。",
    ],
   
    list3: [
      {
        title: "初级JAVA工程师",
        content: "深圳 / 技术类 / 本科 / 2022-05-05",
        bol: false,
        detail: `
        \n  职位描述：  

        \n1、有一定的 java 开发经验； 

        \n2、 Java 基础扎实，熟悉面向对象和设计模式；
        
        \n3、 数据库基础扎实，掌握数据数据库优化基本技巧； 
        
        \n4、 熟悉 SpringMVC 、 Spring Boot 、 Spring Cloud 、 Spring 、 Mybatis 、 Hibernate 等常用框架； 
        
        \n5、 熟练掌握 mysql 、 oracle 、 mongodb 等主流数据存储系统； 
        
        \n6、 有大型互联网公司经验或大型分布式系统开发经验者优先，熟悉分布式消息队列（ RabbitMQ 、 RocketMQ 等），分布 式缓存（ Redis 、 Memcached ）， zookeeper 等； 
        
        \n7、 对技术有强烈的兴趣，喜欢钻研，具有良好的学习能力，沟通技能和团队协作能力； 
        
        \n8、有税友，金碟，航天金税，神码，中软从事税务方面工作优先考虑。
        
        
        
        \n岗位要求：

        \n1、5 年及以上 java 开发经验； 

        \n2、 Java 基础扎实，熟悉面向对象和设计模式；

        \n3、 数据库基础扎实，掌握数据数据库优化基本技巧； 

        \n4、 熟悉 SpringMVC 、 Spring Boot 、 Spring Cloud 、 Spring 、 Mybatis 、 Hibernate 等常用框架； 

        \n5、 熟练掌握 mysql 、 oracle 、 mongodb 等主流数据存储系统； 

        \n6、 有大型互联网公司经验或大型分布式系统开发经验者优先，熟悉分布式消息队列（ RabbitMQ 、 RocketMQ 等），分布 式缓存（ Redis 、 Memcached ）， zookeeper 等； 

        \n7、 对技术有强烈的兴趣，喜欢钻研，具有良好的学习能力，沟通技能和团队协作能力； 

        \n8、有税友，金碟，航天金税，神码，中软从事税务方面工作优先考虑。`,
      },
      {
        title: "初级WEB工程师",
        content: "深圳 / 技术类 / 本科 / 2022-05-05",
        bol: false,
        detail: `
        \n  职位描述：  

        \n1. 与交互设计师及视觉设计师合作实现交互效果，与后端工程师共同完成应用开发；

        \n2. 实现海量用户产品一流的Web界面，优化代码并保证良好兼容性；
        
        \n3. 设计并实现海量用户产品的交互，提高网页性能和用户体验； 
        
        \n4. 精通各种Web前端技术（HTML/CSS/Javascript等)，熟练跨浏览器、跨终端的开发；
        
        
        
        \n岗位要求：

        \n1. 3年以上相关工作经验，有大型网站开发经验；

        \n2. 掌握HTML5/JavaScript/CSS3等Web开发技术； 
        
        \n3. 移动端H5开发，有页面性能优化经验； 
        
        \n4. 熟练掌握模块化开发，有Vue/React/Weex 等开发经验优先； 
        
        \n5. 有Node后端开发/Flutter/小程序项目经验者优先； 
        
        \n6. 熟练掌握前端构建工具如gulp、webpack； 
        
        \n7. 熟悉http的基本工作原理； 
        
        \n8. 前端基础知识牢靠，能手写原生代码； 
        
        \n9. 对Web前台的性能优化以及Web常见漏洞有一定的理解和相关实践； 
        
        \n10. 主动性强，有敬业精神，勇于面对困难和接受挑战； 
        
        \n11. 有良好的团队协作能力和沟通能力；
        
        \n12.有IE及谷歌浏览器插件开发者优先考虑。`,
      },
      {
        title: "初级产品经理",
        content: "深圳 / 技术类 / 本科 / 2022-05-05",
        bol: false,
        detail: `
        \n  职位描述：  

        \n负责报销产品（报销申请、合同管理、项目管理、预算管理、差旅管理等）的相关工作，日常工作包含并不限于以下方面：

        \n1、需求规划：负责公司预算费控产品的需求规划，整理来自公司战略规划、客户方的需求、项目需求等，识别需求价值点，并合理规划优先级。 

        \n2、解决方案设计：收集业务方、客户方产品使用场景及业务诉求，合理设计功能解决方案，并推动方案落地。 

        \n3、推进实现： 根据产品需求定义，驱动开发人员完成功能落地，跟进日常开发过程中产生的具体问题，并完成产品功能验收。

        \n4、专业的洞察能力，持续优化方案、方法论和工具，提升用户体验。
        
        
        
        \n岗位要求：

        \n1、本科及以上学历，计算机相关专业。

        \n2、沟通及推进能力强，能够独自与合作伙伴方完成业务对接并驱动开发将产品方案落地。

        \n3、有独立思考能力，提问能力，有自己的解决未知问题的方法论。

        \n4、清晰的逻辑思维能力，抗压能力强，较强的自我驱动和创新意识 。

        \n5、有核算经验、合同管理经验、SAAS经验、税务业务、发票业务、应收业务、财务共享、企业服务、企业管理咨询经验优先。`,
      },
      {
        title: "销售助理",
        content: "深圳 / 技术类 / 专科 / 2022-05-05",
        bol: false,
        detail: `
        \n  职位描述： 
        
        \n1、负责营销部门全流程进行跟踪，协助部门总监做项目立项规划，商务报价、招投标、合同跟进，发票邮寄，业务结算等工作；

        \n2、协助营销部门处理日常事务及维护客户关系；
        
        \n3、负责整理客户资料，建立客户档案；
        
        \n4、协助上级完成其他临时性工作（部门例会的安排、会议纪要等）。
        
        
        
        \n岗位要求：

        \n1、大专及以上学历，计算机或工商管理专业优先；

        \n2、熟悉计算机软件行业，对助理工作有一定的了解； 
        
        \n3、有企业客户服务工作经验； 
        
        \n4、良好的沟通能力、自我管理能力，能够协助部门的推进及销售过程管理；  
        
        \n5、有责任心，上进心，有一定抗压能力，勤奋，不甘于平凡； 
        
        \n6、具备2年以上部门助理，销售助理，客服助理工作经验优先。`,
      },
      {
        title: "网络营销专员",
        content: "深圳 / 技术类 / 本科 / 2022-05-05",
        bol: false,
        detail: `
        \n职位描述：  

        \n1.通过网络、电话的渠道开发客户。

        \n2.帮助客户与公司产品服务搭建桥梁。

        \n3.让客户充分了解公司与产品完成销售目标。
        
        
        
        \n岗位要求：

        \n1、大专以上学历，20-35岁，市场营销、新媒体、工商管理、企划宣传类专业优先。

        \n2、1年以上互联网营销管理工作经验，有成功的网络营销推广实例。

        \n3、有敏锐的市场洞察力，具备市场调研、数据分析能力。

        \n4、具备良好的工作计划性、高效的执行力和团队管理能力。

        \n5、较强的团队精神，丰富的管理经验。

        \n6、具备优秀谈判技巧和较强沟通能力。

        \n7、赞同本公司企业文化，抗压能力强。`,
      },
    ],
    list2: [
      {
        title: "高级JAVA工程师",
        content: "深圳 / 技术类 / 本科 / 2022-05-05",
        bol: false,
        detail: `
        \n  职位描述：  

        \n1、5 年及以上 java 开发经验； 

        \n2、 Java 基础扎实，熟悉面向对象和设计模式；
        
        \n3、 数据库基础扎实，掌握数据数据库优化基本技巧； 
        
        \n4、 熟悉 SpringMVC 、 Spring Boot 、 Spring Cloud 、 Spring 、 Mybatis 、 Hibernate 等常用框架； 
        
        \n5、 熟练掌握 mysql 、 oracle 、 mongodb 等主流数据存储系统； 
        
        \n6、 有大型互联网公司经验或大型分布式系统开发经验者优先，熟悉分布式消息队列（ RabbitMQ 、 RocketMQ 等），分布 式缓存（ Redis 、 Memcached ）， zookeeper 等； 
        
        \n7、 对技术有强烈的兴趣，喜欢钻研，具有良好的学习能力，沟通技能和团队协作能力； 
        
        \n8、有税友，金碟，航天金税，神码，中软从事税务方面工作优先考虑。
        
        
        
        \n岗位要求：

        \n1、5 年及以上 java 开发经验； 

        \n2、 Java 基础扎实，熟悉面向对象和设计模式；

        \n3、 数据库基础扎实，掌握数据数据库优化基本技巧； 

        \n4、 熟悉 SpringMVC 、 Spring Boot 、 Spring Cloud 、 Spring 、 Mybatis 、 Hibernate 等常用框架； 

        \n5、 熟练掌握 mysql 、 oracle 、 mongodb 等主流数据存储系统； 

        \n6、 有大型互联网公司经验或大型分布式系统开发经验者优先，熟悉分布式消息队列（ RabbitMQ 、 RocketMQ 等），分布 式缓存（ Redis 、 Memcached ）， zookeeper 等； 

        \n7、 对技术有强烈的兴趣，喜欢钻研，具有良好的学习能力，沟通技能和团队协作能力； 

        \n8、有税友，金碟，航天金税，神码，中软从事税务方面工作优先考虑。`,
      },
      {
        title: "高级WEB工程师",
        content: "深圳 / 技术类 / 本科 / 2022-05-05",
        bol: false,
        detail: `
        \n  职位描述：  

        \n1. 与交互设计师及视觉设计师合作实现交互效果，与后端工程师共同完成应用开发；

        \n2. 实现海量用户产品一流的Web界面，优化代码并保证良好兼容性；
        
        \n3. 设计并实现海量用户产品的交互，提高网页性能和用户体验； 
        
        \n4. 精通各种Web前端技术（HTML/CSS/Javascript等)，熟练跨浏览器、跨终端的开发；
        
        
        
        \n岗位要求：

        \n1. 3年以上相关工作经验，有大型网站开发经验；

        \n2. 掌握HTML5/JavaScript/CSS3等Web开发技术； 
        
        \n3. 移动端H5开发，有页面性能优化经验； 
        
        \n4. 熟练掌握模块化开发，有Vue/React/Weex 等开发经验优先； 
        
        \n5. 有Node后端开发/Flutter/小程序项目经验者优先； 
        
        \n6. 熟练掌握前端构建工具如gulp、webpack； 
        
        \n7. 熟悉http的基本工作原理； 
        
        \n8. 前端基础知识牢靠，能手写原生代码； 
        
        \n9. 对Web前台的性能优化以及Web常见漏洞有一定的理解和相关实践； 
        
        \n10. 主动性强，有敬业精神，勇于面对困难和接受挑战； 
        
        \n11. 有良好的团队协作能力和沟通能力；
        
        \n12.有IE及谷歌浏览器插件开发者优先考虑。`,
      },
      {
        title: "高级产品经理",
        content: "深圳 / 技术类 / 本科 / 2022-05-05",
        bol: false,
        detail: `
        \n  职位描述：  

        \n负责报销产品（报销申请、合同管理、项目管理、预算管理、差旅管理等）的相关工作，日常工作包含并不限于以下方面：

        \n1、需求规划：负责公司预算费控产品的需求规划，整理来自公司战略规划、客户方的需求、项目需求等，识别需求价值点，并合理规划优先级。 

        \n2、解决方案设计：收集业务方、客户方产品使用场景及业务诉求，合理设计功能解决方案，并推动方案落地。 

        \n3、推进实现： 根据产品需求定义，驱动开发人员完成功能落地，跟进日常开发过程中产生的具体问题，并完成产品功能验收。

        \n4、专业的洞察能力，持续优化方案、方法论和工具，提升用户体验。
        
        
        
        \n岗位要求：

        \n1、本科及以上学历，计算机相关专业。

        \n2、沟通及推进能力强，能够独自与合作伙伴方完成业务对接并驱动开发将产品方案落地。

        \n3、有独立思考能力，提问能力，有自己的解决未知问题的方法论。

        \n4、清晰的逻辑思维能力，抗压能力强，较强的自我驱动和创新意识 。

        \n5、有核算经验、合同管理经验、SAAS经验、税务业务、发票业务、应收业务、财务共享、企业服务、企业管理咨询经验优先。`,
      },
      {
        title: "销售助理",
        content: "深圳 / 技术类 / 专科 / 2022-05-05",
        bol: false,
        detail: `
        \n  职位描述： 

        \n1、负责营销部门全流程进行跟踪，协助部门总监做项目立项规划，商务报价、招投标、合同跟进，发票邮寄，业务结算等工作；

        \n2、协助营销部门处理日常事务及维护客户关系；
        
        \n3、负责整理客户资料，建立客户档案；
        
        \n4、协助上级完成其他临时性工作（部门例会的安排、会议纪要等）。
        
        
        
        \n岗位要求：

        \n1、大专及以上学历，计算机或工商管理专业优先；

        \n2、熟悉计算机软件行业，对助理工作有一定的了解； 
        
        \n3、有企业客户服务工作经验； 
        
        \n4、良好的沟通能力、自我管理能力，能够协助部门的推进及销售过程管理；  
        
        \n5、有责任心，上进心，有一定抗压能力，勤奋，不甘于平凡； 
        
        \n6、具备2年以上部门助理，销售助理，客服助理工作经验优先。`,
      },
      {
        title: "网络营销专员",
        content: "深圳 / 技术类 / 本科 / 2022-05-05",
        bol: false,
        detail: `
        \n职位描述：  

        \n1.通过网络、电话的渠道开发客户。

        \n2.帮助客户与公司产品服务搭建桥梁。

        \n3.让客户充分了解公司与产品完成销售目标。
        
        
        
        \n岗位要求：

        \n1、大专以上学历，20-35岁，市场营销、新媒体、工商管理、企划宣传类专业优先。

        \n2、1年以上互联网营销管理工作经验，有成功的网络营销推广实例。

        \n3、有敏锐的市场洞察力，具备市场调研、数据分析能力。

        \n4、具备良好的工作计划性、高效的执行力和团队管理能力。

        \n5、较强的团队精神，丰富的管理经验。

        \n6、具备优秀谈判技巧和较强沟通能力。

        \n7、赞同本公司企业文化，抗压能力强。`,
      },
    ],
    list1: [
      "人尽其才,共同发展,人才是企业发展之本、竞争之本,盛利集团视人才为企业第一资源。",
    ],
    list6:[
      "把依靠人才作为企业发展的根本前提，把尊重人才作为企业发展的根本准则，把推进企业和员工共同发展作为人才战略的根本任务。",
      "在人力资源实践活动中，盛利集团善于为各类人才提供充分施展才华的舞台，做到人尽其才，才尽其用。",
      "同时，通过各种有效途径，不断提高企业人力资源管理水平和人才的综合素养，促进企业与员工的互相发展，并让员工分享企业发展成果,实现共同发展，共享成果。",

    ]
  },
  EN: {
    // 英文
    ideaTitle1: "Keep people in business",
    ideas: "Talent concept",
    ideaTitle2: "Do the treatment to retain people",
    ideaTitle3: "Do culture retain people",
    ideaDesc1: "The company adheres to the principle of giving full play to the talents, and people try their best to create a fair competition platform for the growth of talents and the development space to realize their own value",
    ideaDesc2: "According to the characteristics of different positions, the company implements different distribution modes, provides corresponding company remuneration for talents at different levels, and develops management channels and professional and technical channels",
    ideaDesc3: "Keep good employees, keep people. The intention here is not only the emotional care of the leader, but also a lot of ordinary activities, which run through the work and management",
    mainLocation:'Core positioning',
    unfold_details:'Expansion',
    pack_details:'Pack up',


    testC: "test word C",
    testD: "test word D",
    msgName: "Name",
    msgMail: "Mail",
    msgPhone: "Tel",
    msgAddress: "Address",
    msgNote: "Content",
    msgYesBtn: "Submit",
    msgNullBtn: "Reset",
    msgBotText:
      "Tips：Please fill in your real name and contact information, the professional customer service team will contact you within 15 minutes",
    list5: [
      {
        title: "Hunan Shengli High-tech Energy Technology Co., Ltd.",
        phone: "TEL：0746-263-6988",
        address:
          "Address: No. 9, Xingye North Road, High-tech Zone, Jianghua County, Yongzhou City, Hunan Province",
      },
      {
        title: "Hunan Shengwu Energy Technology Co., Ltd.",
        phone: "TEL：0746-263-6988",
        address:
          "Address: Building 2, Agricultural Products Industrial Park, Linwu County, Chenzhou City, Hunan Province",
      },
      {
        title: "Dongguan Shengli Energy Technology Co., Ltd. (Headquarters)",
        phone: "TEL：0769-8282-7542",
        address:
          "Address: Shengli Building, No. 1, Hekang Road, Jiujiang Shui Village, Changping Town, Dongguan City",
      },
    ],
    list4: [
      '　In their respective positions, they have created both rich material value and precious spiritual wealth. In them, they have gathered the shining points of the ordinary employees of the Zhanli Group, which reflects the cultural concept of "being a person and doing things" in Chinese culture, shaping people, Achievements in the construction of the talent team to infect people.',
      '　In their respective positions, they have created both rich material value and precious spiritual wealth. In them, they have gathered the shining points of the ordinary employees of the Zhanli Group, which reflects the cultural concept of "being a person and doing things" in Chinese culture, shaping people, Achievements in the construction of the talent team to infect people.',
    ],
    list3: [
      {
        title: "Junior JAVA engineer",
        content: "Shenzhen Technical Undergraduate 2022-05-05",
        bol: false,
        detail: `
        \njob description:


        \n1. Team core development, participate in the discussion of business product requirements, determine technical solutions, and work on technology research and development and product launch;
        
        \n2. Carry out system optimization, and complete the precipitation of horizontal technology and business product platforms and components;
        
        \n3. Quickly respond to the needs and problems arising from the operation stage. ;
        
        \n4. Participate in computing research, solve key problems and computing problems in products or projects, and continue to reconstruct and optimize the system.
        
        
        
\njob requirements:


\n1. 5 years or above of java development experience; 

\n2. Solid Java foundation, familiar with object-oriented and design patterns;

\n3. Solid database foundation, master the basic skills of data database optimization; 

\n4. Familiar with common frameworks such as SpringMVC, Spring Boot, Spring Cloud, Spring, Mybatis, Hibernate; 

\n5. Proficient in mainstream data storage systems such as mysql, oracle, and mongodb; 

\n6. Experience in large-scale Internet companies or large-scale distributed system development is preferred, familiar with distributed message queues (RabbitMQ, RocketMQ, etc.), distributed caches (Redis, Memcached), zookeeper, etc.; 

\n7. Have a strong interest in technology, like to study, have good learning ability, communication skills and teamwork ability; 

\n8. There are tax friends, Jindie, Aerospace Golden Tax, Shenma, and Chinasoft who are engaged in tax work are preferred.`,
      },
      {
        title: "Junior web engineer",
        content: "Shenzhen Technical Undergraduate 2022-05-05",
        bol: false,
        detail: `
        \njob description:


\n1. Cooperate with interaction designers and visual designers to achieve interactive effects, and complete application development with back-end engineers;

\n2. Realize the first-class web interface of mass user products, optimize the code and ensure good compatibility;

\n3. Design and realize the interaction of massive user products to improve web page performance and user experience; 

\n4. Proficient in various Web front-end technologies (HTML/CSS/Javascript, etc.), skilled in cross-browser and cross-terminal development;



\njob requirements:


\n1. More than 3 years of relevant work experience, experience in large-scale website development;

\n2. Master HTML5/JavaScript/CSS3 and other web development technologies; 

\n3. Mobile H5 development, experience in page performance optimization; 

\n4. Proficiency in modular development, development experience in Vue/React/Weex is preferred; 

\n5. Experience in Node backend development/Flutter/small program projects is preferred; 

\n6. Familiar with front-end construction tools such as gulp and webpack; 

\n7. Familiar with the basic working principle of http; 

\n8. Solid front-end basic knowledge, able to write native code by hand; 

\n9. Have a certain understanding and practice of performance optimization of the Web front desk and common Web vulnerabilities; 

\n10. Strong initiative, professionalism, courage to face difficulties and accept challenges; 

\n11. Have good teamwork and communication skills;

\n12. IE and Google browser plug-in developers are preferred.`,
      },
      {
        title: "Junior product manager",
        content: "Shenzhen; Changsha Sales Specialist 2022-05-05",
        bol: false,
        detail: `
        \njob description:


        \nResponsible for the related work of Renhua reimbursement products (reimbursement application, contract management, project management, budget management, travel management, etc.), daily work includes but not limited to the following aspects:
        
        \n1. Demand planning: Responsible for the demand planning of the company's budget and cost control products, sorting out the company's strategic planning, customer needs, project needs, etc., identifying demand value points, and planning priorities reasonably. 
        
        \n2. Solution design: Collect the product usage scenarios and business demands of the business side and the customer side, design functional solutions reasonably, and promote the implementation of the solutions. 
        
        \n3. Promoting implementation: According to the definition of product requirements, drive developers to complete the implementation of functions, follow up specific problems arising in the daily development process, and complete product function acceptance.
        
        \n4. Professional insights, continuous optimization of solutions, methodologies and tools to improve user experience.
        
        
        
        \njob requirements:


        \n1. Bachelor degree or above, computer related major.
        
        \n2. Strong communication and promotion ability, able to complete business docking with partners alone and drive development to implement product solutions.
        
        \n3. Have the ability to think independently, ask questions, and have their own methodology for solving unknown problems.
        
        \n4. Clear logical thinking ability, strong ability to resist pressure, strong self-motivation and innovation consciousness.
        
        \n5. Accounting experience, contract management experience, SAAS experience, tax business, invoice business, receivable business, financial sharing, enterprise service, enterprise management consulting experience is preferred.`,
      },
      {
        title: "Sales Assistant",
        content: "Shenzhen; Changsha Sales Specialist 2022-05-05",
        bol: false,
        detail: `
        \njob description:


        \n1. Responsible for tracking the whole process of the marketing department, assisting the department director in project planning, business quotation, bidding, contract follow-up, invoice mailing, business settlement, etc.;
        
        \n2. Assist the marketing department in handling daily affairs and maintaining customer relationships;
        
        \n3. Responsible for sorting out customer information and establishing customer files;
        
        \n4. Assist superiors to complete other temporary tasks (arrangement of regular department meetings, meeting minutes, etc.).
        
        
        
        \njob requirements:


        \n1. College degree or above, major in computer or business management is preferred;
        
        \n2. Familiar with the computer software industry and have a certain understanding of assistant work; 
        
        \n3. Experience in corporate customer service; 
        
        \n4. Good communication skills and self-management skills, able to assist the department's promotion and sales process management;  
        
        \n5. Have a sense of responsibility, self-motivated, have a certain ability to resist pressure, be diligent, and not willing to be ordinary; 
        
        \n6. More than 2 years of experience in department assistants, sales assistants, and customer service assistants is preferred.
       
        `,
      },
      {
        title: "Internet Marketing Specialist",
        content: "Shenzhen; Changsha Sales Specialist 2022-05-05",
        bol: false,
        detail: `
        \njob description:


        \n1. Develop customers through network and telephone channels.
        
        \n2. Help customers build bridges with the company's products and services.
        
        \n3. Let customers fully understand the company and products to achieve sales goals.
        
        
        
        \njob requirements:


        \n1. College degree or above, 20-35 years old, major in marketing, new media, business administration, planning and publicity is preferred.
        
        \n2. More than 1 year of Internet marketing management experience, with successful examples of Internet marketing promotion.
        
        \n3. Have keen market insight, have market research and data analysis capabilities.
        
        \n4. Possess good work planning, efficient execution and team management capabilities.
        
        \n5. Strong team spirit and rich management experience.
        
        \n6. Possess excellent negotiation skills and strong communication skills.
        
        \n7. Agree with the company's corporate culture and have a strong ability to withstand pressure.`,
      },
    ],
    list2: [
      {
        title: "Senior JAVA engineer",
        content: "Shenzhen Technical Undergraduate 2022-05-05",
        bol: false,
        detail: `
        \njob description:


        \n1. Team core development, participate in the discussion of business product requirements, determine technical solutions, and work on technology research and development and product launch;
        
        \n2. Carry out system optimization, and complete the precipitation of horizontal technology and business product platforms and components;
        
        \n3. Quickly respond to the needs and problems arising from the operation stage. ;
        
        \n4. Participate in computing research, solve key problems and computing problems in products or projects, and continue to reconstruct and optimize the system.
        
        
        
\njob requirements:


\n1. 5 years or above of java development experience; 

\n2. Solid Java foundation, familiar with object-oriented and design patterns;

\n3. Solid database foundation, master the basic skills of data database optimization; 

\n4. Familiar with common frameworks such as SpringMVC, Spring Boot, Spring Cloud, Spring, Mybatis, Hibernate; 

\n5. Proficient in mainstream data storage systems such as mysql, oracle, and mongodb; 

\n6. Experience in large-scale Internet companies or large-scale distributed system development is preferred, familiar with distributed message queues (RabbitMQ, RocketMQ, etc.), distributed caches (Redis, Memcached), zookeeper, etc.; 

\n7. Have a strong interest in technology, like to study, have good learning ability, communication skills and teamwork ability; 

\n8. There are tax friends, Jindie, Aerospace Golden Tax, Shenma, and Chinasoft who are engaged in tax work are preferred.`,
      },
      {
        title: "Senior web engineer",
        content: "Shenzhen Technical Undergraduate 2022-05-05",
        bol: false,
        detail: `
        \njob description:


\n1. Cooperate with interaction designers and visual designers to achieve interactive effects, and complete application development with back-end engineers;

\n2. Realize the first-class web interface of mass user products, optimize the code and ensure good compatibility;

\n3. Design and realize the interaction of massive user products to improve web page performance and user experience; 

\n4. Proficient in various Web front-end technologies (HTML/CSS/Javascript, etc.), skilled in cross-browser and cross-terminal development;



\njob requirements:


\n1. More than 3 years of relevant work experience, experience in large-scale website development;

\n2. Master HTML5/JavaScript/CSS3 and other web development technologies; 

\n3. Mobile H5 development, experience in page performance optimization; 

\n4. Proficiency in modular development, development experience in Vue/React/Weex is preferred; 

\n5. Experience in Node backend development/Flutter/small program projects is preferred; 

\n6. Familiar with front-end construction tools such as gulp and webpack; 

\n7. Familiar with the basic working principle of http; 

\n8. Solid front-end basic knowledge, able to write native code by hand; 

\n9. Have a certain understanding and practice of performance optimization of the Web front desk and common Web vulnerabilities; 

\n10. Strong initiative, professionalism, courage to face difficulties and accept challenges; 

\n11. Have good teamwork and communication skills;

\n12. IE and Google browser plug-in developers are preferred.`,
      },
      {
        title: "Senior product manager",
        content: "Shenzhen; Changsha Sales Specialist 2022-05-05",
        bol: false,
        detail: `
        \njob description:


        \nResponsible for the related work of Renhua reimbursement products (reimbursement application, contract management, project management, budget management, travel management, etc.), daily work includes but not limited to the following aspects:
        
        \n1. Demand planning: Responsible for the demand planning of the company's budget and cost control products, sorting out the company's strategic planning, customer needs, project needs, etc., identifying demand value points, and planning priorities reasonably. 
        
        \n2. Solution design: Collect the product usage scenarios and business demands of the business side and the customer side, design functional solutions reasonably, and promote the implementation of the solutions. 
        
        \n3. Promoting implementation: According to the definition of product requirements, drive developers to complete the implementation of functions, follow up specific problems arising in the daily development process, and complete product function acceptance.
        
        \n4. Professional insights, continuous optimization of solutions, methodologies and tools to improve user experience.
        
        
        
        \njob requirements:


        \n1. Bachelor degree or above, computer related major.
        
        \n2. Strong communication and promotion ability, able to complete business docking with partners alone and drive development to implement product solutions.
        
        \n3. Have the ability to think independently, ask questions, and have their own methodology for solving unknown problems.
        
        \n4. Clear logical thinking ability, strong ability to resist pressure, strong self-motivation and innovation consciousness.
        
        \n5. Accounting experience, contract management experience, SAAS experience, tax business, invoice business, receivable business, financial sharing, enterprise service, enterprise management consulting experience is preferred.`,
      },
      {
        title: "Sales Assistant",
        content: "Shenzhen; Changsha Sales Specialist 2022-05-05",
        bol: false,
        detail: `
        \njob description:


        \n1. Responsible for tracking the whole process of the marketing department, assisting the department director in project planning, business quotation, bidding, contract follow-up, invoice mailing, business settlement, etc.;
        
        \n2. Assist the marketing department in handling daily affairs and maintaining customer relationships;
        
        \n3. Responsible for sorting out customer information and establishing customer files;
        
        \n4. Assist superiors to complete other temporary tasks (arrangement of regular department meetings, meeting minutes, etc.).
        
        
        
        \njob requirements:


        \n1. College degree or above, major in computer or business management is preferred;
        
        \n2. Familiar with the computer software industry and have a certain understanding of assistant work; 
        
        \n3. Experience in corporate customer service; 
        
        \n4. Good communication skills and self-management skills, able to assist the department's promotion and sales process management;  
        
        \n5. Have a sense of responsibility, self-motivated, have a certain ability to resist pressure, be diligent, and not willing to be ordinary; 
        
        \n6. More than 2 years of experience in department assistants, sales assistants, and customer service assistants is preferred.
       
        `,
      },
      {
        title: "Internet Marketing Specialist",
        content: "Shenzhen; Changsha Sales Specialist 2022-05-05",
        bol: false,
        detail: `
        \njob description:


        \n1. Develop customers through network and telephone channels.
        
        \n2. Help customers build bridges with the company's products and services.
        
        \n3. Let customers fully understand the company and products to achieve sales goals.
        
        
        
        \njob requirements:


        \n1. College degree or above, 20-35 years old, major in marketing, new media, business administration, planning and publicity is preferred.
        
        \n2. More than 1 year of Internet marketing management experience, with successful examples of Internet marketing promotion.
        
        \n3. Have keen market insight, have market research and data analysis capabilities.
        
        \n4. Possess good work planning, efficient execution and team management capabilities.
        
        \n5. Strong team spirit and rich management experience.
        
        \n6. Possess excellent negotiation skills and strong communication skills.
        
        \n7. Agree with the company's corporate culture and have a strong ability to withstand pressure.`,
      },
    ],
    list1: [
      "People make the best use of their talents and develop together,Talent is the foundation of enterprise development and the foundation of competition,Shengli Group regards talents as the first resource of the enterprise",
    ],
    list6:[
      "Regards relying on talents as the fundamental prerequisite for enterprise development, respects talents as the fundamental criterion of enterprise development, and promotes the common development of enterprises and employees as the fundamental task of the talent strategy.",
      "In the human resource practice activities, Shengli Group is good at providing a stage for all kinds of talents to fully display their talents, and make the best use of their talents.",
      "At the same time, through various effective ways, we will continuously improve the enterprise's human resource management level and the comprehensive quality of talents, promote the mutual development of the company and employees, and allow employees to share the results of corporate development, achieve common development, and share results.",
    ]
  },
};
export default info;
