<template>
  <div class="overHidden">
    <top-pic :name="name" :text="$t('common.base.nav_aboutus_intoduction')" :subText="$t('common.base.nav_products_sub')"></top-pic>
    <div class="company-msg contentBox">
      <div class="company-introduce">
        <div class="title">{{ $t("pages.main.shengli_t1") }}{{ $t("pages.main.shengli_t2") }}</div>
        <p v-for="(item, ind) in pList" :key="ind" :class="ind == 1 ? 'margin' : ''">{{ item.name }}</p>
      </div>
      <div class="company-msg-picb">
        <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companybg.jpg" alt="" />
      </div>
    </div>
    <div class="num contentBox">
      <div class="num_item">
        <div class="count">20<span class="add_icon">+</span></div>
        <span class="txt">{{ $t("pages.main.shengli_notice1") }}</span>
      </div>
      <div class="num_item">
        <div class="count">88000<span class="add_icon">+</span></div>
        <span class="txt">{{ $t("pages.main.shengli_notice2") }}</span>
      </div>
      <div class="num_item">
        <div class="count">100<span class="add_icon">+</span></div>
        <span class="txt">{{ $t("pages.main.shengli_notice3") }}</span>
      </div>
      <div class="num_item">
        <div class="count">3800<span class="add_icon">+</span></div>
        <span class="txt">{{ $t("pages.main.shengli_notice4") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
export default {
  name: "IndexPage",
  components: {
    TopPic,
  },
  data() {
    return {
      name: "/introduction",
    };
  },
  computed: {
    pList() {
      return [
        {
          name: this.$t("pages.company.companyp1"),
        },
        {
          name: this.$t("pages.company.companyp2"),
        },
        {
          name: this.$t("pages.company.companyp3"),
        },
      ];
    },
  },
  methods: {
    goClass() {},
  },
};
</script>

<style scoped lang="scss">
.company-introduce {
  width: 686px;
}
.num {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 27px;
  flex-wrap: wrap;
  .num_item {
    font-size: 50px;
    font-weight: bold;
    color: #00b52d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    .count{
      position: relative;
      .add_icon {
            position: absolute;
            top: -4px;
            right: -18px;
            font-weight: 600;
            color: #00b52d;
            font-size: 30px;
          }

    }
    .txt {
    font-size: 20px;
    font-weight: 600;
    color: #262830;
    margin-top: 10px;
    display: block;
  }

  }
}
.margin {
  margin-bottom: 16px !important;
}
.company-msg {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  p {
    font-size: 14px;
    font-weight: 400;
    color: #717171;
    line-height: 22px;
    margin-bottom: 0.08rem;
  }
  .title {
    font-size: 36px;
    font-weight: 600;
    color: #262830;
    line-height: 50px;
    margin: .2rem 0 16px 0;
  }
  .company-msg-picb {
    img {
      width: 100%;
      height: 3.34rem;
      object-fit: cover;
      display: block;
      margin-top: .2rem;
    }
  }
}
</style>
