<template>
  <div id="app">
    <Layout />
  </div>
</template>

<script>
import Layout from './components/Layout.vue'
import Vue from 'vue'
Vue.directive('no-scroll', {
  // 当绑定元素插入到DOM中
  inserted: function (el) {
    // 为元素添加鼠标移入事件监听
    el.addEventListener('mouseenter', function () {
      // 暂时禁用滚动
      document.body.style.overflowX = 'hidden';
    });
    // 为元素添加鼠标移出事件监听
    el.addEventListener('mouseleave', function () {
      // 恢复滚动
      document.body.style.overflowX = '';
    });
  }
});
export default {
  name: 'App',
  components: {
    Layout
  },
  mounted() {
    if (!/msie [6|7|8|9]/i.test(navigator.userAgent) && WOW) {
      new WOW().init()
    }
    try {
      document.querySelector("#icoimg").src='https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/icon/contact_server.png'
    }catch(err){}
  }
}
</script>

<style>
#app {
  /* background: #F4F4F4; */
  min-height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.el-row {
  width: 100% !important;
}
.contentBox{
  width: 14.4rem;
  margin: 0 auto;
}
@media screen and (max-width: 756px) {
.contentBox{
  width: 100%;
  padding: 15px;
  margin: 0 auto;
}
}
</style>
