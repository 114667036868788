<template>
  <div>
    <top-pic :name="name" :text="$t('common.base.nav_contact_contact')" :subText="$t('common.base.nav_contact_sub')"></top-pic>
    <div class="contentBox">
      <!-- <div class="titleBox" v-if="$store.state.deviceType === '1'">
        <div class="title"></div>
      </div> -->
      <mapCom v-if="$store.state.deviceType === '2'"></mapCom>
      <div class="centerBox"
       v-if="$store.state.deviceType === '1'"
      >
        <div class="list">
          <div class="item wow fadeInUp" v-for="(item, index) in $t('common.base2.list5')" :key="index">
            <div class="head">{{ item.title }}</div>
            <div class="content">
              <div class="phone">
                <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/icon/phone_icon.png" />
              </div>
              <div>
                <div class="top">{{ item.phone }}</div>
                <div class="bot">{{ item.address }}</div>
              </div>
            </div>
          </div>
        </div>
        <mapCom></mapCom>
      </div>
      <div v-else class="cardBox">
        <div  v-for="(item, index) in $t('common.base2.list5')" :key="index"
        class="cardArea wow fadeInUp">
          <div class="title">{{ item.title }}</div>
          <div class="address">{{ item.address }}</div>
          <div class="phone">{{ item.phone }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
import mapCom from "./comm/mapCom.vue";
export default {
  name: "IndexPage",
  components: {
    TopPic,
    mapCom,
  },
  data() {
    return {
      name: "/contact",
      list: [
        {
          title: "湖南盛利高新能源科技有限公司",
          phone: "电话：0746-263-6988",
          address: "地址：湖南省永州市江华县高新区兴业北路9号",
        },
        {
          title: "湖南盛武能源科技有限公司",
          phone: "电话：0746-263-6988",
          address: "地址：湖南省永州市江华县高新区兴业北路9号",
        },
        {
          title: "东莞市盛利能源科技有限公司(总部)",
          phone: "总部电话：0769-8282-7542",
          address: "地址：广东省东莞市常平镇桥沥南门路40号4栋101室",
        },
      ],
    };
  },
  methods: {
    goClass() {},
  },
};
</script>

<style scoped lang="scss">
.titleBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 49px 0 20px 0;
  .title {
    width: 570px;
    height: 86px;
    background: url("https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/contact_us.png") no-repeat center/100%;
  }
}
.cardBox {
  .cardArea {
    height: 2.2rem;
    background: #F9F9F9;
    padding: .38rem .26rem;
    font-size: .24rem;
    margin-top: .2rem;
    .title {
      color: #262830;
      font-size: .34rem;
      font-weight: 800;
    }
    .address {
      color: #717171;
      margin: .2rem 0 .3rem;
    }
    .phone {
      color: #1FCE76;
      font-size: .28rem;
      font-weight: 800;
    }
  }
}
.centerBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  flex-wrap: wrap;
}
.list {
  width: 364px;
  margin-right: 35px;

  .item {
    width: 100%;
    overflow: hidden;
    margin-bottom: 20px;
    .head {
      width: 364px;
      height: 44px;
      line-height: 44px;
      background: #19cc41;
      padding-left: 20px;
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
    }
    .content {
      display: flex;
      align-items: center;
      padding: 20px;
      font-size: 16px;
      background: #f9f9f9;
      .phone {
        width: 30px;
        height: 30px;
        margin-right: 12px;
        img {
          width: 100%;
          height: 100%;
          display: inline-block;
        }
      }
      .top {
        font-size: 18px;
        font-weight: bold;
        color: #36bf6a;
        line-height: 21px;
      }
      .bot {
        font-size: 12px;
        font-weight: 400;
        color: #747a90;
        line-height: 29px;
      }
    }
  }
}

// 兼容手机
@media screen and (max-width: 756px) {
  .list {
    display: flex;
    flex-direction: column;
    .item {
      width: 100%;
      margin-bottom: 20px;
      .head {
        font-size: 19px;
      }
      .content {
        font-size: 15px;
      }
    }
  }
}
</style>
