<template>
  <div class="container">
    <el-carousel indicator-position="none"  v-no-scroll>
      <el-carousel-item v-for="(item, ind) in swiperList" :key="ind">
        <div class="bannerText" v-show="ind == 0">
          <div>{{ $t(item.banner1_t1) }}</div>
          <div>{{ $t(item.banner1_t2) }}</div>
          <div class="toMore" @click="goPages('/introduction')">{{ $t(item.banner1_t3) }}</div>
        </div>
        <div class="banner2Text" v-show="ind == 1">
          <div>{{ $t(item.banner2_t1) }}</div>
          <div class="t2Text">{{ $t(item.banner2_t2) }}</div>
          <div class="cutLine"></div>
          <div class="bannerBox">
            <div class="itemBox"
            @click="toShopPage(item2)"
            v-for="(item2,index2) in item.itemBox"
            :key="index2">
              <img :src="item2.img" alt="">
              {{ $t(item2.title) }}
            </div>
          </div>
        </div>
        <img :src="item.img" @click="goProduct(item)" style="cursor: pointer;" />
      </el-carousel-item>
    </el-carousel>
    <div class="productCenterBox">
      <div class="titleBox">
        <div class="zh_title">
          <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
          <span>{{ $t("pages.main.proplan") }}</span>
          <!-- <span >中心</span> -->
          <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
        </div>
        <!-- <div class="en_title">{{ $t("pages.main.proplan_desc") }}</div> -->
      </div>
      <div class="shopCardArea">
          <shop-card-vue
          v-for="(item, index) of productList" 
          :key="index"
          :cardWidth="3.38"
          :dataObj="item"
          ></shop-card-vue>
        <!-- <div class="itemBox wow fadeInUp" v-for="(item, index) of productList" :key="index">
          <div class="left">
            <div>{{ item.title }}</div>
            <div>{{ item.detail }}</div>
            <div @click="goProduct(item)">{{ item.more }}</div>
          </div>
          <div class="right">
            <img :src="item.img" alt="">
          </div>
        </div> -->
      </div>
    </div>

    <div class="briefIntroduction">
      <div class="left">
        <div class="wow fadeInLeft">
          <div class="title">
            <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
            <span>{{ $t("pages.main.shengli_t1") }}</span>
            <span class="green3ec">{{ $t("pages.main.shengli_t2") }}</span>
            <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
          </div>
          <!-- <div class="enTitle">
            Shengli Energy · Green Power
          </div> -->
          <div class="content">
            <p>{{ $t("pages.main.shengli_p1") }}</p>
            <p>{{ $t("pages.main.shengli_p2") }}</p>
          </div>
          
          <div class="learnMore" @click="goPages('/introduction')">
            <!-- <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/slhd-website/icon/more.png" alt=""> -->
            <span>{{ $t("pages.main.learn") }}</span>
          </div>
        </div>
        <div class="dataCard wow fadeInLeft">
          <div class="item">
            <p>
              <span>{{num1}}</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice1") }}</p>
          </div>
          <div class="item">
            <p>
              <span>{{num2}}</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice2") }}</p>
          </div>
          <div class="item">
            <p>
              <span>{{num3}}</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice3") }}</p>
          </div>
          <div class="item">
            <p>
              <span>{{num4}}</span>
              <span>+</span>
            </p>
            <p>{{ $t("pages.main.shengli_notice4") }}</p>
          </div>
          <!-- <div class="blank"></div> -->
        </div>
      </div>
      <!-- <div class="right">
        <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/index_companys.png" alt="">
      </div> -->
    </div>

    <div class="cooperativePartner">
      <div class="title_zh">
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
        <span>{{ $t("pages.main.friend") }}</span>
        <!-- <span>伙伴</span> -->
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
      </div>
      <!-- <div class="title_en">Company Partner</div> -->
      <div class="content companyBox">
        <div class="centerBox">200+</div>
        <div v-for="(item,index) in componyList"
        :key="index"
        class="logoBox" 
        :style="{
          'top': item.top + 'rem',
          'left': item.left + 'rem'
        }"
        ref="logoBox"
        >
          <img :src="item.img" alt="">
        </div>
        <!-- <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/partners.png" alt=""> -->
      </div>
    </div>

    <div class="newsInfo">
      <div class="title_zh">
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
        <span>{{ $t("pages.main.news") }}</span>
        <!-- <span class="green3ec">资讯</span> -->
        <!-- <img class="iconShanDian" src="@/assets/icon/icon_shandian@2x.png" alt=""> -->
      </div>
      <!-- <div class="title_en">News and information</div> -->
      <div class="contentsBox">
        <div class="leftItemBox wow fadeInLeft" v-for="(item, index) of newsInfoLeft" :key="index"
          @click="goDetail(item.id, item.url)">
          <img :src="item.img" alt="" class="leftImg">
          <div class="rightBox">
            <div class="title">{{ item.name }}</div>
            <div class="date">{{ item.time }}</div>
            <div class="text">{{ item.desc }}</div>
            <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/icon/arrow_info.png"
              class="toMoreIcon">
          </div>
        </div>
        <div class="rightItemBox wow fadeInRight">
          <div class="item" v-for="(item, index) of newsInfoRight" :key="index" @click="goDetail(item.id, item.url)">
            <div class="leftBox">
              <div class="day">{{ item.time.slice(8, 10) }}</div>
              <div class="year_month">{{ item.time.slice(0, 7) }}</div>
            </div>
            <div class="rightBox">
              <div class="title">{{ item.name }}</div>
              <div class="text">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="searchMore" @click="goDetail('', '')">{{ $t("pages.main.search_more") }} +</div>
    </div>
  </div>
</template>

<script>
import shopCardVue from '@/components/shopCard.vue';
export default {
  name: "PcPage",
  components: {
    shopCardVue
  },
  data() {
    return {
      isCollapse: 1,
      swiperList: [
        {
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_bannerone%20(2).png",
          url: `/introduction`,
          id: 'none',
          banner1_t1: "pages.main.banner1_t1",
          banner1_t2: "pages.main.banner1_t2",
          banner1_t3: "pages.main.banner1_t3"
        },
        {
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_bannertwo%20(1).png",
          url: `/product?type=1`,
          id: 9,
          banner2_t1: "pages.main.banner2_t1",
          banner2_t2: "pages.main.banner2_t2",
          itemBox: [
            {
              title: "pages.main.proplan_t1",
              path: '/product?type=1',
              id: 9,
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_dianxin.png'
            },
            {
              title: "pages.main.proplan_t2",
              path: '/product?type=2',
              id: 10,
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_chuneng.png'
            },
            {
              title: "pages.main.proplan_t3",
              path: '/product?type=3',
              id: 11,
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_dianmo.png'
            },
            {
              title: "pages.main.proplan_t4",
              path: '/product?type=4',
              id: 12,
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_lidain.png'
            },
            {
              title: "pages.main.proplan_t5",
              path: '/product?type=7',
              id: 19,
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_diandongche.png'
            },
            {
              title: null,
              path: '/product?type=1',
              id: 9,
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_dian.png'
            },
          ]
        },
        // {
        //   img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/index_banner2.png",
        //   url: "/product?type=2",
        //   id: 10
        // },
        // {
        //   img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/index_banner4.png",
        //   url: "/product?type=3",
        //   id: 11
        // },
      ],
      dataCardArea: 0,
      num1: 20,
      num2: 88000,
      num3: 100,
      num4: 3800,
      componyList: [
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_byd%402x.png',
          top: 3.95,
          left: 3.82
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_changhong%402x.png',
          top: 4.53,
          left: 7.72
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_geli%402x.png',
          top: 1.89,
          left: 13.98
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_huawei%402x.png',
          top: 2.15,
          left: 6.23
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_oppo%402x.png',
          top: 0,
          left: 4.96,
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_pingan%402x.png',
          top: 1.2,
          left: 2.9
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_tailing%402x.png',
          top: 4.47,
          left: 12.65
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_xiaomi%402x.png',
          top: 3.29,
          left: 10.22
        },
        {
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_zhongxing%402x.png',
          top: 0.67,
          left: 11.71
        },
      ],
    };
  },
  computed: {
    productList() {
      return [
        {
          title: this.$t("pages.main.proplan_t1"),
          detail: this.$t("pages.main.proplan_t1_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product1.png",
          url: "/product?type=1",
          id: 9
        },
        {
          title: this.$t("pages.main.proplan_t2"),
          detail: this.$t("pages.main.proplan_t2_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product2.png",
          url: "/product?type=2",
          id: 10
        },
        {
          title: this.$t("pages.main.proplan_t3"),
          detail: this.$t("pages.main.proplan_t3_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product3.png",
          url: "/product?type=3",
          id: 11
        },
        {
          title: this.$t("pages.main.proplan_t4"),
          detail: this.$t("pages.main.proplan_t4_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product4.png",
          url: "/product?type=4",
          id: 12
        },
        {
          title: this.$t("pages.main.proplan_t5"),
          detail: this.$t("pages.main.proplan_t5_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product5.png",
          url: "/product?type=7",
          id: 19
        },
        {
          title: this.$t("pages.main.proplan_t6"),
          detail: this.$t("pages.main.proplan_t6_desc"),
          more: this.$t("pages.main.proplan_more"),
          img: "https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/product6.png",
          url: "/product?type=6",
          id: 18
        }
      ];
    },
    newsInfoLeft() {
      let obj = this.$t("pages.news.news" + this.isCollapse);
      return [obj.list[0][0]]
    },
    newsInfoRight() {
      let obj = this.$t("pages.news.news" + this.isCollapse);
      return obj.list[0]
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.dataCardArea = document.querySelector('.dataCard').offsetTop
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toShopPage(obj) {
        this.$router.push({
          path: obj.path,
          query: { id: obj.id },
        });
    },
    goPages(url) {
      this.$router.push({
        path: url,
      });
    },
    goProduct(item) {
      if (item.id == 'none') {
        this.$router.push({
          path: item.url,
        });
      } else {
        this.$router.push({
        path: item.url,
          query: { id: item.id },
        });
      }
    },
    // 去详情
    goDetail(id, url) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          name: url,
          id,
        },
      });
    },
    handleScroll() {
      const scrollPosition = window.scrollY + window.innerHeight
      console.log(scrollPosition)
      if (this.dataCardArea<scrollPosition) {
        this.sortNum(0,20,'num1')
        this.sortNum(87900,88000,'num2')
        this.sortNum(50,100,'num3')
        this.sortNum(3700,3800,'num4')
        window.removeEventListener('scroll', this.handleScroll);
      }
    },
    sortNum(baseNumber, finalNum, str) {
      let step = (finalNum - baseNumber) / 50;//颗粒化可以控制速度
	      let count = baseNumber; // 计数器
	      let initial = 0;
	      let timer = setInterval(() => {
	        count = count + step;
	        if (count >= finalNum) {
	          clearInterval(timer);
	          count = finalNum;
	        }
	        let t = count.toFixed(0);
	        initial = t;
	        this[str] = initial;
	      }, 30);
    }
  },
};
</script>

<style lang="scss" scoped>
.bannerText {
  position: absolute;
  color: #fff;
  font-size: .72rem;
  font-weight: 800;
  font-style: italic;
  line-height: .91rem;
  left: 2.6rem;
  top: 50%;
  transform: translateY(-50%);
  .toMore {
    width: 1.9rem;
    height: .42rem;
    background: #1FCE76;
    transform: skewX(-5deg);
    font-size: .2rem;
    font-weight: 400;
    line-height: .42rem;
    text-align: center;
    margin-top: .94rem;
    cursor: pointer;
  }
}
.banner2Text {
  position: absolute;
  color: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: .64rem;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-items: center;
  .t2Text {
    font-size: .24rem;
    font-weight: 400;
    line-height: .33rem;
    margin: .34rem 0 .06rem;
  }
  .cutLine {
    width: .2rem;
    height: .03rem;
    background: #FFFFFF;
  }
  .bannerBox {
    display: flex;
    margin-top: .52rem;
    .itemBox {
      width: 1.74rem;
      height: 1.27rem;
      border: 1px solid #A6AAAA;
      font-weight: 300;
      font-size: .18rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-right: .55rem;
      text-align: center;
      cursor: pointer;
      img {
        width: .14rem;
        height: .18rem;
        margin: .26rem 0 .30rem;
      }
    }
    .itemBox:hover {
      background: rgba(137, 141, 141,.5);
    }
  }
  .bannerBox > :last-child {
    border: 0;
    img {
      width: auto;
      height: auto;
      margin: auto;
    }
  }
}

.productCenterBox {
  width: 100vw;

  .titleBox {
    padding: 0.5rem 0;

    .zh_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.1rem;

      :first-child {
          margin-right: .2rem;
        }

      :last-child {
        margin-left: .2rem;
      }

      span {
        font-weight: 600;
        font-size: 0.4rem;
        color: #30323B;
      }
      
    }

    .en_title {
      text-align: center;
      font-weight: 400;
      font-size: .12rem;
      color: #8C8C8C;
    }
  }

  .shopCardArea {
    width: 100%;
    padding: 0 2.6rem;
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
  }

  .contentsBox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .itemBox {
      width: 6.92rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.42rem 0;
      background-color: #fafafa;
      margin: 0.1rem;

      .left {
        flex: 1;
        padding: 0 0.44rem 0 0.26rem;

        div {
          &:nth-child(1) {
            font-weight: 600;
            font-size: 0.22rem;
            color: #000000;
          }

          &:nth-child(2) {
            font-weight: 400;
            font-size: 0.14rem;
            color: #8C8C8C;
            margin: 0.12rem 0 0.41rem;
          }

          &:nth-child(3) {
            width: 2.23rem;
            height: 0.43rem;
            line-height: 0.43rem;
            text-align: center;
            background: rgba(25, 204, 65, 0.04);
            border: 0.01rem solid #19CC41;
            font-weight: 400;
            font-size: 0.14rem;
            color: #19CC41;
          }

          &:nth-child(3):hover {
            background-color: #19CC41;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }

      .right {
        width: 2.4rem;
        height: 2.4rem;

        img {
          width: 100%;
          height: 100%;
          transition: 0.5s;
        }

        img:hover {
          cursor: pointer;
          transform: scale(1.1);
          transition: 0.5s;
        }
      }
    }
  }
}

.briefIntroduction {
  // background-color: #f7f7f7;
  // display: flex;
  // align-items: flex-start;
  // justify-content: center;
  padding: .7rem 4.8rem 1.15rem;
  margin-top: 0.83rem;
  background: url('https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/img_lvsedogli%402x.png') no-repeat;
  background-size: 100% 100%;

  .left {
    // flex: 1;

    .title {
      font-weight: 600;
      font-size: 0.36rem;
      color: #fff;
      text-align: center;
      margin-bottom: .5rem;
      // span {
      //   &:last-child {
      //     color: #19CC41;
      //   }
      // }
      // :first-child {
      //     margin-right: .2rem;
      //   }

      // :last-child {
      //   margin-left: .2rem;
      // }
    }

    .enTitle {
      font-size: .12rem;
      color: #545D59;
      text-align: center;
      margin: .12rem 0 .38rem;
    }

    .content {
      margin-bottom: 0.42rem;
      :first-child {
        margin-bottom: .3rem;
      }

      p {
        font-weight: 400;
        font-size: 0.14rem;
        color: #A8ABA9;
        text-align: center;
        margin: auto;
      }
    }

    .learnMore {
      width: max-content;
      display: flex;
      align-items: center;
      padding-bottom: .1rem;
      border-bottom: 1px solid #3EC94B;
      margin: 0 auto .42rem;

      // img {
      //   width: 0.43rem;
      //   height: 0.43rem;
      //   margin-right: 0.12rem;
      // }

      span {
        font-weight: 400;
        font-size: 0.14rem;
        color: #3EC94B;
      }
    }

    .learnMore:hover {
      cursor: pointer;
    }

    .dataCard {
      // width: 9.58rem;
      height: 1.83rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .5rem;
      background-color: #ffffff;
      position: relative;
      margin: auto;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          &:first-child {
            font-weight: bold;
            font-size: 0.46rem;
            color: #00B52D;
            position: relative;

            span {
              &:first-child {
                font-weight: bold;
                font-size: 0.46rem;
                color: #00B52D;
              }

              &:last-child {
                font-weight: bold;
                font-size: 0.3rem;
                color: #00B52D;
                position: absolute;
                top: -0.2rem;
                right: -0.2rem;
                z-index: 2;
              }
            }
          }

          &:last-child {
            font-weight: 600;
            font-size: 0.2rem;
            color: #262830;
            margin-top: 0.11rem;
          }
        }
      }

      .blank {
        width: 0.6rem;
        height: 100%;
        position: absolute;
        right: -0.6rem;
        top: 0;
        bottom: 0;
        z-index: 1;
        background-color: #ffffff;
      }
    }
  }

  .right {
    width: 4.72rem;
    height: 6.06rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.cooperativePartner {
  .title_zh {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.8rem;

    :first-child {
          margin-right: .2rem;
        }

      :last-child {
        margin-left: .2rem;
      }

    span {
      font-weight: 600;
      font-size: 0.4rem;
      color: #30323B;

      // &:last-child {
      //   color: #19CC41;
      // }
    }
  }

  .title_en {
    text-align: center;
    font-weight: 400;
    font-size: 0.12rem;
    color: #8C8C8C;
    margin-top: 0.05rem;
  }

  .companyBox {
    position: relative;
    height: 6.45rem;
  }

  .content {
    padding: 0 2rem;

    .centerBox {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
      border-radius: 50%;
      box-shadow: 0px -1px 32px 0px #F1F3F4;
      background: #1FCE76;
      color: #fff;
      font-size: .48rem;
      font-weight: 700;
      text-align: center;
    }
    .logoBox {
      width: 1.92rem;
      height: 1.92rem;
      border-radius: 50%;
      position: absolute;
      animation: bellShow 2s infinite;
      img {
        width: 100%;
      }
    }
    @keyframes bellShow {
            0% {
                transform: scale(0.80);
                // box-shadow: 0 0 0 0 rgba(0, 0, 0, 10%);
            }

            60% {
                transform: scale(1);
                // box-shadow: 0 0 0 5px rgba(31, 206, 118, 0%);
            }

            100% {
                transform: scale(0.80);
                // box-shadow: 0 0 0 0 rgba(31, 206, 118, 0%);
            }
        }

    // img {
    //   width: 100%;
    // }
  }
  
}

.newsInfo {
  padding: 0.8rem 2.4rem;
  // background: url('https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/info_bg.png') no-repeat 50% 50%/100%;
  background: #FAFAFA;

  .title_zh {
    display: flex;
    align-items: center;
    justify-content: center;

      :first-child {
        margin-right: .2rem;
      }

      :last-child {
        margin-left: .2rem;
      }

    span {
      font-weight: 600;
      font-size: 0.4rem;
      color: #30323B;

      // &:last-child {
      //   color: #19CC41;
      // }
    }
  }

  .title_en {
    text-align: center;
    font-weight: 400;
    font-size: 0.12rem;
    color: #8C8C8C;
    margin-top: 0.05rem;
  }

  .contentsBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.36rem;

    .leftItemBox {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      width: 7.22rem;
      height: 3.8rem;

      .leftImg {
        width: 4.22rem;
        height: 3.8rem;
        transition: 0.5s;
      }

      .leftImg:hover {
        transform: scale(1.05);
        transition: 0.5s;
      }

      .rightBox {
        padding: 0.34rem 0.34rem 0.44rem;
        height: 3.8rem;
        overflow: hidden;
        position: relative;

        .title {
          font-weight: 600;
          font-size: 0.22rem;
          color: #000000;
        }

        .date {
          font-weight: 400;
          font-size: 0.14rem;
          color: #19CC41;
          margin: 0.25rem 0;
        }

        .text {
          font-weight: 400;
          font-size: 0.14rem;
          width: 100%;
          color: #717171;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 8;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .toMoreIcon {
          width: 0.3rem;
          height: 0.1rem;
          position: absolute;
          left: .34rem;
          bottom: .34rem;
        }
      }
    }

    .leftItemBox:hover {
      cursor: pointer;
    }

    .rightItemBox {
      padding: 0 0.4rem;
      background-color: #ffffff;
      width: 6.58rem;
      height: 3.8rem;

      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 50%;

        .leftBox {
          margin-right: 0.26rem;

          .day {
            width: 0.68rem;
            height: 0.68rem;
            line-height: 0.68rem;
            text-align: center;
            background-color: #E8FFED;
            font-weight: bold;
            font-size: 0.32rem;
            color: #262830;
          }

          .year_month {
            width: 0.68rem;
            height: 0.3rem;
            line-height: 0.3rem;
            text-align: center;
            background-color: #19CC41;
            font-weight: bold;
            font-size: 0.12rem;
            color: #FFFFFF;
          }
        }

        .rightBox {
          .title {
            font-weight: 600;
            font-size: 0.22rem;
            color: #000000;
            margin-bottom: 0.12rem;
          }

          .text {
            font-weight: 400;
            font-size: 0.14rem;
            color: #717171;
          }
        }
      }

      .item+.item {
        border-top: 0.01rem solid #ECECEC;
      }
    }

    .rightItemBox:hover {
      cursor: pointer;
    }
  }

  .searchMore {
    margin: 0.65rem auto 0;
    width: 2.43rem;
    height: 0.47rem;
    line-height: 0.47rem;
    text-align: center;
    border: 0.01rem solid #19CC41;
    font-weight: 400;
    font-size: 0.14rem;
    color: #19CC41;
  }

  .searchMore:hover {
    background-color: #19CC41;
    color: #ffffff;
    cursor: pointer;
  }
}

::v-deep .el-carousel__container {
  height: 7.3rem;
}

::v-deep .el-carousel__item {
  height: 7.3rem;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}
</style>
