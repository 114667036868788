// 汇总了pages模块所有页面的数据
// main部分(页面)
import Main from './main';
// product部分(页面)
import Product from './product';
// product部分(页面)
import Company from './company';
import News from './news';
export default {
  Main,
  Product,
  Company,
  News
}