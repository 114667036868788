<template>
  <div style="overflow: hidden">
    <top-pic :name="name" :text="$t('common.base.nav_contact_message')" :subText="$t('common.base.nav_contact_sub')"></top-pic>
    <div class="content wow fadeInUp">
      <!-- <div class="titleBox" v-if="$store.state.deviceType === '1'">
        <div class="title"></div>
      </div> -->
      <div class="item">
        <!-- <div style="width: 60px">{{ $t("common.base2.msgName") }}：</div> -->
        <el-input v-model="subData.userName" :placeholder="$t('common.base2.msgName')"></el-input>
        <el-input v-model="subData.userEmail" :placeholder="$t('common.base2.msgMail')" style="margin-right: 0;"></el-input>
      </div>
      <!-- <div class="item">
        <div style="width: 60px">{{ $t("common.base2.msgMail") }}：</div>
        <el-input v-model="subData.userEmail" :placeholder="$t('common.base2.msgMail')"></el-input>
      </div> -->
      <div class="item">
        <!-- <div style="width: 60px">{{ $t("common.base2.msgPhone") }}：</div> -->
        <el-input v-model="subData.userPhone" :placeholder="$t('common.base2.msgPhone')"></el-input>
        <el-input v-model="subData.address" :placeholder="$t('common.base2.msgAddress')" style="margin-right: 0;"></el-input>
      </div>
      <div class="item">
        <!-- <div style="width: 60px">{{ $t("common.base2.msgNote") }}：</div> -->
        <el-input v-model="subData.context" type="textarea" :rows="3" :placeholder="$t('common.base2.msgNote')"> </el-input>
      </div>

      <div class="btnBox">
        <!-- <el-button type="primary" @click="submit">{{ $t("common.base2.msgYesBtn") }}</el-button> -->
        <div class="subBtn" @click="submit">{{ $t("common.base2.msgYesBtn") }}</div>

        <!-- <el-button @click="nullData">{{ $t("common.base2.msgNullBtn") }}</el-button> -->
      </div>
      <!-- <p class="botText">{{ $t("common.base2.msgBotText") }}</p> -->
    </div>
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
import { submitArticleConcat } from "@/utils/api.js"; // 这个就是我们上面的http.js文件
export default {
  name: "IndexPage",
  components: {
    TopPic,
  },
  data() {
    return {
      name: "/message",
      subData: {
        userName: "",
        userPhone: "",
        userEmail: "",
        context: "",
      },
    };
  },
  methods: {
    submit() {
      if (!this.subData.userName) {
        this.$message.error("请输入姓名");
        return;
      }
      if (!this.subData.userPhone) {
        this.$message.error("请输入电话");
        return;
      }
      submitArticleConcat(this.subData).then((res) => {
        if (res.code == "000000") {
          this.$message.success(res.msg || res.message);
          this.nullData();
        } else {
          this.$message.error(res.msg || res.message);
        }
      });
    },
    nullData() {
      this.subData = {
        userName: "",
        userPhone: "",
        userEmail: "",
        context: "",
      };
    },
  },
};
</script>

<style scoped lang="scss">
.el-input {
  margin-right: 20px;
  border-radius: 5px;
}
/deep/.el-input__inner {
  background: #f9f9f9 !important;
  border: none !important;
  height: 72px !important;
  font-size: 20px !important;
}
/deep/.el-textarea__inner {
  background: #f9f9f9 !important;
  border: none !important;
  min-height: 230px !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}
.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 60px;
  .subBtn {
    width: 441px;
    height: 56px;
    line-height: 56px;
    text-align: center;
    background: #19cc41;
    border-radius: 5px;
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
  }
}
.titleBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 49px 0 20px 0;
  .title {
    width: 570px;
    height: 86px;
    background: url("https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/message.png") no-repeat center/100%;
  }
}

.item {
  // height: 60px;
  width: 100%;
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: space-between;
}

.botText {
  font-size: 16px;
  color: #999;
  margin-bottom: 20px;
}

.content {
  // padding: 0 20px;
  max-width: 14.4rem;
  margin: .4rem auto 0;
}
@media screen and (max-width: 756px) {
  .content {
    margin: .4rem 0 1.8rem;
  }
  .item {
    display: block !important;
    margin: 0
  }
  .el-input {
    margin-bottom: .16rem;
  }
  /deep/ .el-input__inner {
    height: .96rem !important;
    font-size: .24rem !important;
    color: #A7A7A7 !important;
  }
  /deep/.el-textarea__inner {
    min-height: 2.84rem !important;
    font-size: .24rem !important;
    color: #A7A7A7 !important;
    font-weight: 300 !important;
  }
  .btnBox {
    
    margin: 1.02rem auto 0;
    .subBtn { 
      width: 4.46rem;
      height: .88rem;
      font-size: .32rem;
    }
  }
}
</style>
