<template>
    <div>
      <pc-page v-if="$store.state.deviceType === '1'"></pc-page>
      <phone-page v-if="$store.state.deviceType === '2'"></phone-page>
    </div>
  </template>
   
  <script>
  import PcPage from './components/pcPage.vue';
  import PhonePage from './components/phonePage.vue';
  export default {
    name: "IndexPage",
    components: {
      PcPage,
      PhonePage
    },
    data() {
      return {
        
      }
    },
    methods: {
      goClass() {
      },
    }
  }
  </script>
   
  <style scoped lang="scss">

  // 兼容手机
  @media screen and (max-width: 756px) {

  }
  </style>