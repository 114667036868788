// common模块
import Common from './common/index'
// pages模块
import Pages from './pages/index'
const zh = {
  language: {
    name: 'English'
  },
  common: { // common模块
    // common模块下的base部分(页面)里面的中文
    base: Common.Base.ZH,
    // common模块下的base2部分(页面)里面的中文
    base2: Common.Base2.ZH
  },
  pages: {
    // pages模块下的main部分(页面)里面的中文
    main: Pages.Main.ZH,
    // pages模块下的product部分(页面)里面的中文
    product: Pages.Product.ZH,
    company: Pages.Company.ZH,
    news: Pages.News.ZH,
  }
}
export default zh