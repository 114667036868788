// pages模块里面的main部分(页面)数据
const info = {
  ZH: {
    // 中文
    banner1_t1: '致力成为',
    banner1_t2: '新能源行业领先锂电公司',
    banner1_t3: '关于盛利',
    banner2_t1: '丰富的产品解决方案',
    banner2_t2: `多种类${'\xa0\xa0'}多方案`,
    search_more: "探索更多",
    shengli: "盛利集团",
    shengli_desc: "致力于成为新能源锂电池系统领航者",
    shengli_t1: "盛利能源 · ",
    shengli_t2: "绿色动力",
    learn: "了解更多",
    shengli_p1: "盛利集团致力于成为绿色能源领航者，以环保动力电池、风能太阳能储能电池、数码设备电池、电动助力车等为一体的大型实业集团。集团旗下子公司20多家，主导项目遍及全球100多个国家和地区，在海外设有子公司和办事处。",
    shengli_p2: "以研发、生产、销售为一体的新能源科技集团公司。主要产品为锂电池、动力电池；以定制、批发为主；通过诸多国际认证，拥有多项知识产权专利证书；产品远销海外，国内主要和品牌电动车商合作...",
    shengli_notice1: "年自主研发实力",
    shengli_notice2: "亩占地面积",
    shengli_notice3: "研发技术人员",
    shengli_notice4: "工厂工人",
    proplan: "产品中心",
    proplan_desc: "Company Solutions",
    proplan_more: "探索更多 +",
    proplan_t1: "电芯系列",
    proplan_t1_desc: "高性能电芯系列，卓越能量密度，持久续航，安全可靠，为您的设备提供强大动力支持。",
    proplan_t2: "储能系列电池",
    proplan_t2_desc: "储能电池系列，高效储能，持久稳定，为绿色能源保驾护航，助力未来可持续发展。",
    proplan_t3: "电摩模组系列",
    proplan_t3_desc: "电摩模组系列电池，动力强劲，续航持久，安全可靠，为电动摩托车提供稳定能量支持。",
    proplan_t4: "锂电池系列",
    proplan_t4_desc: "锂电池系列，高效能量，持久续航，智能管理，为您的设备带来全新动力体验。",
    proplan_t5: "电动车系列",
    proplan_t5_desc: "帅凌电动车系列，时尚设计，卓越性能，高效能锂电池，让您的骑行更加轻松愉悦。",
    proplan_t6: "智能换电柜",
    proplan_t6_desc: "盛利智行换电柜，便捷高效，安全可靠，为您的电动生活提供无限动力，换电新时代，从此开启。",
    friend1: "合作",
    friend2: "伙伴",
    friend: "合作伙伴",
    friend_desc: "研发、生产、销售等成熟生态链",
    newsa: "新闻",
    newsb: "资讯",
    news: "新闻资讯",
    news_desc: "关注盛利集团新闻资讯，了解更多锂电池行业相关知识",
    btnText: "了解详情",
    imgList: [
      {
        title: "18650电芯系列",
        content: "低内阻，大容量，放电平稳",
      },
      {
        title: "银鱼海霸系列",
        content: "动力强劲，超长续航",
      },
      {
        title: "电摩模组系列",
        content: "高容量，超能量",
      },
      {
        title: "储能系列电池",
        content: "兼容性强，支持定制",
      },
    ],
  },
  EN: {
    // 英文
    banner1_t1: 'Strive to be',
    banner1_t2: 'New energy industry leading lithium battery company',
    banner1_t3: 'About SHENGLI',
    banner2_t1: 'Rich product solutions',
    banner2_t2: 'polytype Multi-plan',
    friend1: "cooperation",
    friend2: "buddy",
    search_more: "Explore more",
    shengli: "SHENGLI ENERGY",
    shengli_desc: "Committed to becoming the leader of new energy lithium battery system",
    shengli_t1: "Shengli Energy · ",
    shengli_t2: "Green Power",
    learn: "Learn more",
    shengli_p1: "Shengli Group is committed to becoming a leader in green energy, a large-scale industrial group integrating environmentally friendly power batteries, wind energy and solar energy storage batteries, digital equipment batteries, and electric mopeds. The group has more than 20 subsidiaries, leading projects in more than 100 countries and regions around the world, and has subsidiaries and offices overseas.",
    shengli_p2: "A new energy technology group company integrating R\u0026D, production and sales. The main products are lithium batteries and power batteries...",
    shengli_notice1: "years of experience",
    shengli_notice2: "square meters area",
    shengli_notice3: "R&D technicians",
    shengli_notice4: "factory worker",
    proplan: "Product Solution",
    proplan_more: "MORE",
    proplan_t1: "Battery cell series",
    proplan_t1_desc: "High performance battery cell series, excellent energy density, long-lasting endurance, safe and reliable, providing powerful power support for your equipment.",
    proplan_t2: "Energy storage series batteries",
    proplan_t2_desc: "The energy storage battery series provides efficient energy storage and long-lasting stability, safeguarding green energy and supporting sustainable development in the future.",
    proplan_t3: "Electric motorcycle module series",
    proplan_t3_desc: "The electric motorcycle module series battery has strong power, long endurance, safety and reliability, providing stable energy support for electric motorcycles.",
    proplan_t4: "Lithium battery series",
    proplan_t4_desc: "Lithium battery series, efficient energy, long-lasting endurance, intelligent management, bring a brand new power experience to your device.",
    proplan_t5: "Electric vehicle series",
    proplan_t5_desc: "The electric vehicle series features fashionable design, excellent performance, and high-efficiency lithium batteries, making your riding more relaxed and enjoyable.",
    proplan_t6: "Intelligent battery swapping cabinet",
    proplan_t6_desc: "Convenient and efficient, safe and reliable, providing unlimited power for your electric life, a new era of battery swapping begins from now on.",
    friend: "Partner",
    friend_desc: "Mature ecological chain of research and development, production, sales, etc",
    news: "News",
    news_desc: "Follow the news of Shengli Group to learn more about the lithium battery industry",
    btnText: "Learn more",
    imgList: [
      {
        title: "18650 battery cell series",
        content: "Low internal resistance, large capacity, stable discharge",
      },
      {
        title: "Whitebait Haiba Series",
        content: "High capacity, super energy",
      },
      {
        title: "Electric Motorcycle Module Series",
        content: "High capacity, super energy",
      },
      {
        title: "Energy storage battery",
        content: "Strong compatibility, support customization",
      },
    ],
  },
};
export default info;
