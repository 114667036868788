<template>
  <div class="lay-container">
    <div class="lay-top-bgz">
      <header-pc :category="categoryLists" v-if="$store.state.deviceType === '1'"></header-pc>
      <header-phone :category="categoryLists" v-if="$store.state.deviceType === '2'"></header-phone>
    </div>
    <div style="lay-content">
      <router-view></router-view>
    </div>
    <div class="lay-btm">
      <footer-pc :category="categoryLists" v-if="$store.state.deviceType === '1'"></footer-pc>
      <footer-phone :category="categoryLists" v-if="$store.state.deviceType === '2'"></footer-phone>
      <el-backtop></el-backtop>
    </div>
  </div>
</template>

<script>
import HeaderPc from './HeaderPc.vue'
import HeaderPhone from './HeaderPhone.vue'
import FooterPc from './FooterPc.vue'
import FooterPhone from './FooterPhone.vue'
import { categoryList } from "@/utils/api";
export default {
  name: 'LaYout',
  components: {
    HeaderPc,
    HeaderPhone,
    FooterPc,
    FooterPhone
  },
  data() {
    return {
      categoryLists: []
    }
  },
  created() { },
  mounted() {
    this.getCategoryList()
    // window.addEventListener('scroll', this.totopMsg, true);
  },
  watch: {
    '$i18n.locale': {
      handler: function(newVal,oldVal) {
        if (newVal !== oldVal) {
          this.getCategoryList()
        }
      }
    }
  },
  methods: {
    getCategoryList() {
      this.categoryLists = []
      categoryList({ language: this.$i18n.locale }).then(res => {
        if (res.code === "000000") {
          res.data.forEach(item => {
            item.child.forEach(it => {
              this.categoryLists.push({
                name: it.productTypeName,
                id: it.id,
                sort: it.sort
              })
            })
          });
          this.categoryLists.forEach((item, index) => {
            item.url = `/product?type=${index + 1}`
          })
          this.categoryLists.sort((a, b) => a.sort - b.sort)
          console.log(this.categoryLists, 'categoryLists');
        }
      })
    },
    totopMsg() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 100) {
        // this.showToTop = true
      } else {
        // this.showToTop = false
      }
    },
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.totopMsg, true);
  }
}
</script>

<style lang="scss" scoped>
.lay-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  .lay-content {
    flex: 1;
    overflow: hidden;
  }
}
</style>