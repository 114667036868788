// pages模块里面的news部分(页面)数据
const info = {
    ZH: { // 中文

      newsAll: {
        total: 5,
        list: [
          [
            {
              name: '国外锂电池品牌排行榜及简介',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/news_mobile.png',
              id: '101',
              desc: 'Panasonic松下（松下电器(中国)有限公司）　　始于1918年，隶属日本松下电器产业株式会社旗下，涉及家电、数码视听电子、办公产品、航空等诸多领域，专业从事各种电器产品的生产、销售的综合性电子技..',
              time: '2021-11-03',
              detail: [
                {
                  type: 'tt',
                  content: 'Panasonic松下（松下电器(中国)有限公司）'
                },
                {
                  type: 'p',
                  content: '始于1918年，隶属日本松下电器产业株式会社旗下，涉及家电、数码视听电子、办公产品、航空等诸多领域，专业从事各种电器产品的生产、销售的综合性电子技术企业集团。'
                },
                {
                  type: 'tt',
                  content: 'LG化学'
                },
                {
                  type: 'p',
                  content: '1947年成立，隶属于韩国三大之一baiLG集团，现为领导韩国化学工业的韩国最大的综合化学公司。'
                },
                {
                  type: 'p',
                  content: '主营业务：动力锂电池，基础材料，信息电子材料，生物化学。'
                },
                {
                  type: 'p',
                  content: '公司优势：韩国首批量产锂离子电池，拥有世界领先的技术力量，在汽车电池，ESS电池，移动设备电池领域主导市场。'
                },
                {
                  type: 'tt',
                  content: '三星SDI'
                },
                {
                  type: 'p',
                  content: '拥有12000名员工，其研发中心遍布韩国，日本。俄罗斯等国家，在台湾，德国，马来西亚，印度，越南等地也办有办事处，是全球锂电池厂家排名靠前的厂家。'
                },
                {
                  type: 'p',
                  content: '主营业务：小型锂离子电池，ESS，汽车电池，电子材料。'
                },
                {
                  type: 'p',
                  content: '公司优势：与世界多家厂商建立了锂离子电池合作关系，如大众汽车集团，宝马等。'
                },
                {
                  type: 'tt',
                  content: '索尼SONY'
                },
                {
                  type: 'p',
                  content: '日本的一家全球知名的大型综合性跨国企业集团，成立于1946年5月，世界很大的电子产品制造公司之一。2016年7月，村田制造所并购索尼电池事业部。'
                },
                {
                  type: 'p',
                  content: '主营业务：视频游戏机，数码配件，电子元器件，专业系统。'
                },
                {
                  type: 'p',
                  content: '公司优势：索尼是锂电池鼻祖，公司于1991年发布第一个商用锂电池，锂电池开始成为电子产品的动力来源。'
                },
                {
                  type: 'tt',
                  content: 'SK INNOVATION'
                },
                {
                  type: 'p',
                  content: '成立于1962年，其前身大韩石油公社是韩国第一家炼油公司，目前SKI是韩国最大的石油化工集团，公司广泛布局能源、化学、新材料等领域。'
                },
                {
                  type: 'p',
                  content: '主营业务：石油开发、电池'
                },
                {
                  type: 'p',
                  content: '公司优势：拥有动力锂电池隔膜的核心技术，全球第三个研发出锂电池隔膜的厂商，还拥有隔膜制造的低收缩性和耐热性专利技术，2008年开始就已经投入电动汽车使用；三菱扶桑、梅赛德斯-奔驰、现代、起亚的电池供应商。'
                },
                {
                  type: 'tt',
                  content: 'AESC'
                },
                {
                  type: 'p',
                  content: '成立于1994年，日本汽车株式会社（日本）与日本电气株式会社（NEC）、NEC东金株式会社（NEC东金）的合资公司。'
                },
                {
                  type: 'p',
                  content: '公司优势：与现行镍氢电池相比，AESC所研发的锂电池可提供近两倍的电力容量，而独特的层压式结构，也让锂电池模组的体积更为轻巧实用。'
                },
                {
                  type: 'tt',
                  content: 'A123系统公司'
                },
                {
                  type: 'p',
                  content: '美国一家专业开发和生产锂离子电池和能量存储系统的公司，成立于2001年，现在全球拥有2000多名员工，2013年被万向集团收购。'
                },
                {
                  type: 'p',
                  content: '主营业务：电池、模组、系统'
                },
                {
                  type: 'p',
                  content: '公司优势：锂离子电池以高功率和高能量密度，寿命长，和卓越的安全性能领先于锂离子电池市场。'
                },
              ],
            },
            {
              name: '公司组织松山湖秋游',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img2.jpg',
              id: '102',
              desc: '2017年11月16日，公司人事行政部组织公司PACK事业部全体管理人员秋游松山湖，首先进行了野外拓展训练，员工你拼我赶，争强了团队凝聚力，中午时分，大家集体进行了野外烧烤及厨艺大比拼，公司根据每..',
              time: '2021-11-03',
              detail: [
                {
                  type: 'text',
                  content: '2017年11月16日，公司人事行政部组织公司PACK事业部全体管理人员秋游松山湖，首先进行了野外拓展训练，员工你拼我赶，增强了团队凝聚力，中午时分，大家集体进行了野外烧烤及厨艺大比拼，公司根据每桌选手厨艺评比出一二三等奖，并分别给与鼓励。下午自由活动，大家骑着自行车，环游松山湖，尽情释放自我。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img21.jpg'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img22.jpg'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img23.jpg'
                },
              ],
            },
            {
              name: '夏天给手机充电7大注意事项',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg',
              id: '103',
              desc: '01、充电时，别玩手机　　手机一边充电，一边玩手机，不仅伤电池缩短电池寿命，还会导致手机大量发热。就比如，我们吃饭都需要先消化下，营养才能得到吸收，手机是一样的。02、充电时，将后台清理掉　　道理如同..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'tt',
                  content: '01、充电时，别玩手机'
                },
                {
                  type: 'p',
                  content: '手机一边充电，一边玩手机，不仅伤电池缩短电池寿命，还会导致手机大量发热。就比如，我们吃饭都需要先消化下，营养才能得到吸收，手机是一样的。'
                },
                {
                  type: 'tt',
                  content: '02、充电时，将后台清理掉'
                },
                {
                  type: 'p',
                  content: '道理如同一，夏天充电，如果没有特别紧急的事情，开启“超级省电”模式充电，充电又快又不发热。'
                },
                {
                  type: 'tt',
                  content: '03、充电时，将手机放地上'
                },
                {
                  type: 'p',
                  content: '手机降温有个特别好用的方法，就是将手机放地板上（瓷砖最好），地板是天然的散热板，能帮助手机散热，加快手机充电速度。'
                },
                {
                  type: 'tt',
                  content: '04、充电时，取掉手机保护壳'
                },
                {
                  type: 'p',
                  content: '手机壳，一般都是塑料、橡胶这类材料做成的，而这类材料是极其不利于散热的。所以取掉，让手机可以畅快充电。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg'
                },
                {
                  type: 'tt',
                  content: '05、充电时，切忌在室外用充电宝'
                },
                {
                  type: 'p',
                  content: '充电宝，是一个充电速度不是很快的工具，且容易发热。烈日当头，热得我们自己都受不了，手机也会热得不行，再用充电宝给手机充电，就是在太阳坝吃火锅呀。'
                },
                {
                  type: 'tt',
                  content: '06、充电时，别一冲就是一晚上'
                },
                {
                  type: 'p',
                  content: '实验证明，充电时间太长伤电池，黑夜漫漫，谁知道晚上电流对电池做了什么，让电池无法在正常储电，释电。小的电池就是过度充电搞坏的。泪了几个奔！'
                },
                {
                  type: 'tt',
                  content: '07、充电时，有小风扇，就对着手机吹吧'
                },
                {
                  type: 'p',
                  content: '夏天，要特别注意手机散热，给手机找个小风扇，就好比给电脑配个散热板一个道理。小V是舍不得给手机开超级省电，游戏还打得飞的人。所以夏天，小V给手机专门配了一个，哦，不是两个小风扇。（注意：不要将小风扇链接到手机上，要另外找电源。）'
                },
              ],
            },
            {
              name: '盛利集团举行第一届篮球比赛',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg',
              id: '104',
              desc: '为进一步丰富广大员工的业余文化生活，营造团结、拼搏、进取、和谐的团队氛围，增强体魄、振奋精神、凝聚力量，增强员工之间的企业凝聚力和自豪感。盛利集团举办了首场男子篮球比赛，各部门、各子公司、各事业部..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: '为进一步丰富广大员工的业余文化生活，营造团结、拼搏、进取、和谐的团队氛围，增强体魄、振奋精神、凝聚力量，增强员工之间的企业凝聚力和自豪感。盛利集团举办了首场男子篮球比赛，各部门、各子公司、各事业部积极响应，纷纷组织球队参加比赛；场外拉拉队更是热情高涨，欢呼声、呐喊声让篮球比赛气氛不断升温。全体运动员、裁判员、工作人员及现场观众表现突出。工作人员积极做好了后勤保障工作，裁判员公正无私，全体运动员真正发挥了友谊第一、比赛第二的精神，赛出了风格、赛出了水平。'
                },
                {
                  type: 'text',
                  content: '今天晚上的比赛是由动力事业部的“龙之队”和盛利通公司的“合一队”在一场欢乐的氛围下开始的，大家虽然在场下是亲密无间的同事，但在球场上却是你争我赶，不放过任何一个得分的机会，在大家期盼的目光和友谊的呐喊声鼓励下，经过艰苦鏖战，最终动力事业部“龙之队”夺得本次篮球比赛的首场胜利。'
                },
                {
                  type: 'text',
                  content: '此次篮球比赛不仅丰富了员工们的业余文体生活，更燃起了集团同仁们投身体育运动的热情和信心。让每个热爱体育运动的员工们重新点燃了运动的激情。体现了我公司一直提倡的注重培养员工综合素质的企业精神，同时加强了企业文化的深入贯彻，增进了同事间的友谊，培养了团结协作的精神。比赛达到了预期效果，同时凭借篮球的独特魅力也向我们展示了当代青年们朝气蓬勃的青春气息。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg'
                },
              ],
            },
            {
              name: '锂电池取得突破 手机续航有救了',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg',
              id: '201',
              desc: '相较于屏幕、处理器性能工艺、闪存芯片的快速发展，锂电池续航仍然是电子设备的“阿喀琉斯之踵”。　　就组成部分上来讲，锂电池由正极、负极、隔膜、电解液等组成，其关键性能指标（如倍率性能和循环寿命）由正极材..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: '相较于屏幕、处理器性能工艺、闪存芯片的快速发展，锂电池续航仍然是电子设备的“阿喀琉斯之踵”。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img202.jpg'
                },
                {
                  type: 'text',
                  content: '就组成部分上来讲，锂电池由正极、负极、隔膜、电解液等组成，其关键性能指标（如倍率性能和循环寿命）由正极材料的电化学性能决定，其中LiFePO4是公认的正极材料。'
                },
                {
                  type: 'text',
                  content: '据中科院金属所报道，该所沈阳材料科学国家（联合）实验室高性能陶瓷材料研究部王晓辉课题组在前期研究基础上，通过创造极度缺水的酸性合成环境， 在国际上首次制备出12nm厚的[100]取向LiFePO4超薄纳米片。'
                },
                {
                  type: 'text',
                  content: '该纳米片电极的意义在于是迄今为止最小的电压间隙，提高了活化粒子数比例。'
                },
                {
                  type: 'text',
                  content: '而我们知道，电极由大量粒子组成，其电化学性能主要依赖于充放电过程中同时参与电化学反应的粒子（活化粒子）占总粒子数的比例。'
                },
                {
                  type: 'text',
                  content: '中科院称，该电极具有优异的倍率性能和循环寿命，在10C（60分钟/10=6分钟）充放电倍率下，循环1000次后能保持初始容量的90%。在20C充放电倍率下，容量仍可达到理论容量的72%。'
                },
                {
                  type: 'text',
                  content: '外界普遍相信，该研究为提高锂电池容量、寿命进而改善电子设备续航提供了新的视角和方法。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg'
                },
              ],
            },
          ],
        ],
      },
      newsOne: {
        total: 4,
        list: [
          [
            {
              name: '国外锂电池品牌排行榜及简介',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img1.jpg',
              id: '101',
              desc: 'Panasonic松下（松下电器(中国)有限公司）　　始于1918年，隶属日本松下电器产业株式会社旗下，涉及家电、数码视听电子、办公产品、航空等诸多领域，专业从事各种电器产品的生产、销售的综合性电子技..',
              time: '2021-11-03',
              detail: [
                {
                  type: 'tt',
                  content: 'Panasonic松下（松下电器(中国)有限公司）'
                },
                {
                  type: 'p',
                  content: '始于1918年，隶属日本松下电器产业株式会社旗下，涉及家电、数码视听电子、办公产品、航空等诸多领域，专业从事各种电器产品的生产、销售的综合性电子技术企业集团。'
                },
                {
                  type: 'tt',
                  content: 'LG化学'
                },
                {
                  type: 'p',
                  content: '1947年成立，隶属于韩国三大之一baiLG集团，现为领导韩国化学工业的韩国最大的综合化学公司。'
                },
                {
                  type: 'p',
                  content: '主营业务：动力锂电池，基础材料，信息电子材料，生物化学。'
                },
                {
                  type: 'p',
                  content: '公司优势：韩国首批量产锂离子电池，拥有世界领先的技术力量，在汽车电池，ESS电池，移动设备电池领域主导市场。'
                },
                {
                  type: 'tt',
                  content: '三星SDI'
                },
                {
                  type: 'p',
                  content: '拥有12000名员工，其研发中心遍布韩国，日本。俄罗斯等国家，在台湾，德国，马来西亚，印度，越南等地也办有办事处，是全球锂电池厂家排名靠前的厂家。'
                },
                {
                  type: 'p',
                  content: '主营业务：小型锂离子电池，ESS，汽车电池，电子材料。'
                },
                {
                  type: 'p',
                  content: '公司优势：与世界多家厂商建立了锂离子电池合作关系，如大众汽车集团，宝马等。'
                },
                {
                  type: 'tt',
                  content: '索尼SONY'
                },
                {
                  type: 'p',
                  content: '日本的一家全球知名的大型综合性跨国企业集团，成立于1946年5月，世界很大的电子产品制造公司之一。2016年7月，村田制造所并购索尼电池事业部。'
                },
                {
                  type: 'p',
                  content: '主营业务：视频游戏机，数码配件，电子元器件，专业系统。'
                },
                {
                  type: 'p',
                  content: '公司优势：索尼是锂电池鼻祖，公司于1991年发布第一个商用锂电池，锂电池开始成为电子产品的动力来源。'
                },
                {
                  type: 'tt',
                  content: 'SK INNOVATION'
                },
                {
                  type: 'p',
                  content: '成立于1962年，其前身大韩石油公社是韩国第一家炼油公司，目前SKI是韩国最大的石油化工集团，公司广泛布局能源、化学、新材料等领域。'
                },
                {
                  type: 'p',
                  content: '主营业务：石油开发、电池'
                },
                {
                  type: 'p',
                  content: '公司优势：拥有动力锂电池隔膜的核心技术，全球第三个研发出锂电池隔膜的厂商，还拥有隔膜制造的低收缩性和耐热性专利技术，2008年开始就已经投入电动汽车使用；三菱扶桑、梅赛德斯-奔驰、现代、起亚的电池供应商。'
                },
                {
                  type: 'tt',
                  content: 'AESC'
                },
                {
                  type: 'p',
                  content: '成立于1994年，日本汽车株式会社（日本）与日本电气株式会社（NEC）、NEC东金株式会社（NEC东金）的合资公司。'
                },
                {
                  type: 'p',
                  content: '公司优势：与现行镍氢电池相比，AESC所研发的锂电池可提供近两倍的电力容量，而独特的层压式结构，也让锂电池模组的体积更为轻巧实用。'
                },
                {
                  type: 'tt',
                  content: 'A123系统公司'
                },
                {
                  type: 'p',
                  content: '美国一家专业开发和生产锂离子电池和能量存储系统的公司，成立于2001年，现在全球拥有2000多名员工，2013年被万向集团收购。'
                },
                {
                  type: 'p',
                  content: '主营业务：电池、模组、系统'
                },
                {
                  type: 'p',
                  content: '公司优势：锂离子电池以高功率和高能量密度，寿命长，和卓越的安全性能领先于锂离子电池市场。'
                },
              ],
            },
            {
              name: '公司组织松山湖秋游',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img2.jpg',
              id: '102',
              desc: '2017年11月16日，公司人事行政部组织公司PACK事业部全体管理人员秋游松山湖，首先进行了野外拓展训练，员工你拼我赶，争强了团队凝聚力，中午时分，大家集体进行了野外烧烤及厨艺大比拼，公司根据每..',
              time: '2021-11-03',
              detail: [
                {
                  type: 'text',
                  content: '2017年11月16日，公司人事行政部组织公司PACK事业部全体管理人员秋游松山湖，首先进行了野外拓展训练，员工你拼我赶，增强了团队凝聚力，中午时分，大家集体进行了野外烧烤及厨艺大比拼，公司根据每桌选手厨艺评比出一二三等奖，并分别给与鼓励。下午自由活动，大家骑着自行车，环游松山湖，尽情释放自我。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img21.jpg'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img22.jpg'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img23.jpg'
                },
              ],
            },
            {
              name: '夏天给手机充电7大注意事项',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg',
              id: '103',
              desc: '01、充电时，别玩手机　　手机一边充电，一边玩手机，不仅伤电池缩短电池寿命，还会导致手机大量发热。就比如，我们吃饭都需要先消化下，营养才能得到吸收，手机是一样的。02、充电时，将后台清理掉　　道理如同..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'tt',
                  content: '01、充电时，别玩手机'
                },
                {
                  type: 'p',
                  content: '手机一边充电，一边玩手机，不仅伤电池缩短电池寿命，还会导致手机大量发热。就比如，我们吃饭都需要先消化下，营养才能得到吸收，手机是一样的。'
                },
                {
                  type: 'tt',
                  content: '02、充电时，将后台清理掉'
                },
                {
                  type: 'p',
                  content: '道理如同一，夏天充电，如果没有特别紧急的事情，开启“超级省电”模式充电，充电又快又不发热。'
                },
                {
                  type: 'tt',
                  content: '03、充电时，将手机放地上'
                },
                {
                  type: 'p',
                  content: '手机降温有个特别好用的方法，就是将手机放地板上（瓷砖最好），地板是天然的散热板，能帮助手机散热，加快手机充电速度。'
                },
                {
                  type: 'tt',
                  content: '04、充电时，取掉手机保护壳'
                },
                {
                  type: 'p',
                  content: '手机壳，一般都是塑料、橡胶这类材料做成的，而这类材料是极其不利于散热的。所以取掉，让手机可以畅快充电。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg'
                },
                {
                  type: 'tt',
                  content: '05、充电时，切忌在室外用充电宝'
                },
                {
                  type: 'p',
                  content: '充电宝，是一个充电速度不是很快的工具，且容易发热。烈日当头，热得我们自己都受不了，手机也会热得不行，再用充电宝给手机充电，就是在太阳坝吃火锅呀。'
                },
                {
                  type: 'tt',
                  content: '06、充电时，别一冲就是一晚上'
                },
                {
                  type: 'p',
                  content: '实验证明，充电时间太长伤电池，黑夜漫漫，谁知道晚上电流对电池做了什么，让电池无法在正常储电，释电。小的电池就是过度充电搞坏的。泪了几个奔！'
                },
                {
                  type: 'tt',
                  content: '07、充电时，有小风扇，就对着手机吹吧'
                },
                {
                  type: 'p',
                  content: '夏天，要特别注意手机散热，给手机找个小风扇，就好比给电脑配个散热板一个道理。小V是舍不得给手机开超级省电，游戏还打得飞的人。所以夏天，小V给手机专门配了一个，哦，不是两个小风扇。（注意：不要将小风扇链接到手机上，要另外找电源。）'
                },
              ],
            },
            {
              name: '盛利集团举行第一届篮球比赛',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg',
              id: '104',
              desc: '为进一步丰富广大员工的业余文化生活，营造团结、拼搏、进取、和谐的团队氛围，增强体魄、振奋精神、凝聚力量，增强员工之间的企业凝聚力和自豪感。盛利集团举办了首场男子篮球比赛，各部门、各子公司、各事业部..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: '为进一步丰富广大员工的业余文化生活，营造团结、拼搏、进取、和谐的团队氛围，增强体魄、振奋精神、凝聚力量，增强员工之间的企业凝聚力和自豪感。盛利集团举办了首场男子篮球比赛，各部门、各子公司、各事业部积极响应，纷纷组织球队参加比赛；场外拉拉队更是热情高涨，欢呼声、呐喊声让篮球比赛气氛不断升温。全体运动员、裁判员、工作人员及现场观众表现突出。工作人员积极做好了后勤保障工作，裁判员公正无私，全体运动员真正发挥了友谊第一、比赛第二的精神，赛出了风格、赛出了水平。'
                },
                {
                  type: 'text',
                  content: '今天晚上的比赛是由动力事业部的“龙之队”和盛利通公司的“合一队”在一场欢乐的氛围下开始的，大家虽然在场下是亲密无间的同事，但在球场上却是你争我赶，不放过任何一个得分的机会，在大家期盼的目光和友谊的呐喊声鼓励下，经过艰苦鏖战，最终动力事业部“龙之队”夺得本次篮球比赛的首场胜利。'
                },
                {
                  type: 'text',
                  content: '此次篮球比赛不仅丰富了员工们的业余文体生活，更燃起了集团同仁们投身体育运动的热情和信心。让每个热爱体育运动的员工们重新点燃了运动的激情。体现了我公司一直提倡的注重培养员工综合素质的企业精神，同时加强了企业文化的深入贯彻，增进了同事间的友谊，培养了团结协作的精神。比赛达到了预期效果，同时凭借篮球的独特魅力也向我们展示了当代青年们朝气蓬勃的青春气息。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg'
                },
              ],
            },
          ],
        ],
      },
      newsTwo: {
        total: 1,
        list: [
          [
            {
              name: '锂电池取得突破 手机续航有救了',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg',
              id: '201',
              desc: '相较于屏幕、处理器性能工艺、闪存芯片的快速发展，锂电池续航仍然是电子设备的“阿喀琉斯之踵”。　　就组成部分上来讲，锂电池由正极、负极、隔膜、电解液等组成，其关键性能指标（如倍率性能和循环寿命）由正极材..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: '相较于屏幕、处理器性能工艺、闪存芯片的快速发展，锂电池续航仍然是电子设备的“阿喀琉斯之踵”。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img202.jpg'
                },
                {
                  type: 'text',
                  content: '就组成部分上来讲，锂电池由正极、负极、隔膜、电解液等组成，其关键性能指标（如倍率性能和循环寿命）由正极材料的电化学性能决定，其中LiFePO4是公认的正极材料。'
                },
                {
                  type: 'text',
                  content: '据中科院金属所报道，该所沈阳材料科学国家（联合）实验室高性能陶瓷材料研究部王晓辉课题组在前期研究基础上，通过创造极度缺水的酸性合成环境， 在国际上首次制备出12nm厚的[100]取向LiFePO4超薄纳米片。'
                },
                {
                  type: 'text',
                  content: '该纳米片电极的意义在于是迄今为止最小的电压间隙，提高了活化粒子数比例。'
                },
                {
                  type: 'text',
                  content: '而我们知道，电极由大量粒子组成，其电化学性能主要依赖于充放电过程中同时参与电化学反应的粒子（活化粒子）占总粒子数的比例。'
                },
                {
                  type: 'text',
                  content: '中科院称，该电极具有优异的倍率性能和循环寿命，在10C（60分钟/10=6分钟）充放电倍率下，循环1000次后能保持初始容量的90%。在20C充放电倍率下，容量仍可达到理论容量的72%。'
                },
                {
                  type: 'text',
                  content: '外界普遍相信，该研究为提高锂电池容量、寿命进而改善电子设备续航提供了新的视角和方法。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg'
                },
              ],
            },
          ],
        ],
      },

      newsThree: {
        total: 0,
        list: [
          []
        ],
      },
      news1: {
        total: 4,
        list: [
          [
            // {
            //   url: '/newsDetail?type=1',
            //   name: '国外锂电池品牌排行榜及简介',
            //   img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/news_mobile.png',
            //   id: '14',
            //   desc: 'Panasonic松下（松下电器(中国)有限公司）　　始于1918年，隶属日本松下电器产业株式会社旗下，涉及家电、数码视听电子、办公产品、航空等诸多领域，专业从事各种电器产品的生产、销售的综合性电子技..',
            //   time: '2021-11-03',
            //   detail: [
            //     {
            //       type: 'tt',
            //       content: 'Panasonic松下（松下电器(中国)有限公司）'
            //     },
            //     {
            //       type: 'p',
            //       content: '始于1918年，隶属日本松下电器产业株式会社旗下，涉及家电、数码视听电子、办公产品、航空等诸多领域，专业从事各种电器产品的生产、销售的综合性电子技术企业集团。'
            //     },
            //     {
            //       type: 'tt',
            //       content: 'LG化学'
            //     },
            //     {
            //       type: 'p',
            //       content: '1947年成立，隶属于韩国三大之一baiLG集团，现为领导韩国化学工业的韩国最大的综合化学公司。'
            //     },
            //     {
            //       type: 'p',
            //       content: '主营业务：动力锂电池，基础材料，信息电子材料，生物化学。'
            //     },
            //     {
            //       type: 'p',
            //       content: '公司优势：韩国首批量产锂离子电池，拥有世界领先的技术力量，在汽车电池，ESS电池，移动设备电池领域主导市场。'
            //     },
            //     {
            //       type: 'tt',
            //       content: '三星SDI'
            //     },
            //     {
            //       type: 'p',
            //       content: '拥有12000名员工，其研发中心遍布韩国，日本。俄罗斯等国家，在台湾，德国，马来西亚，印度，越南等地也办有办事处，是全球锂电池厂家排名靠前的厂家。'
            //     },
            //     {
            //       type: 'p',
            //       content: '主营业务：小型锂离子电池，ESS，汽车电池，电子材料。'
            //     },
            //     {
            //       type: 'p',
            //       content: '公司优势：与世界多家厂商建立了锂离子电池合作关系，如大众汽车集团，宝马等。'
            //     },
            //     {
            //       type: 'tt',
            //       content: '索尼SONY'
            //     },
            //     {
            //       type: 'p',
            //       content: '日本的一家全球知名的大型综合性跨国企业集团，成立于1946年5月，世界很大的电子产品制造公司之一。2016年7月，村田制造所并购索尼电池事业部。'
            //     },
            //     {
            //       type: 'p',
            //       content: '主营业务：视频游戏机，数码配件，电子元器件，专业系统。'
            //     },
            //     {
            //       type: 'p',
            //       content: '公司优势：索尼是锂电池鼻祖，公司于1991年发布第一个商用锂电池，锂电池开始成为电子产品的动力来源。'
            //     },
            //     {
            //       type: 'tt',
            //       content: 'SK INNOVATION'
            //     },
            //     {
            //       type: 'p',
            //       content: '成立于1962年，其前身大韩石油公社是韩国第一家炼油公司，目前SKI是韩国最大的石油化工集团，公司广泛布局能源、化学、新材料等领域。'
            //     },
            //     {
            //       type: 'p',
            //       content: '主营业务：石油开发、电池'
            //     },
            //     {
            //       type: 'p',
            //       content: '公司优势：拥有动力锂电池隔膜的核心技术，全球第三个研发出锂电池隔膜的厂商，还拥有隔膜制造的低收缩性和耐热性专利技术，2008年开始就已经投入电动汽车使用；三菱扶桑、梅赛德斯-奔驰、现代、起亚的电池供应商。'
            //     },
            //     {
            //       type: 'tt',
            //       content: 'AESC'
            //     },
            //     {
            //       type: 'p',
            //       content: '成立于1994年，日本汽车株式会社（日本）与日本电气株式会社（NEC）、NEC东金株式会社（NEC东金）的合资公司。'
            //     },
            //     {
            //       type: 'p',
            //       content: '公司优势：与现行镍氢电池相比，AESC所研发的锂电池可提供近两倍的电力容量，而独特的层压式结构，也让锂电池模组的体积更为轻巧实用。'
            //     },
            //     {
            //       type: 'tt',
            //       content: 'A123系统公司'
            //     },
            //     {
            //       type: 'p',
            //       content: '美国一家专业开发和生产锂离子电池和能量存储系统的公司，成立于2001年，现在全球拥有2000多名员工，2013年被万向集团收购。'
            //     },
            //     {
            //       type: 'p',
            //       content: '主营业务：电池、模组、系统'
            //     },
            //     {
            //       type: 'p',
            //       content: '公司优势：锂离子电池以高功率和高能量密度，寿命长，和卓越的安全性能领先于锂离子电池市场。'
            //     },
            //   ],
            // },
            {
              url: '/newsDetail?type=1',
              name: '冬日出游篇',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/newsPic5.png',
              id: '17',
              desc: '为了提高员工身体锻炼意识，丰富员工业余生活，适当舒缓员工工作压力；促进员工间的沟通和营造愉快和谐的伙伴氛围，在公司领导的大力支持下，12月19号公司组织团队攀登东莞第一峰 — 银屏山。',
              time: '2020-12-28',
            },
            // {
            //   url: '/newsDetail?type=1',
            //   name: '公司组织松山湖秋游',
            //   img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img2.jpg',
            //   id: '13',
            //   desc: '2017年11约16日，公司人事行政部组织公司PACK事业部全体管理人员秋游松山湖，首先进行了野外拓展训练，员工你拼我赶，争强了团队凝聚力，中午时分，大家集体进行了野外烧烤及厨艺大比拼，公司根据每..',
            //   time: '2021-11-03',
            //   detail: [
            //     {
            //       type: 'text',
            //       content: '2017年11月16日，公司人事行政部组织公司PACK事业部全体管理人员秋游松山湖，首先进行了野外拓展训练，员工你拼我赶，增强了团队凝聚力，中午时分，大家集体进行了野外烧烤及厨艺大比拼，公司根据每桌选手厨艺评比出一二三等奖，并分别给与鼓励。下午自由活动，大家骑着自行车，环游松山湖，尽情释放自我。'
            //     },
            //     {
            //       type: 'img',
            //       content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img21.jpg'
            //     },
            //     {
            //       type: 'img',
            //       content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img22.jpg'
            //     },
            //     {
            //       type: 'img',
            //       content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img23.jpg'
            //     },
            //   ],
            // },
            // {
            //   url: '/news?type=1',
            //   name: '夏天给手机充电7大注意事项',
            //   img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg',
            //   id: '103',
            //   desc: '01、充电时，别玩手机　　手机一边充电，一边玩手机，不仅伤电池缩短电池寿命，还会导致手机大量发热。就比如，我们吃饭都需要先消化下，营养才能得到吸收，手机是一样的。02、充电时，将后台清理掉　　道理如同..',
            //   time: '2021-02-23',
            //   detail: [
            //     {
            //       type: 'tt',
            //       content: '01、充电时，别玩手机'
            //     },
            //     {
            //       type: 'p',
            //       content: '手机一边充电，一边玩手机，不仅伤电池缩短电池寿命，还会导致手机大量发热。就比如，我们吃饭都需要先消化下，营养才能得到吸收，手机是一样的。'
            //     },
            //     {
            //       type: 'tt',
            //       content: '02、充电时，将后台清理掉'
            //     },
            //     {
            //       type: 'p',
            //       content: '道理如同一，夏天充电，如果没有特别紧急的事情，开启“超级省电”模式充电，充电又快又不发热。'
            //     },
            //     {
            //       type: 'tt',
            //       content: '03、充电时，将手机放地上'
            //     },
            //     {
            //       type: 'p',
            //       content: '手机降温有个特别好用的方法，就是将手机放地板上（瓷砖最好），地板是天然的散热板，能帮助手机散热，加快手机充电速度。'
            //     },
            //     {
            //       type: 'tt',
            //       content: '04、充电时，取掉手机保护壳'
            //     },
            //     {
            //       type: 'p',
            //       content: '手机壳，一般都是塑料、橡胶这类材料做成的，而这类材料是极其不利于散热的。所以取掉，让手机可以畅快充电。'
            //     },
            //     {
            //       type: 'img',
            //       content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg'
            //     },
            //     {
            //       type: 'tt',
            //       content: '05、充电时，切忌在室外用充电宝'
            //     },
            //     {
            //       type: 'p',
            //       content: '充电宝，是一个充电速度不是很快的工具，且容易发热。烈日当头，热得我们自己都受不了，手机也会热得不行，再用充电宝给手机充电，就是在太阳坝吃火锅呀。'
            //     },
            //     {
            //       type: 'tt',
            //       content: '06、充电时，别一冲就是一晚上'
            //     },
            //     {
            //       type: 'p',
            //       content: '实验证明，充电时间太长伤电池，黑夜漫漫，谁知道晚上电流对电池做了什么，让电池无法在正常储电，释电。小的电池就是过度充电搞坏的。泪了几个奔！'
            //     },
            //     {
            //       type: 'tt',
            //       content: '07、充电时，有小风扇，就对着手机吹吧'
            //     },
            //     {
            //       type: 'p',
            //       content: '夏天，要特别注意手机散热，给手机找个小风扇，就好比给电脑配个散热板一个道理。小V是舍不得给手机开超级省电，游戏还打得飞的人。所以夏天，小V给手机专门配了一个，哦，不是两个小风扇。（注意：不要将小风扇链接到手机上，要另外找电源。）'
            //     },
            //   ],
            // },
            {
              url: '/newsDetail?type=1',
              name: '盛利集团举行第一届篮球比赛',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg',
              id: '14',
              desc: '为进一步丰富广大员工的业余文化生活，营造团结、拼搏、进取、和谐的团队氛围，增强体魄、振奋精神、凝聚力量，增强员工之间的企业凝聚力和自豪感。盛利集团举办了首场男子篮球比赛，各部门、各子公司、各事业部..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: '为进一步丰富广大员工的业余文化生活，营造团结、拼搏、进取、和谐的团队氛围，增强体魄、振奋精神、凝聚力量，增强员工之间的企业凝聚力和自豪感。盛利集团举办了首场男子篮球比赛，各部门、各子公司、各事业部积极响应，纷纷组织球队参加比赛；场外拉拉队更是热情高涨，欢呼声、呐喊声让篮球比赛气氛不断升温。全体运动员、裁判员、工作人员及现场观众表现突出。工作人员积极做好了后勤保障工作，裁判员公正无私，全体运动员真正发挥了友谊第一、比赛第二的精神，赛出了风格、赛出了水平。'
                },
                {
                  type: 'text',
                  content: '今天晚上的比赛是由动力事业部的“龙之队”和盛利通公司的“合一队”在一场欢乐的氛围下开始的，大家虽然在场下是亲密无间的同事，但在球场上却是你争我赶，不放过任何一个得分的机会，在大家期盼的目光和友谊的呐喊声鼓励下，经过艰苦鏖战，最终动力事业部“龙之队”夺得本次篮球比赛的首场胜利。'
                },
                {
                  type: 'text',
                  content: '此次篮球比赛不仅丰富了员工们的业余文体生活，更燃起了集团同仁们投身体育运动的热情和信心。让每个热爱体育运动的员工们重新点燃了运动的激情。体现了我公司一直提倡的注重培养员工综合素质的企业精神，同时加强了企业文化的深入贯彻，增进了同事间的友谊，培养了团结协作的精神。比赛达到了预期效果，同时凭借篮球的独特魅力也向我们展示了当代青年们朝气蓬勃的青春气息。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg'
                },
              ],
            },
          ],
        ],
      },
      news2: {
        total: 1,
        list: [
          [
            {
              url: '/news?type=2',
              name: '锂电池取得突破 手机续航有救了',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg',
              id: '201',
              desc: '相较于屏幕、处理器性能工艺、闪存芯片的快速发展，锂电池续航仍然是电子设备的“阿喀琉斯之踵”。　　就组成部分上来讲，锂电池由正极、负极、隔膜、电解液等组成，其关键性能指标（如倍率性能和循环寿命）由正极材..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: '相较于屏幕、处理器性能工艺、闪存芯片的快速发展，锂电池续航仍然是电子设备的“阿喀琉斯之踵”。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img202.jpg'
                },
                {
                  type: 'text',
                  content: '就组成部分上来讲，锂电池由正极、负极、隔膜、电解液等组成，其关键性能指标（如倍率性能和循环寿命）由正极材料的电化学性能决定，其中LiFePO4是公认的正极材料。'
                },
                {
                  type: 'text',
                  content: '据中科院金属所报道，该所沈阳材料科学国家（联合）实验室高性能陶瓷材料研究部王晓辉课题组在前期研究基础上，通过创造极度缺水的酸性合成环境， 在国际上首次制备出12nm厚的[100]取向LiFePO4超薄纳米片。'
                },
                {
                  type: 'text',
                  content: '该纳米片电极的意义在于是迄今为止最小的电压间隙，提高了活化粒子数比例。'
                },
                {
                  type: 'text',
                  content: '而我们知道，电极由大量粒子组成，其电化学性能主要依赖于充放电过程中同时参与电化学反应的粒子（活化粒子）占总粒子数的比例。'
                },
                {
                  type: 'text',
                  content: '中科院称，该电极具有优异的倍率性能和循环寿命，在10C（60分钟/10=6分钟）充放电倍率下，循环1000次后能保持初始容量的90%。在20C充放电倍率下，容量仍可达到理论容量的72%。'
                },
                {
                  type: 'text',
                  content: '外界普遍相信，该研究为提高锂电池容量、寿命进而改善电子设备续航提供了新的视角和方法。'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg'
                },
              ],
            },
          ],
        ],
      },
      news3: {
        total: 0,
        list: [
          []
        ],
      },
      detail:'查看详情',
      nomore:'暂无数据'
    },
    EN: { // 英文
      detail:'view details',

      newsAll: {
        total: 5,
        list: [
          [
            {
              name: 'Ranking and Introduction of Foreign Lithium Battery Brands',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img1.jpg',
              id: '101',
              desc: 'began in 1918 and is a comprehensive electronic technology enterprise group specialized in the pr..',
              time: '2021-11-03',
              detail: [
                {
                  type: 'tt',
                  content: 'Panasonic Matsushita (Panasonic Electric (China) Co., Ltd.)'
                },
                {
                  type: 'p',
                  content: "began in 1918 and is a comprehensive electronic technology enterprise group specialized in the production and sales of various electrical products, covering many fields such as household appliances, digital audio-visual electronics, office products, aviation, etc., under the subsidiary of Japan's Matsushita Electric Industrial Co., Ltd."
                },
                {
                  type: 'tt',
                  content: 'LG Chem'
                },
                {
                  type: 'p',
                  content: 'Established in 1947, it belongs to the baiLG Group, one of the three largest in South Korea, and is currently the largest comprehensive chemical company in South Korea that leads the South Korean chemical industry.'
                },
                {
                  type: 'p',
                  content: 'Main business: power lithium battery, basic materials, information and electronic materials, biochemistry.'
                },
                {
                  type: 'p',
                  content: 'Company advantage: South Korea’s first mass production of lithium-ion batteries, has the world’s leading technical force, and leads the market in the fields of car batteries, ESS batteries, and mobile device batteries.'
                },
                {
                  type: 'tt',
                  content: 'Samsung SDI'
                },
                {
                  type: 'p',
                  content: 'has 12,000 employees and its R&D centers are located in Korea and Japan. Russia and other countries also have offices in Taiwan, Germany, Malaysia, India, Vietnam and other places, and are among the top manufacturers of lithium battery manufacturers in the world.'
                },
                {
                  type: 'p',
                  content: 'main business: small lithium-ion batteries, ESS, car batteries, electronic materials.'
                },
                {
                  type: 'p',
                  content: 'Company advantage: Established lithium-ion battery cooperative relationship with many manufacturers in the world, such as Volkswagen Group, BMW and so on.'
                },
                {
                  type: 'tt',
                  content: 'Sony SONY'
                },
                {
                  type: 'p',
                  content: "Japan is a world-renowned large-scale comprehensive multinational enterprise group, founded in May 1946, one of the world's largest electronic product manufacturing companies. In July 2016, Murata Manufacturing Co., Ltd. acquired Sony's battery business unit."
                },
                {
                  type: 'p',
                  content: 'main business: video game consoles, digital accessories, electronic components, professional systems.'
                },
                {
                  type: 'p',
                  content: 'Company advantage: Sony is the originator of lithium batteries. The company released the first commercial lithium battery in 1991. Lithium batteries began to become a source of power for electronic products.'
                },
                {
                  type: 'tt',
                  content: 'SK INNOVATION'
                },
                {
                  type: 'p',
                  content: 'was established in 1962. Its predecessor, Korea Petroleum Corporation, was South Korea’s first oil refining company. At present, SKI is South Korea’s largest petrochemical group. The company has a wide range of fields such as energy, chemistry, and new materials.'
                },
                {
                  type: 'p',
                  content: 'Main business: oil development, battery'
                },
                {
                  type: 'p',
                  content: ' Company advantages: Possess the core technology of power lithium battery separators, the third manufacturer in the world to develop lithium battery separators, and also owns the patented technology of low shrinkage and heat resistance for separator manufacturing. It has been used in electric vehicles since 2008; Mitsubishi Battery suppliers for Fuso, Mercedes-Benz, Hyundai, and Kia.'
                },
                {
                  type: 'tt',
                  content: 'AESC'
                },
                {
                  type: 'p',
                  content: 'was established in 1994 as a joint venture of Japan Automobile Co., Ltd. (Japan), NEC Corporation (NEC), and NEC Tokin Co., Ltd. (NEC Tokin).'
                },
                {
                  type: 'p',
                  content: 'Company advantage: Compared with the current Ni-MH battery, the lithium battery developed by AESC can provide nearly twice the power capacity, and the unique laminated structure also makes the volume of the lithium battery module lighter and more practical.'
                },
                {
                  type: 'tt',
                  content: 'A123 Systems Corporation'
                },
                {
                  type: 'p',
                  content: 'An American company specializing in the development and production of lithium-ion batteries and energy storage systems was established in 2001 and now has more than 2,000 employees worldwide. It was acquired by Wanxiang Group in 2013.'
                },
                {
                  type: 'p',
                  content: 'Main business: batteries, modules, systems'
                },
                {
                  type: 'p',
                  content: 'Company advantage: Lithium-ion batteries lead the lithium-ion battery market with high power, high energy density, long life, and excellent safety performance.'
                },
              ],
            },
            {
              name: 'The company organizes an autumn tour of Songshan Lake',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img2.jpg',
              id: '102',
              desc: 'On November 16th, 2017, the personnel administration department of the company organized all manag..',
              time: '2021-11-03',
              detail: [
                {
                  type: 'text',
                  content: "On November 16th, 2017, the personnel administration department of the company organized all managers of the company’s PACK business department to visit Songshan Lake in autumn. First, they carried out field development training. The employees worked hard to strengthen the team cohesion. At noon, everyone went to the field. Barbecue and culinary competition, the company according to each table contestant's culinary evaluation of the first two third prizes, and respectively give encouragement. Free activities in the afternoon. Everyone rides a bicycle and travels around Songshan Lake to release themselves."
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img21.jpg'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img22.jpg'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img23.jpg'
                },
              ],
            },
            {
              name: '7 things to note when charging your mobile phone in summer',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg',
              id: '103',
              desc: 'While charging the phone while playing with the phone, it will not only damage the battery and sho..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'tt',
                  content: "01. Don't play with your phone while charging"
                },
                {
                  type: 'p',
                  content: "While charging the phone while playing with the phone, it will not only damage the battery and shorten the battery life, but also cause the phone to heat up a lot. For example, we all need to digest food before nutrition can be absorbed. The mobile phone is the same."
                },
                {
                  type: 'tt',
                  content: "02. When charging, clean up the background"
                },
                {
                  type: 'p',
                  content: 'The principle is the same, charging in summer, if there is no special emergency, turn on the "super power saving" mode to charge, the charging is fast and does not heat up.'
                },
                {
                  type: 'tt',
                  content: '03. When charging, put the phone on the ground'
                },
                {
                  type: 'p',
                  content: 'A particularly useful way to cool the phone is to put the phone on the floor (tiles are best). The floor is a natural heat sink, which can help the phone dissipate heat and speed up the charging of the phone.'
                },
                {
                  type: 'tt',
                  content: '04. Remove the protective case of the phone when charging'
                },
                {
                  type: 'p',
                  content: 'Mobile phone cases are generally made of materials such as plastic and rubber, which are extremely unfavorable for heat dissipation. So remove it, so that the phone can be charged freely.'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg'
                },
                {
                  type: 'tt',
                  content: '05. When charging, do not use power banks outdoors'
                },
                {
                  type: 'p',
                  content: "Power Bank is a tool that does not charge very quickly and is easy to heat up. The scorching sun is so hot that we can't stand the heat, and the mobile phone will be too hot. Then use the power bank to charge the mobile phone, which is to eat hot pot in the sun dam."
                },
                {
                  type: 'tt',
                  content: '06. When charging, don’t charge it overnight'
                },
                {
                  type: 'p',
                  content: 'Experiments have proved that charging time is too long to damage the battery, and the night is long, who knows what the current does to the battery at night, so that the battery cannot be stored and discharged normally. The small battery is damaged by overcharging. A few tears ran!'
                },
                {
                  type: 'tt',
                  content: '07. When charging, there is a small fan, just blow it at the phone'
                },
                {
                  type: 'p',
                  content: 'In summer, pay special attention to the heat dissipation of the mobile phone. Finding a small fan for the mobile phone is like giving a computer a heat sink. Xiao V is a person who is reluctant to turn on the super power-saving mobile phone, and the game is still flying. So in the summer, Xiao V specially equipped the mobile phone with one, oh, not two small fans. (Note: Do not link the small fan to the phone, find a power supply separately.)'
                },
              ],
            },
            {
              name: 'Shengli Group held the first basketball game',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg',
              id: '104',
              desc: 'In order to further enrich the amateur cultural life of the majority of employees, create a united..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: 'In order to further enrich the amateur cultural life of the majority of employees, create a united, hard-working, enterprising and harmonious team atmosphere, strengthen physique, invigorate the spirit, gather strength, and enhance the corporate cohesion and sense of pride among employees. Shengli Group held the first men’s basketball game. Various departments, subsidiaries and business divisions responded positively and organized teams to participate in the game. Cheerleaders outside the court were even more enthusiastic. Cheers and shouts made the atmosphere of the basketball game continue to heat up. . All athletes, referees, staff and on-site spectators performed outstandingly. The staff actively did a good job in logistics support, the referees were fair and unselfish, and all the athletes truly displayed the spirit of friendship first and second in the game, the style of the game and the level of the game.'
                },
                {
                  type: 'text',
                  content: 'Tonight’s game was started by the "Dragon Team" of the Power Division and the "One Team" of Shenglitong Company in a happy atmosphere. Although everyone is close colleagues off the court, they are on the court. It’s you who rushed to catch me. Don’t miss any chance of scoring. Encouraged by everyone’s expectations and the shouts of friendship, after a hard fight, the “Dragon Team” of the Power Division finally won the first place in this basketball game. Victory.'
                },
                {
                  type: 'text',
                  content: 'This basketball game not only enriched the amateur cultural and sports life of the employees, but also ignited the enthusiasm and confidence of the group colleagues to participate in sports. Let every employee who loves sports reignite the passion of sports. It embodies the corporate spirit that our company has always advocated focusing on cultivating the comprehensive quality of employees, and at the same time strengthens the in-depth implementation of corporate culture, enhances the friendship between colleagues, and cultivates the spirit of unity and cooperation. The game achieved the expected results, and at the same time, with the unique charm of basketball, it also showed us the vigorous and youthful atmosphere of contemporary youth.'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg'
                },
              ],
            },
            {
              name: 'Lithium battery has made a breakthrough, mobile phone battery life can be saved',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg',
              id: '201',
              desc: `Compared with the rapid development of screens, processor performance technology, and flash memory chips, lithium battery life is still the "Achilles' heel" of electronic devices.`,
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: `Compared with the rapid development of screens, processor performance technology, and flash memory chips, lithium battery life is still the "Achilles' heel" of electronic devices.`
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img202.jpg'
                },
                {
                  type: 'text',
                  content: 'In terms of components, a lithium battery is composed of a positive electrode, a negative electrode, a separator, an electrolyte, etc. Its key performance indicators (such as rate performance and cycle life) are determined by the electrochemical performance of the positive electrode material, of which LiFePO4 is a recognized positive electrode material.'
                },
                {
                  type: 'text',
                  content: 'According to a report from the Institute of Metals of the Chinese Academy of Sciences, the research group of Wang Xiaohui of the High Performance Ceramic Materials Research Department of the Shenyang National (Joint) Laboratory of Materials Science has prepared a 12nm thickness for the first time in the world by creating an acidic synthesis environment with extreme water shortage on the basis of previous research. [100] oriented LiFePO4 ultra-thin nanosheets.'
                },
                {
                  type: 'text',
                  content: 'The significance of the nanoplate electrode is that it is the smallest voltage gap so far, which increases the proportion of activated particles.'
                },
                {
                  type: 'text',
                  content: 'And we know that the electrode is composed of a large number of particles, and its electrochemical performance mainly depends on the proportion of the particles (activated particles) that participate in the electrochemical reaction at the same time during the charge and discharge process to the total number of particles.'
                },
                {
                  type: 'text',
                  content: 'According to the Chinese Academy of Sciences, the electrode has excellent rate performance and cycle life. At a charge and discharge rate of 10C (60 minutes/10=6 minutes), it can maintain 90% of the initial capacity after 1,000 cycles. At 20C charge and discharge rate, the capacity can still reach 72% of the theoretical capacity.'
                },
                {
                  type: 'text',
                  content: 'It is generally believed that the research provides a new perspective and method for improving the capacity and life of lithium batteries, thereby improving the battery life of electronic devices.'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg'
                },
              ],
            },
          ],
        ],
      },
      newsOne: {
        total: 4,
        list: [
          [
            {
              name: 'Ranking and Introduction of Foreign Lithium Battery Brands',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img1.jpg',
              id: '101',
              desc: 'began in 1918 and is a comprehensive electronic technology enterprise group specialized in the pr..',
              time: '2021-11-03',
              detail: [
                {
                  type: 'tt',
                  content: 'Panasonic Matsushita (Panasonic Electric (China) Co., Ltd.)'
                },
                {
                  type: 'p',
                  content: "began in 1918 and is a comprehensive electronic technology enterprise group specialized in the production and sales of various electrical products, covering many fields such as household appliances, digital audio-visual electronics, office products, aviation, etc., under the subsidiary of Japan's Matsushita Electric Industrial Co., Ltd."
                },
                {
                  type: 'tt',
                  content: 'LG Chem'
                },
                {
                  type: 'p',
                  content: 'Established in 1947, it belongs to the baiLG Group, one of the three largest in South Korea, and is currently the largest comprehensive chemical company in South Korea that leads the South Korean chemical industry.'
                },
                {
                  type: 'p',
                  content: 'Main business: power lithium battery, basic materials, information and electronic materials, biochemistry.'
                },
                {
                  type: 'p',
                  content: 'Company advantage: South Korea’s first mass production of lithium-ion batteries, has the world’s leading technical force, and leads the market in the fields of car batteries, ESS batteries, and mobile device batteries.'
                },
                {
                  type: 'tt',
                  content: 'Samsung SDI'
                },
                {
                  type: 'p',
                  content: 'has 12,000 employees and its R&D centers are located in Korea and Japan. Russia and other countries also have offices in Taiwan, Germany, Malaysia, India, Vietnam and other places, and are among the top manufacturers of lithium battery manufacturers in the world.'
                },
                {
                  type: 'p',
                  content: 'main business: small lithium-ion batteries, ESS, car batteries, electronic materials.'
                },
                {
                  type: 'p',
                  content: 'Company advantage: Established lithium-ion battery cooperative relationship with many manufacturers in the world, such as Volkswagen Group, BMW and so on.'
                },
                {
                  type: 'tt',
                  content: 'Sony SONY'
                },
                {
                  type: 'p',
                  content: "Japan is a world-renowned large-scale comprehensive multinational enterprise group, founded in May 1946, one of the world's largest electronic product manufacturing companies. In July 2016, Murata Manufacturing Co., Ltd. acquired Sony's battery business unit."
                },
                {
                  type: 'p',
                  content: 'main business: video game consoles, digital accessories, electronic components, professional systems.'
                },
                {
                  type: 'p',
                  content: 'Company advantage: Sony is the originator of lithium batteries. The company released the first commercial lithium battery in 1991. Lithium batteries began to become a source of power for electronic products.'
                },
                {
                  type: 'tt',
                  content: 'SK INNOVATION'
                },
                {
                  type: 'p',
                  content: 'was established in 1962. Its predecessor, Korea Petroleum Corporation, was South Korea’s first oil refining company. At present, SKI is South Korea’s largest petrochemical group. The company has a wide range of fields such as energy, chemistry, and new materials.'
                },
                {
                  type: 'p',
                  content: 'Main business: oil development, battery'
                },
                {
                  type: 'p',
                  content: ' Company advantages: Possess the core technology of power lithium battery separators, the third manufacturer in the world to develop lithium battery separators, and also owns the patented technology of low shrinkage and heat resistance for separator manufacturing. It has been used in electric vehicles since 2008; Mitsubishi Battery suppliers for Fuso, Mercedes-Benz, Hyundai, and Kia.'
                },
                {
                  type: 'tt',
                  content: 'AESC'
                },
                {
                  type: 'p',
                  content: 'was established in 1994 as a joint venture of Japan Automobile Co., Ltd. (Japan), NEC Corporation (NEC), and NEC Tokin Co., Ltd. (NEC Tokin).'
                },
                {
                  type: 'p',
                  content: 'Company advantage: Compared with the current Ni-MH battery, the lithium battery developed by AESC can provide nearly twice the power capacity, and the unique laminated structure also makes the volume of the lithium battery module lighter and more practical.'
                },
                {
                  type: 'tt',
                  content: 'A123 Systems Corporation'
                },
                {
                  type: 'p',
                  content: 'An American company specializing in the development and production of lithium-ion batteries and energy storage systems was established in 2001 and now has more than 2,000 employees worldwide. It was acquired by Wanxiang Group in 2013.'
                },
                {
                  type: 'p',
                  content: 'Main business: batteries, modules, systems'
                },
                {
                  type: 'p',
                  content: 'Company advantage: Lithium-ion batteries lead the lithium-ion battery market with high power, high energy density, long life, and excellent safety performance.'
                },
              ],
            },
            {
              name: 'The company organizes an autumn tour of Songshan Lake',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img2.jpg',
              id: '102',
              desc: 'On November 16th, 2017, the personnel administration department of the company organized all manag..',
              time: '2021-11-03',
              detail: [
                {
                  type: 'text',
                  content: "On November 16th, 2017, the personnel administration department of the company organized all managers of the company’s PACK business department to visit Songshan Lake in autumn. First, they carried out field development training. The employees worked hard to strengthen the team cohesion. At noon, everyone went to the field. Barbecue and culinary competition, the company according to each table contestant's culinary evaluation of the first two third prizes, and respectively give encouragement. Free activities in the afternoon. Everyone rides a bicycle and travels around Songshan Lake to release themselves."
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img21.jpg'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img22.jpg'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img23.jpg'
                },
              ],
            },
            {
              name: '7 things to note when charging your mobile phone in summer',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg',
              id: '103',
              desc: 'While charging the phone while playing with the phone, it will not only damage the battery and sho..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'tt',
                  content: "01. Don't play with your phone while charging"
                },
                {
                  type: 'p',
                  content: "While charging the phone while playing with the phone, it will not only damage the battery and shorten the battery life, but also cause the phone to heat up a lot. For example, we all need to digest food before nutrition can be absorbed. The mobile phone is the same."
                },
                {
                  type: 'tt',
                  content: "02. When charging, clean up the background"
                },
                {
                  type: 'p',
                  content: 'The principle is the same, charging in summer, if there is no special emergency, turn on the "super power saving" mode to charge, the charging is fast and does not heat up.'
                },
                {
                  type: 'tt',
                  content: '03. When charging, put the phone on the ground'
                },
                {
                  type: 'p',
                  content: 'A particularly useful way to cool the phone is to put the phone on the floor (tiles are best). The floor is a natural heat sink, which can help the phone dissipate heat and speed up the charging of the phone.'
                },
                {
                  type: 'tt',
                  content: '04. Remove the protective case of the phone when charging'
                },
                {
                  type: 'p',
                  content: 'Mobile phone cases are generally made of materials such as plastic and rubber, which are extremely unfavorable for heat dissipation. So remove it, so that the phone can be charged freely.'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg'
                },
                {
                  type: 'tt',
                  content: '05. When charging, do not use power banks outdoors'
                },
                {
                  type: 'p',
                  content: "Power Bank is a tool that does not charge very quickly and is easy to heat up. The scorching sun is so hot that we can't stand the heat, and the mobile phone will be too hot. Then use the power bank to charge the mobile phone, which is to eat hot pot in the sun dam."
                },
                {
                  type: 'tt',
                  content: '06. When charging, don’t charge it overnight'
                },
                {
                  type: 'p',
                  content: 'Experiments have proved that charging time is too long to damage the battery, and the night is long, who knows what the current does to the battery at night, so that the battery cannot be stored and discharged normally. The small battery is damaged by overcharging. A few tears ran!'
                },
                {
                  type: 'tt',
                  content: '07. When charging, there is a small fan, just blow it at the phone'
                },
                {
                  type: 'p',
                  content: 'In summer, pay special attention to the heat dissipation of the mobile phone. Finding a small fan for the mobile phone is like giving a computer a heat sink. Xiao V is a person who is reluctant to turn on the super power-saving mobile phone, and the game is still flying. So in the summer, Xiao V specially equipped the mobile phone with one, oh, not two small fans. (Note: Do not link the small fan to the phone, find a power supply separately.)'
                },
              ],
            },
            {
              name: 'Shengli Group held the first basketball game',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg',
              id: '104',
              desc: 'In order to further enrich the amateur cultural life of the majority of employees, create a united..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: 'In order to further enrich the amateur cultural life of the majority of employees, create a united, hard-working, enterprising and harmonious team atmosphere, strengthen physique, invigorate the spirit, gather strength, and enhance the corporate cohesion and sense of pride among employees. Shengli Group held the first men’s basketball game. Various departments, subsidiaries and business divisions responded positively and organized teams to participate in the game. Cheerleaders outside the court were even more enthusiastic. Cheers and shouts made the atmosphere of the basketball game continue to heat up. . All athletes, referees, staff and on-site spectators performed outstandingly. The staff actively did a good job in logistics support, the referees were fair and unselfish, and all the athletes truly displayed the spirit of friendship first and second in the game, the style of the game and the level of the game.'
                },
                {
                  type: 'text',
                  content: 'Tonight’s game was started by the "Dragon Team" of the Power Division and the "One Team" of Shenglitong Company in a happy atmosphere. Although everyone is close colleagues off the court, they are on the court. It’s you who rushed to catch me. Don’t miss any chance of scoring. Encouraged by everyone’s expectations and the shouts of friendship, after a hard fight, the “Dragon Team” of the Power Division finally won the first place in this basketball game. Victory.'
                },
                {
                  type: 'text',
                  content: 'This basketball game not only enriched the amateur cultural and sports life of the employees, but also ignited the enthusiasm and confidence of the group colleagues to participate in sports. Let every employee who loves sports reignite the passion of sports. It embodies the corporate spirit that our company has always advocated focusing on cultivating the comprehensive quality of employees, and at the same time strengthens the in-depth implementation of corporate culture, enhances the friendship between colleagues, and cultivates the spirit of unity and cooperation. The game achieved the expected results, and at the same time, with the unique charm of basketball, it also showed us the vigorous and youthful atmosphere of contemporary youth.'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg'
                },
              ],
            },
          ],
        ],
      },
      newsTwo: {
        total: 1,
        list: [
          [
            {
              name: 'Lithium battery has made a breakthrough, mobile phone battery life can be saved',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg',
              id: '201',
              desc: `Compared with the rapid development of screens, processor performance technology, and flash memory chips, lithium battery life is still the "Achilles' heel" of electronic devices.`,
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: `Compared with the rapid development of screens, processor performance technology, and flash memory chips, lithium battery life is still the "Achilles' heel" of electronic devices.`
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img202.jpg'
                },
                {
                  type: 'text',
                  content: 'In terms of components, a lithium battery is composed of a positive electrode, a negative electrode, a separator, an electrolyte, etc. Its key performance indicators (such as rate performance and cycle life) are determined by the electrochemical performance of the positive electrode material, of which LiFePO4 is a recognized positive electrode material.'
                },
                {
                  type: 'text',
                  content: 'According to a report from the Institute of Metals of the Chinese Academy of Sciences, the research group of Wang Xiaohui of the High Performance Ceramic Materials Research Department of the Shenyang National (Joint) Laboratory of Materials Science has prepared a 12nm thickness for the first time in the world by creating an acidic synthesis environment with extreme water shortage on the basis of previous research. [100] oriented LiFePO4 ultra-thin nanosheets.'
                },
                {
                  type: 'text',
                  content: 'The significance of the nanoplate electrode is that it is the smallest voltage gap so far, which increases the proportion of activated particles.'
                },
                {
                  type: 'text',
                  content: 'And we know that the electrode is composed of a large number of particles, and its electrochemical performance mainly depends on the proportion of the particles (activated particles) that participate in the electrochemical reaction at the same time during the charge and discharge process to the total number of particles.'
                },
                {
                  type: 'text',
                  content: 'According to the Chinese Academy of Sciences, the electrode has excellent rate performance and cycle life. At a charge and discharge rate of 10C (60 minutes/10=6 minutes), it can maintain 90% of the initial capacity after 1,000 cycles. At 20C charge and discharge rate, the capacity can still reach 72% of the theoretical capacity.'
                },
                {
                  type: 'text',
                  content: 'It is generally believed that the research provides a new perspective and method for improving the capacity and life of lithium batteries, thereby improving the battery life of electronic devices.'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg'
                },
              ],
            },
          ],
        ],
      },
      newsThree: {
        total: 0,
        list: [
          []
        ],
      },
      news1: {
        total: 4,
        list: [
          [
            // {
            //   url: '/news?type=1',
            //   name: 'Ranking and Introduction of Foreign Lithium Battery Brands',
            //   img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img1.jpg',
            //   id: '101',
            //   desc: 'began in 1918 and is a comprehensive electronic technology enterprise group specialized in the pr..',
            //   time: '2021-11-03',
            //   detail: [
            //     {
            //       type: 'tt',
            //       content: 'Panasonic Matsushita (Panasonic Electric (China) Co., Ltd.)'
            //     },
            //     {
            //       type: 'p',
            //       content: "began in 1918 and is a comprehensive electronic technology enterprise group specialized in the production and sales of various electrical products, covering many fields such as household appliances, digital audio-visual electronics, office products, aviation, etc., under the subsidiary of Japan's Matsushita Electric Industrial Co., Ltd."
            //     },
            //     {
            //       type: 'tt',
            //       content: 'LG Chem'
            //     },
            //     {
            //       type: 'p',
            //       content: 'Established in 1947, it belongs to the baiLG Group, one of the three largest in South Korea, and is currently the largest comprehensive chemical company in South Korea that leads the South Korean chemical industry.'
            //     },
            //     {
            //       type: 'p',
            //       content: 'Main business: power lithium battery, basic materials, information and electronic materials, biochemistry.'
            //     },
            //     {
            //       type: 'p',
            //       content: 'Company advantage: South Korea’s first mass production of lithium-ion batteries, has the world’s leading technical force, and leads the market in the fields of car batteries, ESS batteries, and mobile device batteries.'
            //     },
            //     {
            //       type: 'tt',
            //       content: 'Samsung SDI'
            //     },
            //     {
            //       type: 'p',
            //       content: 'has 12,000 employees and its R&D centers are located in Korea and Japan. Russia and other countries also have offices in Taiwan, Germany, Malaysia, India, Vietnam and other places, and are among the top manufacturers of lithium battery manufacturers in the world.'
            //     },
            //     {
            //       type: 'p',
            //       content: 'main business: small lithium-ion batteries, ESS, car batteries, electronic materials.'
            //     },
            //     {
            //       type: 'p',
            //       content: 'Company advantage: Established lithium-ion battery cooperative relationship with many manufacturers in the world, such as Volkswagen Group, BMW and so on.'
            //     },
            //     {
            //       type: 'tt',
            //       content: 'Sony SONY'
            //     },
            //     {
            //       type: 'p',
            //       content: "Japan is a world-renowned large-scale comprehensive multinational enterprise group, founded in May 1946, one of the world's largest electronic product manufacturing companies. In July 2016, Murata Manufacturing Co., Ltd. acquired Sony's battery business unit."
            //     },
            //     {
            //       type: 'p',
            //       content: 'main business: video game consoles, digital accessories, electronic components, professional systems.'
            //     },
            //     {
            //       type: 'p',
            //       content: 'Company advantage: Sony is the originator of lithium batteries. The company released the first commercial lithium battery in 1991. Lithium batteries began to become a source of power for electronic products.'
            //     },
            //     {
            //       type: 'tt',
            //       content: 'SK INNOVATION'
            //     },
            //     {
            //       type: 'p',
            //       content: 'was established in 1962. Its predecessor, Korea Petroleum Corporation, was South Korea’s first oil refining company. At present, SKI is South Korea’s largest petrochemical group. The company has a wide range of fields such as energy, chemistry, and new materials.'
            //     },
            //     {
            //       type: 'p',
            //       content: 'Main business: oil development, battery'
            //     },
            //     {
            //       type: 'p',
            //       content: ' Company advantages: Possess the core technology of power lithium battery separators, the third manufacturer in the world to develop lithium battery separators, and also owns the patented technology of low shrinkage and heat resistance for separator manufacturing. It has been used in electric vehicles since 2008; Mitsubishi Battery suppliers for Fuso, Mercedes-Benz, Hyundai, and Kia.'
            //     },
            //     {
            //       type: 'tt',
            //       content: 'AESC'
            //     },
            //     {
            //       type: 'p',
            //       content: 'was established in 1994 as a joint venture of Japan Automobile Co., Ltd. (Japan), NEC Corporation (NEC), and NEC Tokin Co., Ltd. (NEC Tokin).'
            //     },
            //     {
            //       type: 'p',
            //       content: 'Company advantage: Compared with the current Ni-MH battery, the lithium battery developed by AESC can provide nearly twice the power capacity, and the unique laminated structure also makes the volume of the lithium battery module lighter and more practical.'
            //     },
            //     {
            //       type: 'tt',
            //       content: 'A123 Systems Corporation'
            //     },
            //     {
            //       type: 'p',
            //       content: 'An American company specializing in the development and production of lithium-ion batteries and energy storage systems was established in 2001 and now has more than 2,000 employees worldwide. It was acquired by Wanxiang Group in 2013.'
            //     },
            //     {
            //       type: 'p',
            //       content: 'Main business: batteries, modules, systems'
            //     },
            //     {
            //       type: 'p',
            //       content: 'Company advantage: Lithium-ion batteries lead the lithium-ion battery market with high power, high energy density, long life, and excellent safety performance.'
            //     },
            //   ],
            // },
            {
              url: '/newsDetail?type=1',
              name: 'Winter Outing',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/newsPic5.png',
              id: '17',
              desc: "In order to improve employees' awareness of physical exercise, enrich their spare time life, and appropriately relieve their work pressure; Promote communication among employees and create a pleasant and harmonious partnership atmosphere, with the strong support of the company's leaders, the company organized a team to climb the first peak in Dongguan - Yinping Mountain on December 19.",
              time: '2020-12-28',
            },
            // {
            //   url: '/news?type=1',
            //   name: 'The company organizes an autumn tour of Songshan Lake',
            //   img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img2.jpg',
            //   id: '13',
            //   desc: 'On November 16th, 2017, the personnel administration department of the company organized all manag..',
            //   time: '2021-11-03',
            //   detail: [
            //     {
            //       type: 'text',
            //       content: "On November 16th, 2017, the personnel administration department of the company organized all managers of the company’s PACK business department to visit Songshan Lake in autumn. First, they carried out field development training. The employees worked hard to strengthen the team cohesion. At noon, everyone went to the field. Barbecue and culinary competition, the company according to each table contestant's culinary evaluation of the first two third prizes, and respectively give encouragement. Free activities in the afternoon. Everyone rides a bicycle and travels around Songshan Lake to release themselves."
            //     },
            //     {
            //       type: 'img',
            //       content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img21.jpg'
            //     },
            //     {
            //       type: 'img',
            //       content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img22.jpg'
            //     },
            //     {
            //       type: 'img',
            //       content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img23.jpg'
            //     },
            //   ],
            // },
            // {
            //   url: '/news?type=1',
            //   name: '7 things to note when charging your mobile phone in summer',
            //   img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg',
            //   id: '103',
            //   desc: 'While charging the phone while playing with the phone, it will not only damage the battery and sho..',
            //   time: '2021-02-23',
            //   detail: [
            //     {
            //       type: 'tt',
            //       content: "01. Don't play with your phone while charging"
            //     },
            //     {
            //       type: 'p',
            //       content: "While charging the phone while playing with the phone, it will not only damage the battery and shorten the battery life, but also cause the phone to heat up a lot. For example, we all need to digest food before nutrition can be absorbed. The mobile phone is the same."
            //     },
            //     {
            //       type: 'tt',
            //       content: "02. When charging, clean up the background"
            //     },
            //     {
            //       type: 'p',
            //       content: 'The principle is the same, charging in summer, if there is no special emergency, turn on the "super power saving" mode to charge, the charging is fast and does not heat up.'
            //     },
            //     {
            //       type: 'tt',
            //       content: '03. When charging, put the phone on the ground'
            //     },
            //     {
            //       type: 'p',
            //       content: 'A particularly useful way to cool the phone is to put the phone on the floor (tiles are best). The floor is a natural heat sink, which can help the phone dissipate heat and speed up the charging of the phone.'
            //     },
            //     {
            //       type: 'tt',
            //       content: '04. Remove the protective case of the phone when charging'
            //     },
            //     {
            //       type: 'p',
            //       content: 'Mobile phone cases are generally made of materials such as plastic and rubber, which are extremely unfavorable for heat dissipation. So remove it, so that the phone can be charged freely.'
            //     },
            //     {
            //       type: 'img',
            //       content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img3.jpg'
            //     },
            //     {
            //       type: 'tt',
            //       content: '05. When charging, do not use power banks outdoors'
            //     },
            //     {
            //       type: 'p',
            //       content: "Power Bank is a tool that does not charge very quickly and is easy to heat up. The scorching sun is so hot that we can't stand the heat, and the mobile phone will be too hot. Then use the power bank to charge the mobile phone, which is to eat hot pot in the sun dam."
            //     },
            //     {
            //       type: 'tt',
            //       content: '06. When charging, don’t charge it overnight'
            //     },
            //     {
            //       type: 'p',
            //       content: 'Experiments have proved that charging time is too long to damage the battery, and the night is long, who knows what the current does to the battery at night, so that the battery cannot be stored and discharged normally. The small battery is damaged by overcharging. A few tears ran!'
            //     },
            //     {
            //       type: 'tt',
            //       content: '07. When charging, there is a small fan, just blow it at the phone'
            //     },
            //     {
            //       type: 'p',
            //       content: 'In summer, pay special attention to the heat dissipation of the mobile phone. Finding a small fan for the mobile phone is like giving a computer a heat sink. Xiao V is a person who is reluctant to turn on the super power-saving mobile phone, and the game is still flying. So in the summer, Xiao V specially equipped the mobile phone with one, oh, not two small fans. (Note: Do not link the small fan to the phone, find a power supply separately.)'
            //     },
            //   ],
            // },
            {
              url: '/news?type=1',
              name: 'Shengli Group held the first basketball game',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg',
              id: '14',
              desc: 'In order to further enrich the amateur cultural life of the majority of employees, create a united..',
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: 'In order to further enrich the amateur cultural life of the majority of employees, create a united, hard-working, enterprising and harmonious team atmosphere, strengthen physique, invigorate the spirit, gather strength, and enhance the corporate cohesion and sense of pride among employees. Shengli Group held the first men’s basketball game. Various departments, subsidiaries and business divisions responded positively and organized teams to participate in the game. Cheerleaders outside the court were even more enthusiastic. Cheers and shouts made the atmosphere of the basketball game continue to heat up. . All athletes, referees, staff and on-site spectators performed outstandingly. The staff actively did a good job in logistics support, the referees were fair and unselfish, and all the athletes truly displayed the spirit of friendship first and second in the game, the style of the game and the level of the game.'
                },
                {
                  type: 'text',
                  content: 'Tonight’s game was started by the "Dragon Team" of the Power Division and the "One Team" of Shenglitong Company in a happy atmosphere. Although everyone is close colleagues off the court, they are on the court. It’s you who rushed to catch me. Don’t miss any chance of scoring. Encouraged by everyone’s expectations and the shouts of friendship, after a hard fight, the “Dragon Team” of the Power Division finally won the first place in this basketball game. Victory.'
                },
                {
                  type: 'text',
                  content: 'This basketball game not only enriched the amateur cultural and sports life of the employees, but also ignited the enthusiasm and confidence of the group colleagues to participate in sports. Let every employee who loves sports reignite the passion of sports. It embodies the corporate spirit that our company has always advocated focusing on cultivating the comprehensive quality of employees, and at the same time strengthens the in-depth implementation of corporate culture, enhances the friendship between colleagues, and cultivates the spirit of unity and cooperation. The game achieved the expected results, and at the same time, with the unique charm of basketball, it also showed us the vigorous and youthful atmosphere of contemporary youth.'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img4.jpg'
                },
              ],
            },
          ],
        ],
      },
      news2: {
        total: 1,
        list: [
          [
            {
              url: '/news?type=2',
              name: 'Lithium battery has made a breakthrough, mobile phone battery life can be saved',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg',
              id: '201',
              desc: `Compared with the rapid development of screens, processor performance technology, and flash memory chips, lithium battery life is still the "Achilles' heel" of electronic devices.`,
              time: '2021-02-23',
              detail: [
                {
                  type: 'text',
                  content: `Compared with the rapid development of screens, processor performance technology, and flash memory chips, lithium battery life is still the "Achilles' heel" of electronic devices.`
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img202.jpg'
                },
                {
                  type: 'text',
                  content: 'In terms of components, a lithium battery is composed of a positive electrode, a negative electrode, a separator, an electrolyte, etc. Its key performance indicators (such as rate performance and cycle life) are determined by the electrochemical performance of the positive electrode material, of which LiFePO4 is a recognized positive electrode material.'
                },
                {
                  type: 'text',
                  content: 'According to a report from the Institute of Metals of the Chinese Academy of Sciences, the research group of Wang Xiaohui of the High Performance Ceramic Materials Research Department of the Shenyang National (Joint) Laboratory of Materials Science has prepared a 12nm thickness for the first time in the world by creating an acidic synthesis environment with extreme water shortage on the basis of previous research. [100] oriented LiFePO4 ultra-thin nanosheets.'
                },
                {
                  type: 'text',
                  content: 'The significance of the nanoplate electrode is that it is the smallest voltage gap so far, which increases the proportion of activated particles.'
                },
                {
                  type: 'text',
                  content: 'And we know that the electrode is composed of a large number of particles, and its electrochemical performance mainly depends on the proportion of the particles (activated particles) that participate in the electrochemical reaction at the same time during the charge and discharge process to the total number of particles.'
                },
                {
                  type: 'text',
                  content: 'According to the Chinese Academy of Sciences, the electrode has excellent rate performance and cycle life. At a charge and discharge rate of 10C (60 minutes/10=6 minutes), it can maintain 90% of the initial capacity after 1,000 cycles. At 20C charge and discharge rate, the capacity can still reach 72% of the theoretical capacity.'
                },
                {
                  type: 'text',
                  content: 'It is generally believed that the research provides a new perspective and method for improving the capacity and life of lithium batteries, thereby improving the battery life of electronic devices.'
                },
                {
                  type: 'img',
                  content: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/news_img201.jpg'
                },
              ],
            },
          ],
        ],
      },
      news3: {
        total: 0,
        list: [
          []
        ],
      },
      detail:'View details',
      nomore:'No data'
    },
  }
  export default info