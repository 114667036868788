<template>
  <div class="overHidden">
    <top-pic :name="name" :text="$t('common.base.nav_aboutus_course')" :subText="$t('common.base.nav_products_sub')"></top-pic>
    <div class="contentBox phoneClass"  v-if="$i18n.locale == 'zh'">
      <img class="content-img" src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/develop_bg.png" alt="" >
    </div>
    <div class="company-msg wow fadeInUp"  v-else>
      <div class="company-lc">
        <div>2008：Shenglilong</div>
        <p>Shenglilong Technology was established, focusing on R&D, production and sales of lithium batteries</p>
        <div>2010：Shengli Energy</div>
        <p>Shengli Energy was established, focusing on battery cell R&D, production and sales</p>
        <div>2014：Feitu Electronics</div>
        <p>Feitu Electronics was established, focusing on R&D, production and sales of lithium battery protective film</p>
        <div>2015：Shengli Lithium Energy</div>
        <p>Shengli Lithium Energy was established, focusing on the development and research of new lithium battery technologies</p>
        <div>2016：Sheng Liqiang</div>
        <p>Shengli Lithium Energy was established, focusing on new energy solutions and overseas markets</p>
      </div>
    </div>
  </div>
</template>
  
<script>
import TopPic from '@/components/TopPic.vue'
export default {
  name: "IndexPage",
  components: {
    TopPic
  },
  data() {
    return {
      name: '/course'
    }
  },
  methods: {
    goClass() {

    },
  }
}
</script>
  
<style scoped lang="scss">
  .content-img {
    width: 100%;
    object-fit: cover;
    margin-top: 78px;
    display: block;
    margin-bottom: 82px;
  }
  .company-msg {
    width: 15rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 449px;
      object-fit: contain;
    }
  }
  .company-lc {
    margin: .4rem 0;
    div{
      font-size: .16rem;
      line-height: .32rem;
      margin-top: .4rem;
    }
    p {
      font-size: .16rem;
      text-indent: 2em;
      line-height: .3rem;
    }
  }
// 兼容手机
@media screen and (max-width: 756px) {
  .phoneClass {
    overflow-x: auto;
    .content-img {
      width: auto;
    }
  }
  .company-msg {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 3.2rem;
      object-fit: contain;
      margin: .5rem;
    }
  }
  .company-lc {
    margin: .4rem 0;
    padding: 0 .3rem;
    div{
      font-size: .16rem;
      line-height: .32rem;
      margin-top: .4rem;
    }
    p {
      font-size: .16rem;
      text-indent: 2em;
      line-height: .3rem;
    }
  }
}
</style>