<template>
  <div>
    <div class="jt-nav-bg"
    :style="{'background': navOpen ? '#fff' : ''}"
    >
      <!-- 我是顶部栏phone端 -->
      <div class="jt-nav">
        <div class="jt-nav-l">
          <img v-if="navOpen" src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/navlogo.png" alt=""
            @click="$router.push('/')" />  
            <img v-else src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/icon_logowhich.png" alt=""
            @click="$router.push('/')" />
        </div>
        <div class="jt-nav-m">
          <span>1</span>
          <img v-if="navOpen" src="@/assets/icon/icon_xiala.png" alt=""
            @click="openNav(2)" />
          <img v-else src="@/assets/icon/img_xuanxiang.png" alt=""
            @click="openNav(1)" />
        </div>
        
      </div>
      <div class="nav-son" :class="navOpen ? 'nav-son-open' : ''">
        <div class="indexBox" 
        :class="$route.path == '/' ? 'routeActive' : ''"
        @click="toIndex">首页</div>
        <el-collapse v-model="activeNames" accordion>
          <el-collapse-item v-for="(item, ind) in navList" 
          
          :key="ind" :title="item.name" :name="item.id">
                <template slot="title">
                  <div :class="item.childList.includes($route.path) ? 'routeActive' : ''">
                    {{item.name}}
                  </div>
                  
                </template>
                <div v-for="(items, index) in item.children" 
                :key="index" 
                class="jt-nav-son-item" @click="goPages(items)">
                  <div 
                  class="jt-nav-son-item-t">{{ items.name }}</div>
                </div>
          </el-collapse-item>
        </el-collapse>
        <div class="jt-nav-r">
          <div @click="changeText('zh')" :class="{ active: lang === 'zh' }">中文</div>
          <span></span>
          <div @click="changeText('en')" :class="{ active: lang === 'en' }">EN</div>
        </div>
      </div>
    </div>
    <div class="navzhan"></div>
  </div>
</template>

<script>
import { categoryList } from "@/utils/api";
export default {
  props: {
    category: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      activeNames: [],
      navOpen: false,
      categoryLists: [],
      lang: localStorage.getItem('languageSet') || 'zh'
    };
  },
  computed: {
    navList() {
      return [
        {
          name: this.$t('common.base.nav_products'),
          url: '/product',
          childList: ['/product',],
          children: this.category
        },
        {
          // name: '关于盛利',
          name: this.$t('common.base.nav_aboutus'),
          url: '/introduction',
          childList: ['/introduction','/course','/certificate','/culture',],
          children: [
            {
              name: this.$t('common.base.nav_aboutus_intoduction'),
              url: '/introduction',
            },
            {
              name: this.$t('common.base.nav_aboutus_course'),
              url: '/course',
            },
            {
              name: this.$t('common.base.nav_aboutus_certificate'),
              url: '/certificate',
            },
            {
              name: this.$t('common.base.nav_aboutus_culture'),
              url: '/culture',
            }
          ]
        },
        {
          name: this.$t('common.base.nav_news'),
          url: '/news?type=0',
          childList: ['/news'],
          children: [
            {
              name: this.$t('common.base.nav_news_companynews'),
              url: '/news?type=1',
            },
            {
              name: this.$t('common.base.nav_news_technical'),
              url: '/news?type=2',
            },
            {
              name: this.$t('common.base.nav_news_problem'),
              url: '/news?type=3',
            }
          ]
        },
        {
          name: this.$t('common.base.nav_resources'),
          url: '/source',
          childList: ['/source','/social','/campus','/man'],
          children: [
            {
              name: this.$t('common.base.nav_resources_talent'),
              url: '/source',
            },
            {
              name: this.$t('common.base.nav_resources_social'),
              url: '/social',
            },
            {
              name: this.$t('common.base.nav_resources_campus'),
              url: '/campus',
            },
            {
              name: this.$t('common.base.nav_resources_staff'),
              url: '/man',
            }
          ]
        },
        {
          name: this.$t('common.base.nav_contact'),
          url: '/contact',
          childList: ['/contact','/message'],
          children: [
            {
              name: this.$t('common.base.nav_contact_contact'),
              url: '/contact',
            },
            {
              name: this.$t('common.base.nav_contact_message'),
              url: '/message',
            },
          ]
        },
      ]
    }
  },
  watch: {
    '$route.path': {
      handler: function() {
        this.navOpen = false
      },
      immediate: true
    }
  },
  mounted() {
    // this.getCategoryList()
  },
  methods: {
    // 语言切换
    changeText(text) {
      this.lang = text
      this.$i18n.locale = text
      localStorage.setItem('languageSet', this.$i18n.locale)
      this.getCategoryList()
    },
    openNav(num) {
      this.activeNames = []
      if (num === 1) {
        this.navOpen = true
      } else {
        this.navOpen = false
      }
    },
    toIndex() {
      this.$router.push('/')
    },
    goPages(item) {
      this.activeNames = [];
      this.navOpen = false;
      this.$router.push({
        path: item.url,
        query: {
          id: item.id
        }
      })
    },
    getCategoryList() {
      this.categoryLists = []
      categoryList({ language: this.$i18n.locale }).then(res => {
        if (res.code === "000000") {
          res.data.forEach(item => {
            item.child.forEach(it => {
              this.categoryLists.push({
                name: it.productTypeName,
                id: it.id,
                sort: it.sort
              })
            })
          });
          this.categoryLists.forEach((item, index) => {
            item.url = `/product?type=${index + 1}`
          })
          this.categoryLists.sort((a, b) => a.sort - b.sort)
          // console.log(this.categoryLists, 'categoryLists');
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-collapse-item__header {
  border-bottom: 0px !important;
  height: 1rem;
  color: #717171;
  font-size: .32rem;
  font-weight: 400;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0px !important;
  
}
::v-deep .el-collapse {
  border-top: 0px !important;
}
.routeActive {
  color: #1FCE76 !important;
}
.jt-nav-bg {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  // background: #FFFFFF;
  // box-shadow: 0 0 4px #949594;
  // border-bottom: 1px solid #949594;
  z-index: 1000;
  background: #0F1E19;
  

  .jt-nav {
    display: flex;
    align-items: center;
    height: 1.3rem;
    font-size: 0.18rem;
    width: 100vw;
    // padding: 0 30rem;
    margin: auto;

    .jt-nav-l {
      margin-left: 0.3rem;

      img {
        width: 2.3rem;
        height: 0.68rem;
        display: block;
      }

    }

    .jt-nav-m {
      flex: 1;
      display: flex;
      align-items: center;
      margin-right: 0.3rem;

      span {
        flex: 1;
        opacity: 0;
      }

      img {
        width: 0.48rem;
        height: 0.48rem;
        display: block;
      }
    }

    
  }
  .jt-nav-r {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.2rem;
      margin-right: 0.3rem;
      font-size: .32rem;
      color: #BABABA;

      div {
        line-height: 1rem;
        padding: 0.06rem;
        cursor: pointer;
      }

      span {
        width: 1px;
        height: 14px;
        background: #8d8c8c;
        margin: 0 .34rem;
      }

      .active {
        color: #1FCE76;
      }
    }

  .nav-son {
    position: fixed;
    left: 0;
    width: 100vw;
    top: 1.3rem;
    height: 0rem;
    overflow: hidden;
    display: block;
    background: #FFFFFF;

    .indexBox {
      height: 1rem;
      line-height: 1rem;
      color: #717171;
      font-size: .32rem;
      font-weight: 400;
      text-align: left;
      padding-left: .2rem;
    }

    .jt-nav-son-item {
      display: flex;
      flex-direction: column;
      background: #f8f8f8;

      .jt-nav-son-item-t {
        margin-top: 0.1rem;
        text-align: left;
        line-height: 1rem;
        padding-left: 0.35rem;
        color: #717171;
        font-size: .32rem;
        font-weight: 400;
      }
    }
  }

  .nav-son-open {
    height: auto;
  }

  ::v-deep .el-collapse-item__header {
    padding-left: 0.2rem;
  }

  ::v-deep .el-collapse-item__header.is-active {
    border-bottom-color: #EBEEF5;
  }
}

.navzhan {
  height: 1.3rem;
  width: 100%;
}

.swier-tem {
  height: 8rem;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    height: 2.8rem;
    display: block;
  }

  div {
    flex: 1;
  }
}
</style>