<template>
  <div style="background: #fff">
    <top-pic v-if="!showAll" :name="name" :text="'产品'" @clickOne="sonClick" ref="topPic" @refresh="&quot;refresh&quot;;"></top-pic>
    <div class="product-msg contentBox">
      <!-- <div class="product-l">
        <h3 @click="goPages('/product?type=0')">产品解决方案</h3>
        <div class="product-l-item" v-for="(item, ind) in pronavList" :key="ind" :class="current === ind? 'navact' : ''" @click="itemClick(item, ind)">
          {{ item.productTypeName }}
        </div>
      </div> -->
      <div class="product-r">
        <div class="product-r-item wow fadeInUp" v-for="item in curProList" :key="item.id" @click="goDetail(item.id)">
          <div style="position: relative">
            <img :src="item.cover" alt="" />
            <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/icon/product_standard.png" class="imgs" />
          </div>
          <p>{{ item.name }}</p>
          <span></span>
        </div>
        
      </div>
      <div class="noMoreData" v-show="$store.state.deviceType === '2' && showAll">
        {{curProList.length == total ? '没有更多了...' : '下拉加载更多！'}}
      </div>
    </div>
    <div class="pagebox wow fadeInUp" v-if="$store.state.deviceType === '1' && total">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page.page" :page-sizes="[5, 10]" :page-size="page.per_page" layout="total, sizes, prev, pager, next, jumper" :total="total"> </el-pagination>
    </div>
    <div class="pagebox searchMore" 
    @click="showAllData"
    v-if="$store.state.deviceType === '2' && !showAll">
      <div>探索更多</div>
      <!-- <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page.page" :page-size="page.per_page" layout="total, prev, pager, next" :total="total"> </el-pagination> -->
    </div>
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
import { productGetPage, categoryList } from "@/utils/api";
export default {
  name: "IndexPage",
  components: {
    TopPic,
  },
  data() {
    return {
      name: "product?type=0",
      curRoute: "",
      //page: 1,
      total: 0,
      page: {
        page: 1,
        per_page: this.$store.state.deviceType === '1' ? 5 : 6,
      },
      curProList: [],
      pronavList: [],
      typeId: "",
      current: null,
      showAll: false,
    };
  },
  computed: {
    // 页面导航
    // pronavList() {
    //   return [
    //     {
    //       name: this.$t('common.base.nav_products_cell'),
    //       url: '/product?type=1',
    //     },
    //     {
    //       name: this.$t('common.base.nav_products_storage'),
    //       url: '/product?type=2',
    //     },
    //     {
    //       name: this.$t('common.base.nav_products_vehicle'),
    //       url: '/product?type=3',
    //     },
    //     {
    //       name: this.$t('common.base.nav_products_custom'),
    //       url: '/product?type=4',
    //     }
    //   ]
    // },
    // 显示的产品
    // curProList() {
    //   let str = this.$route.fullPath
    //   let msg = null
    //   let list = []
    //   if (str == '/product?type=0') {
    //     msg = this.$t('pages.product.productAll')
    //   } else if (str == '/product?type=1') {
    //     msg = this.$t('pages.product.productOne')
    //   } else if (str == '/product?type=2') {
    //     msg = this.$t('pages.product.productTwo')
    //   } else if (str == '/product?type=3') {
    //     msg = this.$t('pages.product.productThree')
    //   } else if (str == '/product?type=4') {
    //     msg = this.$t('pages.product.productFour')
    //   }
    //   this.total = msg.total
    //   list = msg.list[this.page - 1]
    //   return list
    // }
  },
  watch: {
    $route: {
      handler:function(route) {
        if (route.fullPath.indexOf("product") > -1) {
          console.log("-----首页----------", route);
          this.typeId = route.query.id;
          const target = this.pronavList.findIndex((item) => item.id == this.typeId);
          this.current = target;
          this.curProList = []
          this.showAll = false
          this.total = 0
          this.page.page = 1;
          this.page.per_page = this.$store.state.deviceType === '1' ? 5 : 6;
          this.getproduct();
          this.setMsg(route.fullPath);
          window.removeEventListener('scroll', this.handleScroll);
        }
      },
      immediate: true,
      deep: true,
    },
    '$i18n.locale': {
      handler: function(newVal,oldVal) {
        if (newVal !== oldVal && this.$route.path === "/product") {
          if (this.$store.state.deviceType !== '2') {
            this.curProList = []
          }
          this.getproduct();
        }
      }
    }
  },
  created() {
    this.setMsg(this.$route.fullPath);
    // this.getproduct();
    // this.getDate();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    getDate() {
      categoryList({ language: this.$i18n.locale }).then((res) => {
        if (res.code == "000000") {
          res.data.forEach((item) => {
            this.pronavList.push(...item.child);
          });
          const target = this.pronavList.findIndex((item) => item.id == this.$route.query.id);
          this.current = target;
        }
      });
    },
    itemClick(item, i) {
      this.current = i;
      this.typeId = item.id;
      this.getproduct();
      this.$refs.topPic.changeType(i);
    },
    showAllData() {
      this.showAll = true
      this.page.page++
      window.addEventListener('scroll', this.handleScroll);
      this.getproduct()
    },
    handleScroll() {
      if (this.total == this.curProList.length) {
        return
      }
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
      if (scrollHeight - (scrollTop + clientHeight) < 1) {
        this.page.page++
        this.getproduct()
      }
    },
    getproduct() {
      productGetPage({ page: this.page, typeId: this.typeId }).then((res) => {
        if (res.code == "000000") {
          if (this.$store.state.deviceType === '2') {
            this.curProList = this.curProList.concat(res.data.list);
          } else {
            this.curProList = res.data.list
          }
          
          this.total = res.data.total;
        }
      });
    },
    // 设置数据
    setMsg(url) {
      this.name = url;
      this.curRoute = url;
    },
    // 页面跳转
    goPages(url) {
      this.$router.push({
        path: url,
      });

      //this.getproduct();
    },
    // 去详情
    goDetail(id) {
      this.$router.push({
        path: "/productDetail",
        query: {
          name: this.name,
          id,
        },
      });
    },
    handleSizeChange(page) {
      this.page.per_page = page;
      this.getproduct();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.getproduct();
    },
    sonClick() {
      this.page.page = 1;
      this.page.per_page = 6;
      this.getproduct();
    },
  },
};
</script>

<style scoped lang="scss">
.product-msg {
  padding-top: 20px;
  min-height: 2.6rem;
  .product-l {
    background: #ffffff;
    width: 3.3rem;
    height: 3.5rem;
    margin-right: 0.6rem;
    h3 {
      font-size: 0.24rem;
      height: 0.8rem;
      background: #51b059;
      color: #ffffff;
      text-align: center;
      line-height: 0.8rem;
      letter-spacing: 0.03rem;
    }
    .product-l-item {
      padding: 0 0.15rem;
      height: 0.48rem;
      line-height: 0.48rem;
      background: #ffffff;
      font-size: 0.16rem;
      color: #000000;
      margin: 0.08rem;
      cursor: pointer;
    }
    .navact {
      background: #51b059;
      color: #ffffff;
    }
    .product-l-item:hover {
      background: #51b059;
      color: #ffffff;
    }
  }
  .product-r {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .product-r-item {
      width: 270px;
      background: #F9F9F9;
      height: 277px;
      // overflow: hidden;
      margin-bottom: 30px;
      border-radius: 5px;
      // margin-right: 14px;

      img {
        width: 270px;
        height: 237px;
        display: block;
        object-fit: contain;
      }
      .imgs {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 60px;
        height: 61px;
      }
      // &:hover img {
      //   transform: scale(1.2);
      // }
      p {
        font-size: 14px;
        color: #262830;
        height: 40px;
        // line-height: 40px;
        text-align: center;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
      }
    }
  }
}
.pagebox {
  display: flex;
  align-items: center;
  justify-content: center;
}
// 兼容手机
@media screen and (max-width: 756px) {
  .product-msg {
    width: 100%;
    margin: 0 auto 0.4rem;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.15rem;
    justify-content: space-between;
    padding: 0 0.3rem;
    .product-l {
      background: #ffffff;
      width: 100%;
      height: 5.4rem;
      h3 {
        font-size: 0.24rem;
        height: 1.3rem;
        background: #51b059;
        color: #ffffff;
        text-align: center;
        line-height: 1.3rem;
        letter-spacing: 0.03rem;
      }
      .product-l-item {
        padding: 0 0.15rem;
        height: 0.8rem;
        line-height: 0.8rem;
        background: #ffffff;
        font-size: 0.2rem;
        color: #000000;
        margin: 0.08rem;
        cursor: pointer;
      }
      .navact {
        background: #51b059;
        color: #ffffff;
      }
      .product-l-item:hover {
        background: #51b059;
        color: #ffffff;
      }
    }
    .product-r {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 0.4rem;
      .product-r-item {
        width: 46%;
        background: #F9F9F9;
        height: 3.23rem;
        // overflow: hidden;
        margin: 0 0 0.3rem;
        div {
          width: 100%;
          height: 2.8rem;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
          }
        }
        p {
          font-size: 0.22rem;
          color: #262830;
          // line-height: 0.2rem;
          text-align: center;
          margin-top: .16rem;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        span {
          width: 100%;
          background: #777;
          height: 0.03rem;
        }
      }
      
    }
    .noMoreData {
      text-align: center;
      font-size: .24rem;
      margin-top: .2rem;
    }
  }
  .searchMore {
    width: 4.56rem;
    height: .88rem;
    border: 1px solid #1FCE76;
    color: #1FCE76;
    font-size: .32rem;
    margin: .3rem auto .5rem;
  }
}
</style>
