<template>
<!-- <div class="cardBox" :style="{'width': cardWidth + 'rem'}"> -->
  <div
  class="shopCard"
  ref="cardRef"
  :style="{'width': cardWidth + 'rem','height': cardHeight + 'rem'}"
  @mouseover="handleMouseOver"
  @mouseleave="handleMouseLeave"
  @click="toDetail"
  >
    <div class="hideTitle" v-show="hoverNow">
        <div class="hideTitleText">
            {{ dataObj.title }}
        </div>
    </div>
    <div class="coverImg">
        <img :src="dataObj.img" alt="">
    </div>
    <div class="defaultTitle" v-show="!hoverNow">{{ dataObj.title }}</div>
    <div class="detail">{{ dataObj.detail }}</div>
    <div class="takeMore">{{$t('pages.main.search_more')}}</div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  props: {
    cardWidth: Number,
    dataObj: Object
  },
  data() {
    return {
        hoverNow: false,
        cardHeight: this.$i18n.locale == 'en' ? 5 : 4.76
    }
  },
  watch: {
    '$i18n.locale': {
      handler: function(newVal,oldVal) {
        if (newVal == 'en') {
          this.cardHeight = 5
        } else {
          this.cardHeight = 4.76
        }
      }
    }
  },
  methods: {
    handleMouseOver() {
        this.hoverNow = true
        if (this.$i18n.locale == 'en') {
          this.cardHeight = 5.2
        } else {
          this.cardHeight = 4.9
        }
    },
    handleMouseLeave() {
        this.hoverNow = false
        if (this.$i18n.locale == 'en') {
          this.cardHeight = 5
        } else {
          this.cardHeight = 4.76
        }
    },
    toDetail() {
        this.$router.push({
          path: this.dataObj.url,
          query: { id: this.dataObj.id },
        });
    }
  }
}
</script>

<style lang="scss" scoped>
// .cardBox {
//     position: relative;
//     height: 4.76rem;
//     margin: .08rem .06rem;
    .shopCard {
        // height: 4.76rem;
        background: #FAFAFA;
        margin: .08rem .06rem;
        cursor: pointer;
        transition: 0.4s;
        position: relative;
        padding: .16rem .15rem .4rem;
        .hideTitle {
            .hideTitleText {
              // width: max-content;
              // color: #257A64;
              font-weight: 700;
              font-size: .23rem;
              margin: auto;
              text-align: center;
              padding-bottom: .1rem;
              // border-bottom: 1px solid #AFEACC;
            }
        }
        .coverImg {
          width: 70%;
          margin: auto;
          img {
            width: 100%;
          }
        }
        .defaultTitle {
            color: #3A3C43;
            font-size: .22rem;
            font-weight: 700;
            // margin-bottom: .34rem;
            min-height: .6rem;
        }
        .detail {
            font-size: .14rem;
            color: #717171;
            line-height: .21rem;
        }
        .takeMore {
            font-size: .14rem;
            color: #3EC94B;
            margin-top: .25rem;
            position: absolute;
            left: .15rem;
            bottom: .4rem;
        }
    }
    
    .shopCard:hover {
        width: 3.46rem !important;
        height: 4.90rem;
        // background: linear-gradient(135deg, #BAF9F0 0%, #E3FFE3 100%);
        border: 1px solid #1FCE76;
        margin: 0 !important;
        padding: .46rem .2rem .3rem;
        .coverImg {
          width: 80%;
        }
        .detail {
            text-align: center;
            // color: #257A64;
        }
        .takeMore {
            width: 1.36rem;
            height: .35rem;
            line-height: .35rem;
            margin: auto;
            text-align: center;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: .2rem;
            text-decoration: underline;
            // background: linear-gradient(90deg, #E3FFE3 0%, #C1FFC7 100%);
        }
    }

// }

</style>