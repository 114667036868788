<template>
  <div>
    <top-pic :name="name" :text="$t('common.base.nav_resources_staff')" :subText="$t('common.base.nav_products_sub')"></top-pic>
    <div class="contentBox">
  
    <div class="content">
      <p v-for="(item,index) in $t('common.base2.list4')" :key="index">{{item }}</p>
    </div>
    </div>
  </div>

</template>
   
<script>

import TopPic from '@/components/TopPic.vue'
export default {
  name: "IndexPage",
  components: {
    TopPic
  },
  data() {
    return {
      name: '/man'
    }
  },
  methods: {
    goClass() {
    },
  }
}
</script>
   
<style scoped lang="scss">
.content{
  p{
    font-size: 16px;
    margin: 30px 0;
  }
}
// 兼容手机
@media screen and (max-width: 756px) {}
</style>