import store from "../../store";
(function flexible(window, document) {
  var docEl = document.documentElement;
  // var dpr = window.devicePixelRatio || 1;
  var deviceCur = "1"; // 默认是1.PC端 2.移动端
  // 判断设备是不是手机
  function IsPhone() {
    var info = navigator.userAgent;
    //通过正则表达式的test方法判断是否包含“Mobile”字符串
    var isPhone = /mobile/i.test(info);
    //如果包含“Mobile”（是手机设备）则返回true
    return isPhone;
  }
  // 判断设备是不是平板电脑
  function IsIpad() {
    var info = navigator.userAgent;
    //通过正则表达式的test方法判断是否包含“Ipad”字符串
    var isIpad = /ipad/i.test(info);
    //如果包含“ipad”（是平板设备）则返回true
    return isIpad;
  }

  function deviceDone() {
    if (IsPhone()) {
      // 手机(移动端)
      deviceCur = "2";
      if (IsIpad()) {
        // 平板电脑
        deviceCur = "1";
      }
    }
    store.commit("setDeviceType", deviceCur);
  }
  deviceDone();

  function setHtmlBodyFontSize() {
    if (IsPhone()) {
      // 手机(移动端)
      // console.log(!!document.getElementById("yidongMeta"))
      if (document.getElementById("yidongMeta")) {
        // console.log(3)
      } else {
        let meta = document.createElement("meta");
        meta.content ="width=device-width,initial-scale=1,maximum-scale=1,minimum-scale=1,user-scalable=no";
        meta.name = "viewport";
        meta.id = "yidongMeta";
        document.getElementsByTagName("head")[0].appendChild(meta);
      }
      // <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,minimum-scale=1,user-scalable=no" />
      console.log("mobile");
      // 原理(750px设计稿)
      // 100vw        == 750px (这里的750px指的是设计稿的设计宽度,请按实际改变)
      // 13.33333vw   == 100px (这样设置1rem就等于100px,vw会自己帮我们算,不用我们自己计算pxrem之间的转换)
      // html的font-size(即1rem) = (100*100/750)vw,界面会按设计稿等比缩放(窗口的100vw并非固定值)
      // 16px就是0.16rem
      // 下面设置的fontsize这个值是根据设计稿750算出来的,如果设计稿不是,请重新计算

      // 重新计算
      // 设计稿宽度
      const designWidth = 750;
      const REM = `${((100 * 100) / designWidth).toFixed(5)}vw`;
      docEl.style.fontSize = REM;
      document.body.style.fontSize = REM;
      if (IsIpad()) {
        // 平板电脑
        console.log("ipad");
        docEl.style.fontSize = REM;
        document.body.style.fontSize = REM;
        if (document.getElementById("yidongMeta")) {
          let meta1 = document.getElementById("yidongMeta");
          meta1.parentNode.removeChild(meta1);
        }
      }
    } else {
      // PC端
      console.log("pc");
      // 重新计算
      // 设计稿宽度
      const designWidth = 1920;
      const REM = `${((100 * 100) / designWidth).toFixed(5)}vw`;
      console.log(REM)
      docEl.style.fontSize = REM;
      document.body.style.fontSize = REM;
      if (document.getElementById("yidongMeta")) {
        let meta1 = document.getElementById("yidongMeta");
        meta1.parentNode.removeChild(meta1);
      }
    }
  }
  setHtmlBodyFontSize();

  // reset rem unit on page resize
  window.addEventListener("resize", setHtmlBodyFontSize);

  // detect 0.5px supports
  // if (dpr >= 2) {
  //   var fakeBody = document.createElement("body");
  //   var testElement = document.createElement("div");
  //   testElement.style.border = ".5px solid transparent";
  //   fakeBody.appendChild(testElement);
  //   docEl.appendChild(fakeBody);
  //   if (testElement.offsetHeight === 1) {
  //     docEl.classList.add("hairlines");
  //   }
  //   docEl.removeChild(fakeBody);
  // }
})(window, document);
