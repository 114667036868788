// pages模块里面的product部分(页面)数据
const info = {
    ZH: { // 中文
      productAll: {
        total: 74,
        list: [
          [
            {
              name: '18650-3200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10001.jpg',
              id: '10001',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '最大充电电压',
                '放电截止电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-32R',
                '3200mAh',
                '3.7V',
                '4.2V',
                '2.75V',
                '≤25毫欧',
                '65.1mm *18.4mm (直径)',
                '52g±1g'
              ]
            },
            {
              name: '18650-2600mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10002.jpg',
              id: '10002',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-26R',
                '2600mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm x 18.4mm (直径)',
                '48g ± 1g'
              ]
            },
            {
              name: '18650-2500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10003.jpg',
              id: '10003',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-25R',
                '2500mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm x 18.4mm (直径)',
                '46g ± 1g'
              ]
            },
            {
              name: '18650-2200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10004.jpg',
              id: '10004',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-22R',
                '2200mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm *18.4mm (直径)',
                '44g±1g'
              ]
            },
            {
              name: '18650-1500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10005.jpg',
              id: '10005',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-15R',
                '1500mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm x 18.4mm (直径)',
                '38g ± 1g'
              ]
            },
            {
              name: '18650-1800mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10006.jpg',
              id: '10006',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                '18650-1800mAh',
                '1800mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm x 18.4mm (直径)',
                '40g ± 1g'
              ]
            },
          ],
          [
            {
              name: '21700-4800mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10007.jpg',
              id: '10007',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '充电截止电压',
                '放电截止电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC21700-48R',
                '4800mAh',
                '3.7V',
                '4.2V',
                '2.5V',
                '≤24毫欧',
                '70mm *21mm (直径)',
                '69g±1g'
              ]
            },
            {
              name: '26650-3500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10008.jpg',
              id: '10008',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC26650-35R',
                '3500mAh',
                '3.7V',
                '4.2V',
                '65mm *26mm (直径)',
                '82g±1g'
              ]
            },
            {
              name: '26700-4000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10009.jpg',
              id: '10009',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '放电终止电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC26700-40R',
                '4000mAh',
                '3.2V',
                '3.65 ± 0.05v',
                '2.0 ± 0.05v',
                '≤20毫欧',
                '70mm *26mm (直径)',
                '103g±1g'
              ]
            },
            {
              name: '32700-6000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10010.jpg',
              id: '10010',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '充放电流',
                '充放次数',
                '内阻',
                '尺寸',
                '重量',
                '材料体系',
              ],
              rightList: [
                'IRC32700-60R',
                '6000mAh',
                '3.2V',
                '1C充3C放',
                '3000次以上',
                '≤7毫欧',
                '32mm *70mm (直径)',
                '150g±2g',
                '磷酸铁锂',
              ]
            },
            {
              name: '18650-1200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10011.jpg',
              id: '10011',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-12R',
                '1200mAh',
                '3.7V',
                '2.75 ~4.2V',
                '43.2V',
                '≤38毫欧',
                '65.1mm x 18.4mm (直径)',
                '35g ± 1g'
              ]
            },
            {
              name: '18650-2000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10012.jpg',
              id: '10012',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-20R',
                '2000mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm *18.4mm (直径)',
                '42g ± 1g'
              ]
            },
          ],
          [
            {
              name: '300W UPS',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20001.jpg',
              id: '20001',
              leftList: [
                '产品型号',
                '重量',
                '尺寸',
                '输出功率',
                '输入电压',
                '输出电压',
                '直流输出',
                'USB输出',
                '过载',
                '短路',
                '充电时间',
              ],
              rightList: [
                'ES-300',
                '3.2Kg',
                '29.3*12.2*13.0cm',
                '额定300W',
                'AC 110V~220V 50/60HZ',
                '220V 50HZ/110V 60HZ',
                'DC 12V/5A-2',
                '5V/3A-2',
                '过载后，交流输出断开，断开负载，重启恢复',
                '短路后，交流输出断开，负载断开，重启恢复',
                '6~7H',
              ]
            },
            {
              name: '12V25AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20002.jpg',
              id: '20002',
              leftList: [
                '标称电压',
                '标称容量',
                '输出电压',
                '重量',
                '持续工作电流',
                '最大瞬时电流',
              ],
              rightList: [
                '12.8V',
                '25000mAh (25AH)',
                '14.6~10.0V',
                '2.7kg左右',
                '25A',
                '100A（1-2S）',
              ]
            },
            {
              name: '12V铅酸电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20003.jpg',
              id: '20003',
              leftList: [
                '标称电压',
                '标称容量',
                '电池尺寸',
                '电池重量',
                '电池寿命',
                '输入充电电压',
                '输入充电电流',
              ],
              rightList: [
                '11.1V',
                '7Ah/9Ah/12Ah/15Ah/21Ah',
                '64*98*150mm',
                '500g - 1300g',
                '超过 2000 次,DOD 为 80%',
                '12.6v',
                '标准1A,快速5A',
              ]
            },
            {
              name: '12V防水电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20004.jpg',
              id: '20004',
              leftList: [
                '标称电压',
                '标称容量',
                '输出电压',
                '重量',
              ],
              rightList: [
                '11.1V',
                '10400mAh(10.4AH)/20000mAh(20Ah)/30000mAh(30Ah)',
                '12.6~9.6V',
                '780g/1460g/2200g',
              ]
            },
            {
              name: '12V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20005.jpg',
              id: '20005',
              leftList: [
                '标称电压',
                '标称容量',
                '输出电压',
                '电池重量',
                '持续工作电流',
                '最大瞬时电流',
                '尺寸',
                '类型',
              ],
              rightList: [
                '11.1V',
                '30000mAh（30Ah）',
                '12.6~9.6V',
                '2200g',
                '0~10A',
                '10A',
                '230mm*55mm*300mm',
                '锂离子',
              ]
            },
            {
              name: '12V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20006.jpg',
              id: '20006',
              leftList: [
                '标称电压',
                '标称容量',
                '输出电压',
                '电池重量',
                '持续工作电流',
                '最大瞬时电流',
                '尺寸',
                '类型',
              ],
              rightList: [
                '11.1V',
                '20000mAh（20AH）',
                '12.6~9.6V',
                '1460g',
                '15~20A',
                '30A',
                '120mm*75mm*200mm',
                '锂离子',
              ]
            },
          ],
          [
            {
              name: '12V10AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20007.jpg',
              id: '20007',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '输入电压',
                '输出电压',
                '电池重量',
                '持续工作电流',
                '最大瞬时电流',
                '尺寸',
                '类型',
              ],
              rightList: [
                '',
                '11.1V',
                '10400mAh（10.4AH）',
                '12.6V',
                '12.6~9.6V',
                '780g',
                '0~8A',
                '10A',
                '90mm*60mm*156mm',
                '锂离子',
              ]
            },
            {
              name: '喷雾器专用锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20008.jpg',
              id: '20008',
              leftList: [
                '型号',
                '重量',
                '尺寸',
                '续航时间',
                '标称电压',
                '电池重量',
                '循环寿命',
                '输入充电电压',
                '输入充电电流',
                '持续放电电流',
                '充电温度',
                '放电温度',
                '储存温度'
              ],
              rightList: [
                '12V6Ah / 8Ah / 10Ah / 12Ah / 15Ah / 20Ah',
                '≈565g ≈705g ≈820g ≈950g ≈1085g ≈1300g',
                '96*65*150mm',
                '2.5h ~ 14h',
                '11.1v',
                '约500g - 1300g',
                '2000次以上，DOD为80%',
                '12.6v',
                '标准1A，快速5A',
                '8A，约100W',
                '0~45℃',
                '-20~60℃',
                '-20~45℃'
              ]
            },
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20009.jpg',
              id: '20009',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '最大充电电流',
                '瞬时放电电流',
                '持续放电电流',
                '使用寿命',
                '储存温度',
                '重量',
                '尺寸',
                '工作电压范围',
                '过充保护电压',
                '过放保护电压',
                '电池温度保护'
              ],
              rightList: [
                '3.2v 100ah lifepo4',
                '12.8V',
                '200Ah',
                '50A',
                '300A(5S)',
                '100A',
                '大于3000次循环',
                '-20～45℃',
                '18.1kg左右',
                '450*165*145mm/定制',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '60℃+mn5℃'
              ]
            },
            {
              name: '12V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20010.jpg',
              id: '20010',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '瞬时放电电流',
                '最大充电电流',
                '工作电压范围',
                '过充保护电压',
                '过放保护电压',
                '持续放电电流',
                '循环寿命',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V100Ah（磷酸铁锂）',
                '100Ah',
                '12.8V',
                '150A(3S)',
                '20A',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '100A',
                '≥3000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '270*175*180mm / 定制',
                '≈9.1Kg'
              ]
            },
            {
              name: '12V100AH手提箱',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20011.jpg',
              id: '20011',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '过充保护电压',
                '过放保护电压',
                '持续充电电流',
                '持续放电电流',
                '截止放电电流',
                '循环寿命',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V100Ah手提箱（磷酸铁锂）',
                '100Ah',
                '12.8V',
                '10~ 14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '100A',
                '100A',
                '300A（3 秒）',
                '≥4000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '280*240*155mm / 定制',
                '≈9.2Kg'
              ]
            },
            {
              name: '12V300AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20012.jpg',
              id: '20012',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '最大充电电流',
                '持续放电电流',
                '瞬时放电电流',
                '工作电压范围',
                '过充保护电压',
                '过放保护电压',
                '电池温度保护',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12v 300Ah',
                '300Ah',
                '12.8V',
                '200A',
                '200A',
                '600A(5S)',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '70℃+mn5℃',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '522*240*218mm / 定制',
                '25.5±0.5kg'
              ]
            },
          ],
          [
            {
              name: '12V50AH钣金',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20013.jpg',
              id: '20013',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '截止电压',
                '标准充电电流',
                '持续充电电流',
                '持续放电电流',
                '截止放电电流',
                '循环寿命',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V50Ah（磷酸铁锂）',
                '50Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '10A',
                '50A',
                '70A',
                '250A 1-2秒',
                '≥4000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '340*255*85mm（带杆）',
                '≈6.6Kg'
              ]
            },
            {
              name: '12V100AH钣金',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20014.jpg',
              id: '20014',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '截止电压',
                '标准充电电流',
                '持续充电电流',
                '持续放电电流',
                '截止放电电流',
                '循环寿命',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V100Ah（磷酸铁锂）',
                '12V100Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '20A',
                '100A',
                '100A',
                '500A（1~2秒）',
                '≥4000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '390*260*80mm（带杆）',
                '≈11Kg'
              ]
            },
            {
              name: '12V 120AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20015.jpg',
              id: '20015',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '截止电压',
                '标准充电电流',
                '持续充电电流',
                '持续放电电流',
                '截止放电电流',
                '循环寿命',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V120Ah（磷酸铁锂）',
                '120Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '20A',
                '100A',
                '120A',
                '500A（1~2秒）',
                '≥4000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '400*250*142mm',
                '≈15.6Kg'
              ]
            },
            {
              name: '12V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20016.jpg',
              id: '20016',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '12V100Ah',
                '100A',
                '200A',
                '14.6V',
                '10.5V',
                '215*170*325mm',
                '≈10Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '12V150AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20017.jpg',
              id: '20017',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '12V150Ah',
                '100A',
                '200A',
                '14.6V',
                '10.5V',
                '215*170*325mm',
                '≈15Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20018.jpg',
              id: '20018',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '12V200Ah',
                '200A',
                '400A',
                '14.6V',
                '10.5V',
                '165*450*240mm',
                '≈38Kg',
                '磷酸铁锂',
              ]
            },
          ],
          [
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20019.jpg',
              id: '20019',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '12V300AH',
                '300A',
                '600A',
                '14.6V',
                '10.5V',
                '235*485*220mm',
                '≈28Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '12V400Ah',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20020.jpg',
              id: '20020',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '12V300AH',
                '300A',
                '600A',
                '14.6V',
                '10.5V',
                '265*490*220mm',
                '≈40Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '12V400Ah',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20021.jpg',
              id: '20021',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '24V100AH',
                '100A',
                '200A',
                '29.2V',
                '21V',
                '165*450*240mm',
                '≈19Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '24V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20022.jpg',
              id: '20022',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '24V200Ah',
                '200A',
                '400A',
                '29.2V',
                '21V',
                '265*490*220mm',
                '≈40Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '24V300AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20023.jpg',
              id: '20023',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '24V300Ah',
                '300A',
                '600A',
                '29.2V',
                '21V',
                '255*560*285mm',
                '≈72Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '24V400AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20024.jpg',
              id: '20024',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '24V400Ah',
                '300A',
                '600A',
                '29.2V',
                '21V',
                '255*680*320mm',
                '≈88Kg',
                '磷酸铁锂',
              ]
            },
          ],
          [
            {
              name: '12V户外多功能锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20025.jpg',
              id: '20025',
              leftList: [
                '型号',
                '输入电压',
                '输出电压',
                '标称电压',
                '持续输出电流',
                '工作温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V40Ah',
                '12.6V',
                '12.6V ~ 9.6V',
                '12V',
                '40A',
                '-20℃ ~ 40℃',
                '230*85*150mm',
                '≈3Kg',
              ]
            },
            {
              name: '12V户外多功能锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20026.jpg',
              id: '20026',
              leftList: [
                '型号',
                '电池类型',
                '标称电压',
                '限制电压',
                '终止电压',
                '充电电流',
                '持续电流',
                '工作温度',
                '尺寸'
              ],
              rightList: [
                '12V40Ah / 70Ah',
                '聚合物锂电池',
                '12.6V',
                '12.6V',
                '9.5V',
                '5 ~ 8A闪充',
                '50A',
                '-20℃ ~ 60℃',
                '245*60*350mm 345*80*350mm'
              ]
            },
            {
              name: '12V户外多功能锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20027.jpg',
              id: '20027',
              leftList: [
                '充电方式',
                '瞬间放电电流',
                '持续放电电流',
                '充电电流',
                '保护电压',
                '放电终止电压',
                '工作温度',
                '循环寿命',
                '尺寸',
                '重量'
              ],
              rightList: [
                '恒流恒压',
                '200A',
                '150A',
                '12.6V 5A',
                '210 ~ 220V',
                '10.1V',
                '-20℃ ~ 60℃',
                '2000次以上',
                '310*238*51mm',
                '4Kg左右'
              ]
            },
            {
              name: '300W UPS',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20028.jpg',
              id: '20028',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度',
              ],
              rightList: [
                '',
                '',
                '',
                '',
                '',
              ]
            },
            {
              name: '12V铅酸外壳合集',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20029.jpg',
              id: '20029',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度',
              ],
              rightList: [
                '',
                '',
                '',
                '',
                '',
              ]
            },
            {
              name: '12V简包',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20030.jpg',
              id: '20030',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度',
              ],
              rightList: [
                '',
                '',
                '',
                '',
                '',
              ]
            },
          ],
          [
            {
              name: '银鱼海霸电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30001.jpg',
              id: '30001',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '尺寸',
                '重量',
                '放电时间',
                '循环寿命',
              ],
              rightList: [
                '18650 Li Ion Cell',
                '48V',
                '10Ah/12Ah/15Ah/20Ah/25Ah/30Ah',
                '370*135*85mm',
                '4.5kg-7kg',
                '6-8小时',
                '1000次',
              ]
            },
            {
              name: '电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30002.jpg',
              id: '30002',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '重量',
                '尺寸',
              ],
              rightList: [
                '锂离子18650',
                '48V',
                '10Ah/12Ah/15Ah/20Ah',
                '3kg+',
                '370mm*160mm*100mm',
              ]
            },
            {
              name: '横梁款电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30003.jpg',
              id: '30003',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '充电截止电压',
                '放电截止电压',
                '标准充电电流',
                '组合类型',
                '电芯',
                'BMS',
                '适配电机功率',
                '放电温度',
                '充电温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '48V10Ah（48V12.5Ah）',
                '10Ah、12.5ah',
                '48V',
                '54.6V',
                '39V',
                '2-5A',
                '13Series 4 Parallel 13S4P / 13Series 5 Parallel 13S5P',
                '18650 3.7V 2500mAh',
                '30Amp 连续 60 ± 10AMP 最大',
                '48V 500W 750W 1000W',
                '-20℃～65℃',
                '0～45℃',
                '430x100x42mm',
                '≈3Kg'
              ]
            },
            {
              name: '海龙款电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30004.jpg',
              id: '30004',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '充电截止电压',
                '放电截止电压',
                '标准充电电流',
                '组合类型',
                '电芯',
                'BMS',
                '适配电机功率',
                '放电温度',
                '充电温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '36V10Ah',
                '10Ah',
                '36V',
                '42V',
                '30V',
                '2-5A',
                '10Series 5Paralles 10S5P',
                '18650 3.7V 2000mAh',
                '20 Amp 连续最大 50±10Amp',
                '36V 250W 350W 500W',
                '-20℃～65℃',
                '-20 ~45℃',
                '90*111*367mm',
                '≈3.7kg'
              ]
            },
            {
              name: '水壶款电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30005.jpg',
              id: '30005',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '最大负载',
                '标准充电电流',
                '截止放电电流',
              ],
              rightList: [
                '36V10ah 12ah 15ah 17.5ah （48V 10Ah/12Ah/15Ah）',
                '10Ah/12Ah/15Ah/17.5ah',
                '36V(48V)',
                '42V(54.6V)',
                '5A',
                '20A',
                '(39V)',
              ]
            },
            {
              name: '小包车电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30006.jpg',
              id: '30006',
              leftList: [
                '型号',
                '标称电压',
                '工作电流',
                '循环寿命',
                '充电时间',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '48V16Ah',
                '48V',
                '16Ah',
                '600次60%',
                '6 ~ 10h',
                '160*150*183mm',
                '≈5.5Kg',
                '18650三元锂'
              ]
            },
          ],
          [
            {
              name: '48V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30007.jpg',
              id: '30007',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '48V32Ah',
                '48V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '315*190*105mm',
                '≈8.7Kg',
                '三元锂'
              ]
            },
            {
              name: '60V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30008.jpg',
              id: '30008',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V32Ah',
                '60V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '270*160*173mm',
                '≈11Kg',
                '三元锂'
              ]
            },
            {
              name: '72V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30009.jpg',
              id: '30009',
              leftList: [
                '型号',
                '标称电压',
                '标称电流',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '72V32Ah',
                '72V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '290*160*170mm',
                '≈12.6Kg',
                '三元锂'
              ]
            },
            {
              name: '72V60AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30010.jpg',
              id: '30010',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '72V60Ah',
                '72V',
                '60Ah',
                '60Ah',
                '-20℃ ~ 45℃',
                '170*230*375mm',
                '≈24.5Kg',
                '三元锂'
              ]
            },
            {
              name: '72V70AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30011.jpg',
              id: '30011',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '72V70Ah',
                '72V',
                '70Ah',
                '70Ah',
                '-20℃ ~ 45℃',
                '170*480*235mm',
                '≈31.5Kg',
                '三元锂'
              ]
            },
            {
              name: '60V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30012.jpg',
              id: '30012',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V100Ah',
                '60V',
                '100Ah',
                '100Ah',
                '-20℃ ~ 45℃',
                '170*540*235mm',
                '≈36.5Kg',
                '三元锂'
              ]
            },
          ],
          [
            {
              name: '60V60AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30013.jpg',
              id: '30013',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V60Ah',
                '60V',
                '60Ah',
                '60Ah',
                '-20℃ ~ 45℃',
                '170*230*330',
                '≈21.5Kg',
                '三元锂'
              ]
            },
            {
              name: '60V50A',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30014.jpg',
              id: '30014',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V50Ah',
                '60V',
                '50Ah',
                '50Ah',
                '0℃ ~ 45℃',
                '300*230*170mm',
                '≈19.5Kg',
                '三元锂'
              ]
            },
            {
              name: '60V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30015.jpg',
              id: '30015',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V40Ah',
                '60V',
                '40Ah',
                '40Ah',
                '0℃ ~ 45℃',
                '300*230*170mm',
                '≈17.8Kg',
                '三元锂'
              ]
            },
            {
              name: '60V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30016.jpg',
              id: '30016',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V40Ah',
                '60V',
                '40Ah',
                '40Ah',
                '0℃ ~ 45℃',
                '300*230*170mm',
                '≈17.8Kg',
                '三元锂'
              ]
            },
            {
              name: '72V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30017.jpg',
              id: '30017',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '72V100Ah',
                '72V',
                '100Ah',
                '100Ah',
                '-20℃ ~ 45℃',
                '170*600*235mm',
                '≈42.5Kg',
                '三元锂'
              ]
            },
            {
              name: '电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30018.jpg',
              id: '30018',
              leftList: [
                '标称电压',
                '标称容量',
                '续航公里',
                '尺寸',
                '重量',
                '兼容'
              ],
              rightList: [
                '48V',
                '10A / 12A / 15A / 20A',
                '30~90KM',
                '400*165*95mm',
                '3Kg起',
                '通用'
              ]
            },
          ],
          [
            {
              name: '小力鹰60V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30019.jpg',
              id: '30019',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '72V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30020.jpg',
              id: '30020',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '48V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30021.jpg',
              id: '30021',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '海龙2号电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30022.jpg',
              id: '30022',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '72V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30023.jpg',
              id: '30023',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '简包款电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30024.jpg',
              id: '30024',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
          ],
          [
            {
              name: '60V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30025.jpg',
              id: '30025',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '60V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30026.jpg',
              id: '30026',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '60V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30027.jpg',
              id: '30027',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '48V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30028.jpg',
              id: '30028',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '48V12AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30029.jpg',
              id: '30029',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '48V10AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30030.jpg',
              id: '30030',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
          ],
          [
            {
              name: '48V10AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30031.jpg',
              id: '30031',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '电动车锂电池定制',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_40001.jpg',
              id: '100001',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度',
              ],
              rightList: [
                '18650',
                '48V',
                '10,15,20,25Ah',
                '5~45',
                '-20~60',
              ]
            },
          ]
        ],
      },
      productOne: {
        total: 12,
        list: [
          [
            {
              name: '18650-3200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10001.jpg',
              id: '10001',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '最大充电电压',
                '放电截止电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-32R',
                '3200mAh',
                '3.7V',
                '4.2V',
                '2.75V',
                '≤25毫欧',
                '65.1mm *18.4mm (直径)',
                '52g±1g'
              ]
            },
            {
              name: '18650-2600mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10002.jpg',
              id: '10002',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-26R',
                '2600mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm x 18.4mm (直径)',
                '48g ± 1g'
              ]
            },
            {
              name: '18650-2500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10003.jpg',
              id: '10003',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-25R',
                '2500mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm x 18.4mm (直径)',
                '46g ± 1g'
              ]
            },
            {
              name: '18650-2200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10004.jpg',
              id: '10004',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-22R',
                '2200mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm *18.4mm (直径)',
                '44g±1g'
              ]
            },
            {
              name: '18650-1500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10005.jpg',
              id: '10005',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-15R',
                '1500mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm x 18.4mm (直径)',
                '38g ± 1g'
              ]
            },
            {
              name: '18650-1800mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10006.jpg',
              id: '10006',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                '18650-1800mAh',
                '1800mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm x 18.4mm (直径)',
                '40g ± 1g'
              ]
            },
          ],
          [
            {
              name: '21700-4800mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10007.jpg',
              id: '10007',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '充电截止电压',
                '放电截止电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC21700-48R',
                '4800mAh',
                '3.7V',
                '4.2V',
                '2.5V',
                '≤24毫欧',
                '70mm *21mm (直径)',
                '69g±1g'
              ]
            },
            {
              name: '26650-3500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10008.jpg',
              id: '10008',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC26650-35R',
                '3500mAh',
                '3.7V',
                '4.2V',
                '65mm *26mm (直径)',
                '82g±1g'
              ]
            },
            {
              name: '26700-4000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10009.jpg',
              id: '10009',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '放电终止电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC26700-40R',
                '4000mAh',
                '3.2V',
                '3.65 ± 0.05v',
                '2.0 ± 0.05v',
                '≤20毫欧',
                '70mm *26mm (直径)',
                '103g±1g'
              ]
            },
            {
              name: '32700-6000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10010.jpg',
              id: '10010',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '充放电流',
                '充放次数',
                '内阻',
                '尺寸',
                '重量',
                '材料体系',
              ],
              rightList: [
                'IRC32700-60R',
                '6000mAh',
                '3.2V',
                '1C充3C放',
                '3000次以上',
                '≤7毫欧',
                '32mm *70mm (直径)',
                '150g±2g',
                '磷酸铁锂',
              ]
            },
            {
              name: '18650-1200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10011.jpg',
              id: '10011',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-12R',
                '1200mAh',
                '3.7V',
                '2.75 ~4.2V',
                '43.2V',
                '≤38毫欧',
                '65.1mm x 18.4mm (直径)',
                '35g ± 1g'
              ]
            },
            {
              name: '18650-2000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10012.jpg',
              id: '10012',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '负载极限电压',
                '内阻',
                '尺寸',
                '重量'
              ],
              rightList: [
                'IRC18650-20R',
                '2000mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38毫欧',
                '65.1mm *18.4mm (直径)',
                '42g ± 1g'
              ]
            },
          ]
        ],
      },
      productTwo: {
        total: 30,
        list: [
          [
            {
              name: '300W UPS',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20001.jpg',
              id: '20001',
              leftList: [
                '产品型号',
                '重量',
                '尺寸',
                '输出功率',
                '输入电压',
                '输出电压',
                '直流输出',
                'USB输出',
                '过载',
                '短路',
                '充电时间',
              ],
              rightList: [
                'ES-300',
                '3.2Kg',
                '29.3*12.2*13.0cm',
                '额定300W',
                'AC 110V~220V 50/60HZ',
                '220V 50HZ/110V 60HZ',
                'DC 12V/5A-2',
                '5V/3A-2',
                '过载后，交流输出断开，断开负载，重启恢复',
                '短路后，交流输出断开，负载断开，重启恢复',
                '6~7H',
              ]
            },
            {
              name: '12V25AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20002.jpg',
              id: '20002',
              leftList: [
                '标称电压',
                '标称容量',
                '输出电压',
                '重量',
                '持续工作电流',
                '最大瞬时电流',
              ],
              rightList: [
                '12.8V',
                '25000mAh (25AH)',
                '14.6~10.0V',
                '2.7kg左右',
                '25A',
                '100A（1-2S）',
              ]
            },
            {
              name: '12V铅酸电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20003.jpg',
              id: '20003',
              leftList: [
                '标称电压',
                '标称容量',
                '电池尺寸',
                '电池重量',
                '电池寿命',
                '输入充电电压',
                '输入充电电流',
              ],
              rightList: [
                '11.1V',
                '7Ah/9Ah/12Ah/15Ah/21Ah',
                '64*98*150mm',
                '500g - 1300g',
                '超过 2000 次,DOD 为 80%',
                '12.6v',
                '标准1A,快速5A',
              ]
            },
            {
              name: '12V防水电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20004.jpg',
              id: '20004',
              leftList: [
                '标称电压',
                '标称容量',
                '输出电压',
                '重量',
              ],
              rightList: [
                '11.1V',
                '10400mAh(10.4AH)/20000mAh(20Ah)/30000mAh(30Ah)',
                '12.6~9.6V',
                '780g/1460g/2200g',
              ]
            },
            {
              name: '12V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20005.jpg',
              id: '20005',
              leftList: [
                '标称电压',
                '标称容量',
                '输出电压',
                '电池重量',
                '持续工作电流',
                '最大瞬时电流',
                '尺寸',
                '类型',
              ],
              rightList: [
                '11.1V',
                '30000mAh（30Ah）',
                '12.6~9.6V',
                '2200g',
                '0~10A',
                '10A',
                '230mm*55mm*300mm',
                '锂离子',
              ]
            },
            {
              name: '12V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20006.jpg',
              id: '20006',
              leftList: [
                '标称电压',
                '标称容量',
                '输出电压',
                '电池重量',
                '持续工作电流',
                '最大瞬时电流',
                '尺寸',
                '类型',
              ],
              rightList: [
                '11.1V',
                '20000mAh（20AH）',
                '12.6~9.6V',
                '1460g',
                '15~20A',
                '30A',
                '120mm*75mm*200mm',
                '锂离子',
              ]
            },
          ],
          [
            {
              name: '12V10AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20007.jpg',
              id: '20007',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '输入电压',
                '输出电压',
                '电池重量',
                '持续工作电流',
                '最大瞬时电流',
                '尺寸',
                '类型',
              ],
              rightList: [
                '',
                '11.1V',
                '10400mAh（10.4AH）',
                '12.6V',
                '12.6~9.6V',
                '780g',
                '0~8A',
                '10A',
                '90mm*60mm*156mm',
                '锂离子',
              ]
            },
            {
              name: '喷雾器专用锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20008.jpg',
              id: '20008',
              leftList: [
                '型号',
                '重量',
                '尺寸',
                '续航时间',
                '标称电压',
                '电池重量',
                '循环寿命',
                '输入充电电压',
                '输入充电电流',
                '持续放电电流',
                '充电温度',
                '放电温度',
                '储存温度'
              ],
              rightList: [
                '12V6Ah / 8Ah / 10Ah / 12Ah / 15Ah / 20Ah',
                '≈565g ≈705g ≈820g ≈950g ≈1085g ≈1300g',
                '96*65*150mm',
                '2.5h ~ 14h',
                '11.1v',
                '约500g - 1300g',
                '2000次以上，DOD为80%',
                '12.6v',
                '标准1A，快速5A',
                '8A，约100W',
                '0~45℃',
                '-20~60℃',
                '-20~45℃'
              ]
            },
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20009.jpg',
              id: '20009',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '最大充电电流',
                '瞬时放电电流',
                '持续放电电流',
                '使用寿命',
                '储存温度',
                '重量',
                '尺寸',
                '工作电压范围',
                '过充保护电压',
                '过放保护电压',
                '电池温度保护'
              ],
              rightList: [
                '3.2v 100ah lifepo4',
                '12.8V',
                '200Ah',
                '50A',
                '300A(5S)',
                '100A',
                '大于3000次循环',
                '-20～45℃',
                '18.1kg左右',
                '450*165*145mm/定制',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '60℃+mn5℃'
              ]
            },
            {
              name: '12V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20010.jpg',
              id: '20010',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '瞬时放电电流',
                '最大充电电流',
                '工作电压范围',
                '过充保护电压',
                '过放保护电压',
                '持续放电电流',
                '循环寿命',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V100Ah（磷酸铁锂）',
                '100Ah',
                '12.8V',
                '150A(3S)',
                '20A',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '100A',
                '≥3000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '270*175*180mm / 定制',
                '≈9.1Kg'
              ]
            },
            {
              name: '12V100AH手提箱',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20011.jpg',
              id: '20011',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '工作电压',
                '过充保护电压',
                '过放保护电压',
                '持续充电电流',
                '持续放电电流',
                '截止放电电流',
                '循环寿命',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V100Ah手提箱（磷酸铁锂）',
                '100Ah',
                '12.8V',
                '10~ 14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '100A',
                '100A',
                '300A（3 秒）',
                '≥4000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '280*240*155mm / 定制',
                '≈9.2Kg'
              ]
            },
            {
              name: '12V300AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20012.jpg',
              id: '20012',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '最大充电电流',
                '持续放电电流',
                '瞬时放电电流',
                '工作电压范围',
                '过充保护电压',
                '过放保护电压',
                '电池温度保护',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12v 300Ah',
                '300Ah',
                '12.8V',
                '200A',
                '200A',
                '600A(5S)',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '70℃+mn5℃',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '522*240*218mm / 定制',
                '25.5±0.5kg'
              ]
            },
          ],
          [
            {
              name: '12V50AH钣金',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20013.jpg',
              id: '20013',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '截止电压',
                '标准充电电流',
                '持续充电电流',
                '持续放电电流',
                '截止放电电流',
                '循环寿命',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V50Ah（磷酸铁锂）',
                '50Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '10A',
                '50A',
                '70A',
                '250A 1-2秒',
                '≥4000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '340*255*85mm（带杆）',
                '≈6.6Kg'
              ]
            },
            {
              name: '12V100AH钣金',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20014.jpg',
              id: '20014',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '截止电压',
                '标准充电电流',
                '持续充电电流',
                '持续放电电流',
                '截止放电电流',
                '循环寿命',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V100Ah（磷酸铁锂）',
                '12V100Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '20A',
                '100A',
                '100A',
                '500A（1~2秒）',
                '≥4000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '390*260*80mm（带杆）',
                '≈11Kg'
              ]
            },
            {
              name: '12V 120AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20015.jpg',
              id: '20015',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '截止电压',
                '标准充电电流',
                '持续充电电流',
                '持续放电电流',
                '截止放电电流',
                '循环寿命',
                '充电温度',
                '排气温度',
                '储存温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V120Ah（磷酸铁锂）',
                '120Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '20A',
                '100A',
                '120A',
                '500A（1~2秒）',
                '≥4000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '400*250*142mm',
                '≈15.6Kg'
              ]
            },
            {
              name: '12V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20016.jpg',
              id: '20016',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '12V100Ah',
                '100A',
                '200A',
                '14.6V',
                '10.5V',
                '215*170*325mm',
                '≈10Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '12V150AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20017.jpg',
              id: '20017',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '12V150Ah',
                '100A',
                '200A',
                '14.6V',
                '10.5V',
                '215*170*325mm',
                '≈15Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20018.jpg',
              id: '20018',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '12V200Ah',
                '200A',
                '400A',
                '14.6V',
                '10.5V',
                '165*450*240mm',
                '≈38Kg',
                '磷酸铁锂',
              ]
            },
          ],
          [
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20019.jpg',
              id: '20019',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '12V300AH',
                '300A',
                '600A',
                '14.6V',
                '10.5V',
                '235*485*220mm',
                '≈28Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '12V400Ah',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20020.jpg',
              id: '20020',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '12V300AH',
                '300A',
                '600A',
                '14.6V',
                '10.5V',
                '265*490*220mm',
                '≈40Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '12V400Ah',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20021.jpg',
              id: '20021',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '24V100AH',
                '100A',
                '200A',
                '29.2V',
                '21V',
                '165*450*240mm',
                '≈19Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '24V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20022.jpg',
              id: '20022',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '24V200Ah',
                '200A',
                '400A',
                '29.2V',
                '21V',
                '265*490*220mm',
                '≈40Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '24V300AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20023.jpg',
              id: '20023',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '24V300Ah',
                '300A',
                '600A',
                '29.2V',
                '21V',
                '255*560*285mm',
                '≈72Kg',
                '磷酸铁锂',
              ]
            },
            {
              name: '24V400AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20024.jpg',
              id: '20024',
              leftList: [
                '型号',
                '标准电流',
                '瞬间电流',
                '充电截止电压',
                '放电截止电压',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '24V400Ah',
                '300A',
                '600A',
                '29.2V',
                '21V',
                '255*680*320mm',
                '≈88Kg',
                '磷酸铁锂',
              ]
            },
          ],
          [
            {
              name: '12V户外多功能锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20025.jpg',
              id: '20025',
              leftList: [
                '型号',
                '输入电压',
                '输出电压',
                '标称电压',
                '持续输出电流',
                '工作温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '12V40Ah',
                '12.6V',
                '12.6V ~ 9.6V',
                '12V',
                '40A',
                '-20℃ ~ 40℃',
                '230*85*150mm',
                '≈3Kg',
              ]
            },
            {
              name: '12V户外多功能锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20026.jpg',
              id: '20026',
              leftList: [
                '型号',
                '电池类型',
                '标称电压',
                '限制电压',
                '终止电压',
                '充电电流',
                '持续电流',
                '工作温度',
                '尺寸'
              ],
              rightList: [
                '12V40Ah / 70Ah',
                '聚合物锂电池',
                '12.6V',
                '12.6V',
                '9.5V',
                '5 ~ 8A闪充',
                '50A',
                '-20℃ ~ 60℃',
                '245*60*350mm 345*80*350mm'
              ]
            },
            {
              name: '12V户外多功能锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20027.jpg',
              id: '20027',
              leftList: [
                '充电方式',
                '瞬间放电电流',
                '持续放电电流',
                '充电电流',
                '保护电压',
                '放电终止电压',
                '工作温度',
                '循环寿命',
                '尺寸',
                '重量'
              ],
              rightList: [
                '恒流恒压',
                '200A',
                '150A',
                '12.6V 5A',
                '210 ~ 220V',
                '10.1V',
                '-20℃ ~ 60℃',
                '2000次以上',
                '310*238*51mm',
                '4Kg左右'
              ]
            },
            {
              name: '300W UPS',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20028.jpg',
              id: '20028',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度',
              ],
              rightList: [
                '',
                '',
                '',
                '',
                '',
              ]
            },
            {
              name: '12V铅酸外壳合集',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20029.jpg',
              id: '20029',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度',
              ],
              rightList: [
                '',
                '',
                '',
                '',
                '',
              ]
            },
            {
              name: '12V简包',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20030.jpg',
              id: '20030',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度',
              ],
              rightList: [
                '',
                '',
                '',
                '',
                '',
              ]
            },
          ]
        ],
      },
      productThree: {
        total: 31,
        list: [
          [
            {
              name: '银鱼海霸电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30001.jpg',
              id: '30001',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '尺寸',
                '重量',
                '放电时间',
                '循环寿命',
              ],
              rightList: [
                '18650 Li Ion Cell',
                '48V',
                '10Ah/12Ah/15Ah/20Ah/25Ah/30Ah',
                '370*135*85mm',
                '4.5kg-7kg',
                '6-8小时',
                '1000次',
              ]
            },
            {
              name: '电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30002.jpg',
              id: '30002',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '重量',
                '尺寸',
              ],
              rightList: [
                '锂离子18650',
                '48V',
                '10Ah/12Ah/15Ah/20Ah',
                '3kg+',
                '370mm*160mm*100mm',
              ]
            },
            {
              name: '横梁款电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30003.jpg',
              id: '30003',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '充电截止电压',
                '放电截止电压',
                '标准充电电流',
                '组合类型',
                '电芯',
                'BMS',
                '适配电机功率',
                '放电温度',
                '充电温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '48V10Ah（48V12.5Ah）',
                '10Ah、12.5ah',
                '48V',
                '54.6V',
                '39V',
                '2-5A',
                '13Series 4 Parallel 13S4P / 13Series 5 Parallel 13S5P',
                '18650 3.7V 2500mAh',
                '30Amp 连续 60 ± 10AMP 最大',
                '48V 500W 750W 1000W',
                '-20℃～65℃',
                '0～45℃',
                '430x100x42mm',
                '≈3Kg'
              ]
            },
            {
              name: '海龙款电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30004.jpg',
              id: '30004',
              leftList: [
                '电池型号',
                '标称容量',
                '标称电压',
                '充电截止电压',
                '放电截止电压',
                '标准充电电流',
                '组合类型',
                '电芯',
                'BMS',
                '适配电机功率',
                '放电温度',
                '充电温度',
                '尺寸',
                '重量'
              ],
              rightList: [
                '36V10Ah',
                '10Ah',
                '36V',
                '42V',
                '30V',
                '2-5A',
                '10Series 5Paralles 10S5P',
                '18650 3.7V 2000mAh',
                '20 Amp 连续最大 50±10Amp',
                '36V 250W 350W 500W',
                '-20℃～65℃',
                '-20 ~45℃',
                '90*111*367mm',
                '≈3.7kg'
              ]
            },
            {
              name: '水壶款电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30005.jpg',
              id: '30005',
              leftList: [
                '电芯型号',
                '标称容量',
                '标称电压',
                '充电电压',
                '最大负载',
                '标准充电电流',
                '截止放电电流',
              ],
              rightList: [
                '36V10ah 12ah 15ah 17.5ah （48V 10Ah/12Ah/15Ah）',
                '10Ah/12Ah/15Ah/17.5ah',
                '36V(48V)',
                '42V(54.6V)',
                '5A',
                '20A',
                '(39V)',
              ]
            },
            {
              name: '小包车电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30006.jpg',
              id: '30006',
              leftList: [
                '型号',
                '标称电压',
                '工作电流',
                '循环寿命',
                '充电时间',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '48V16Ah',
                '48V',
                '16Ah',
                '600次60%',
                '6 ~ 10h',
                '160*150*183mm',
                '≈5.5Kg',
                '18650三元锂'
              ]
            },
          ],
          [
            {
              name: '48V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30007.jpg',
              id: '30007',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '48V32Ah',
                '48V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '315*190*105mm',
                '≈8.7Kg',
                '三元锂'
              ]
            },
            {
              name: '60V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30008.jpg',
              id: '30008',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V32Ah',
                '60V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '270*160*173mm',
                '≈11Kg',
                '三元锂'
              ]
            },
            {
              name: '72V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30009.jpg',
              id: '30009',
              leftList: [
                '型号',
                '标称电压',
                '标称电流',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '72V32Ah',
                '72V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '290*160*170mm',
                '≈12.6Kg',
                '三元锂'
              ]
            },
            {
              name: '72V60AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30010.jpg',
              id: '30010',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '72V60Ah',
                '72V',
                '60Ah',
                '60Ah',
                '-20℃ ~ 45℃',
                '170*230*375mm',
                '≈24.5Kg',
                '三元锂'
              ]
            },
            {
              name: '72V70AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30011.jpg',
              id: '30011',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '72V70Ah',
                '72V',
                '70Ah',
                '70Ah',
                '-20℃ ~ 45℃',
                '170*480*235mm',
                '≈31.5Kg',
                '三元锂'
              ]
            },
            {
              name: '60V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30012.jpg',
              id: '30012',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V100Ah',
                '60V',
                '100Ah',
                '100Ah',
                '-20℃ ~ 45℃',
                '170*540*235mm',
                '≈36.5Kg',
                '三元锂'
              ]
            },
          ],
          [
            {
              name: '60V60AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30013.jpg',
              id: '30013',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V60Ah',
                '60V',
                '60Ah',
                '60Ah',
                '-20℃ ~ 45℃',
                '170*230*330',
                '≈21.5Kg',
                '三元锂'
              ]
            },
            {
              name: '60V50A',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30014.jpg',
              id: '30014',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V50Ah',
                '60V',
                '50Ah',
                '50Ah',
                '0℃ ~ 45℃',
                '300*230*170mm',
                '≈19.5Kg',
                '三元锂'
              ]
            },
            {
              name: '60V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30015.jpg',
              id: '30015',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V40Ah',
                '60V',
                '40Ah',
                '40Ah',
                '0℃ ~ 45℃',
                '300*230*170mm',
                '≈17.8Kg',
                '三元锂'
              ]
            },
            {
              name: '60V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30016.jpg',
              id: '30016',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '60V40Ah',
                '60V',
                '40Ah',
                '40Ah',
                '0℃ ~ 45℃',
                '300*230*170mm',
                '≈17.8Kg',
                '三元锂'
              ]
            },
            {
              name: '72V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30017.jpg',
              id: '30017',
              leftList: [
                '型号',
                '标称电压',
                '标称容量',
                '工作电流',
                '工作温度',
                '尺寸',
                '重量',
                '材料体系'
              ],
              rightList: [
                '72V100Ah',
                '72V',
                '100Ah',
                '100Ah',
                '-20℃ ~ 45℃',
                '170*600*235mm',
                '≈42.5Kg',
                '三元锂'
              ]
            },
            {
              name: '电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30018.jpg',
              id: '30018',
              leftList: [
                '标称电压',
                '标称容量',
                '续航公里',
                '尺寸',
                '重量',
                '兼容'
              ],
              rightList: [
                '48V',
                '10A / 12A / 15A / 20A',
                '30~90KM',
                '400*165*95mm',
                '3Kg起',
                '通用'
              ]
            },
          ],
          [
            {
              name: '小力鹰60V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30019.jpg',
              id: '30019',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '72V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30020.jpg',
              id: '30020',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '48V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30021.jpg',
              id: '30021',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '海龙2号电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30022.jpg',
              id: '30022',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '72V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30023.jpg',
              id: '30023',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '简包款电动车锂电池',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30024.jpg',
              id: '30024',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
          ],
          [
            {
              name: '60V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30025.jpg',
              id: '30025',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '60V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30026.jpg',
              id: '30026',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '60V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30027.jpg',
              id: '30027',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '48V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30028.jpg',
              id: '30028',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '48V12AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30029.jpg',
              id: '30029',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '48V10AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30030.jpg',
              id: '30030',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
          ],
          [
            {
              name: '48V10AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30031.jpg',
              id: '30031',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
          ]
        ],
      },
      productFour: {
        total: 1,
        list: [
          [
            {
              name: '电动车锂电池定制',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_40001.jpg',
              id: '100001',
              leftList: [
                '电芯型号',
                '标称电压',
                '标称容量',
                '充电温度',
                '放电温度',
              ],
              rightList: [
                '18650',
                '48V',
                '10,15,20,25Ah',
                '5~45',
                '-20~60',
              ]
            },
          ],
        ],
      }
    },
    EN: { // 英文
      productAll: {
        total: 74,
        list: [
          [
            {
              name: '18650-3200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10001.jpg',
              id: '10001',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Maximum charging voltage',
                'Discharge cut-off voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-32R',
                '3200mAh',
                '3.7V',
                '4.2V',
                '2.75V',
                '≤25 milliohms',
                '65.1mm *18.4mm (diameter)',
                '52g±1g'
              ]
            },
            {
              name: '18650-2600mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10002.jpg',
              id: '10002',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-26R',
                '2600mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm x 18.4mm (diameter)',
                '48g ± 1g'
              ]
            },
            {
              name: '18650-2500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10003.jpg',
              id: '10003',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-25R',
                '2500mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm x 18.4mm (diameter)',
                '46g ± 1g'
              ]
            },
            {
              name: '18650-2200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10004.jpg',
              id: '10004',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-22R',
                '2200mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm *18.4mm (diameter)',
                '44g±1g'
              ]
            },
            {
              name: '18650-1500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10005.jpg',
              id: '10005',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-15R',
                '1500mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm x 18.4mm (diameter)',
                '38g ± 1g'
              ]
            },
            {
              name: '18650-1800mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10006.jpg',
              id: '10006',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                '18650-1800mAh',
                '1800mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm x 18.4mm (diameter)',
                '40g ± 1g'
              ]
            },
          ],
          [
            {
              name: '21700-4800mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10007.jpg',
              id: '10007',
              leftList: [
                'Battery model',
                'Battery material',
                'Nominal capacity',
                'Battery voltage',
                'Internal resistance of the battery',
                'Battery specifications',
              ],
              rightList: [
                '21700',
                'Li-ion Lithium Ion Battery',
                '4800mAh (milliampere)',
                '3.6V (volts)',
                '11.5-14.5mΩ (milliohms)',
                '21*70 mm',
              ]
            },
            {
              name: '26650-3500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10008.jpg',
              id: '10008',
              leftList: [
                'Model',
                'Battery Type',
                'Typical Capacity',
                'Cell AC Impedance (1KH)',
                'Nominal Voltage',
                'End-of-charge Voltage',
                'End-of-discharge Voltage',
                'Standard charging current',
                'Standard Discharge Current',
                'Max continuous Discharging Current',
                'Maximum pulse discharge current (long pulse)',
                'Maximum pulse discharge current (short pulse)',
                'Cycle Life',
                'Charging Temperatur',
                'Discharging Temperature',
                'Battery size',
                'Battery weight'
              ],
              rightList: [
                '26650-3500mah',
                'Lifepo4 battery cell',
                '3500mah',
                '20 mΩ',
                '3.2V',
                '3.65 V',
                '2.0V',
                '0.5C(1750 mA)',
                '0.5C(1750 mA)',
                '10500mA',
                '17500mA',
                '28000mA',
                '1 C/100 % DOD≥2000 cycles',
                '0~60 °C',
                '-20~ 60°C',
                '26*65mm',
                'About 90g'
              ]
            },
            {
              name: '26700-4000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10009.jpg',
              id: '10009',
              leftList: [
                'Nominal capacity',
                'Nominal voltage',
                'Charging Voltage',
                'Charging Method',
                'Standard Charge Current',
                'Standard Discharge Current',
                'Discharge Cut-off Voltage',
                'AC internal resistance',
                'Energy Density',
                'Rechargeable time',
                'Working temperature (charging)',
                'Working temperature (discharge)',
                'Cell Dimension',
                'Weight'
              ],
              rightList: [
                '4000mAh (Discharge the cell from 3.65V to 2.0V by 0.5C current)',
                '3.2V',
                '3.65 ± 0.05 V',
                'CC-CV (2000mA, 3.65V, 200mA cut-off)',
                '0.5 C(2000 mA)',
                '0.5 C(2000 mA)',
                '2.0 ± 0.05 V',
                '＜20 mΩ',
                '＞135Wh/kg',
                'up to 2000 times',
                '0 ~ 50 ℃',
                '-10 ~ 60℃',
                'Height: 71 mm（±0.20 mm） Diameter:26.4 mm（±0.20 mm）',
                'About 93g'
              ]
            },
            {
              name: '32700-6000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10010.jpg',
              id: '10010',
              leftList: [
                'Capacity',
                'Voltage',
                'Max discharge current',
                'Discharge current',
                'Internal Resistance',
                'Cycle times',
                'Charging Temperature',
                'Discharging temperature',
                'Storage temperature',
                'Size'
              ],
              rightList: [
                'Max 6000mah',
                '3.2V (limit voltage:2.0-3.65V)',
                '5C',
                '3C',
                'less than 8 mohms',
                'about 3000 times',
                '0~45Centigrade',
                '-20~60Centigrade',
                '-20~45Centigrade',
                '32*70mm'
              ]
            },
            {
              name: '18650-1200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10011.jpg',
              id: '10011',
              leftList: [
                'Model',
                'Type',
                'Nominal capacity',
                'Nominal voltage',
                'Limit charging voltage',
                'Working voltage',
                'Internal resistance',
                'Rechargeable time',
                'Rechargeable battery',
                'Origin',
                'Dimensions',
                'Weight'
              ],
              rightList: [
              'IRC18650-12R',
              'Lithium Ion 18650',
              '1200mah',
              '3.7V',
              '4.2v',
              '2.75-4.2V',
              '≤38mΩ',
              'up to 500 times',
              'yes',
              'Made in China',
              '65.1 mm x 18.4 mm (diameter)',
              '35 g ± 1 g'
              ]
            },
            {
              name: '18650-2000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10012.jpg',
              id: '10012',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-20R',
                '2000mAh',
                '3.7V',
                '2.75 ~4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm*18.4mm (diameter)',
                '42g±1g'
              ]
            },
          ],
          [
            {
              name: '300W UPS',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20001.jpg',
              id: '20001',
              leftList: [
                'Model',
                'Input voltage',
                'Charging voltage',
                'Output voltage',
                'AC waveform',
                'Operating temperatue',
                'Size',
                'Weight',
              ],
              rightList: [
                '50Ah500Wh',
                '220V',
                '220V',
                '220V',
                'Sine wave current',
                '-20 ~ 40℃',
                '120*132*192mm',
                'About 3.3Kg',
              ]
            },
            {
              name: '12V25AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20002.jpg',
              id: '20002',
              leftList: [
                'Nominal voltage',
                'Nominal capacity',
                'Output voltage',
                'Weight',
                'Continuous operating current',
                'Maximum instantaneous current',
              ],
              rightList: [
                '12.8V',
                '25000mAh (25AH)',
                '14.6~10.0V',
                'about 2.7kg',
                '25A',
                '100A（1-2S）',
              ]
            },
            {
              name: '12V lead-acid battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20003.jpg',
              id: '20003',
              leftList: [
                'Nominal voltage',
                'Nominal capacity',
                'Battery size',
                'Battery weight',
                'Battery life',
                'Input charging voltage',
                'Input charging current',
              ],
              rightList: [
                '11.1V',
                '7Ah/9Ah/12Ah/15Ah/21Ah',
                '64*98*150mm',
                '500g - 1300g',
                'over 2000 times with 80% DOD',
                '12.6v',
                'standard 1A, fast 5A',
              ]
            },
            {
              name: '12V waterproof battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20004.jpg',
              id: '20004',
              leftList: [
                'Mode',
                'Input voltage',
                'Output voltage',
                'Battery capacity',
                'Battery weight',
                'Continuous operating current'
              ],
              rightList: [
                '12V 20Ah (18650)',
                '12.6V',
                '9.6-12.6V',
                '20Ah',
                'About 1.73kg/1.485kg/1.78kg',
                '20Ah'
              ]
            },
            {
              name: '12V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20005.jpg',
              id: '20005',
              leftList: [
                'Nominal voltage',
                'Nominal capacity',
                'Output voltage',
                'Battery weight',
                'Continuous working current',
                'Maximum instantaneous current',
                'Size',
                'Type',
              ],
              rightList: [
                '11.1V',
                '30000mAh（30Ah）',
                '12.6~9.6V',
                '2200g',
                '0~10A',
                '10A',
                '230mm*55mm*300mm',
                '锂离子',
              ]
            },
            {
              name: '12V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20006.jpg',
              id: '20006',
              leftList: [
                'Nominal voltage',
                'Nominal capacity',
                'Output voltage',
                'Battery weight',
                'Continuous working current',
                'Maximum instantaneous current',
                'Size',
                'Type',
              ],
              rightList: [
                '11.1V',
                '20000mAh（20AH）',
                '12.6~9.6V',
                '1460g',
                '15~20A',
                '30A',
                '120mm*75mm*200mm',
                'Lithium-ion',
              ]
            },
          ],
          [
            {
              name: '12V10AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20007.jpg',
              id: '20007',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Input voltage',
                'Output voltage',
                'Battery weight',
                'Continuous working current',
                'Maximum instantaneous current',
                'Size',
                'Type',
              ],
              rightList: [
                '',
                '11.1V',
                '10400mAh（10.4AH）',
                '12.6V',
                '12.6~9.6V',
                '780g',
                '0~8A',
                '10A',
                '90mm*60mm*156mm',
                'Lithium-ion',
              ]
            },
            {
              name: 'Lithium battery for sprayers',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20008.jpg',
              id: '20008',
              leftList: [
                'Model',
                'Weight',
                'Size',
                'Battery life',
                'Nominal voltage',
                'Battery weight',
                'Cycle life',
                'Input charging voltage',
                'Input charging current',
                'Continuous discharge current',
                'Charging temperature',
                'Discharge temperature',
                'Storage temperature'
              ],
              rightList: [
                '12V6Ah / 8Ah / 10Ah / 12Ah / 15Ah / 20Ah',
                '≈565g ≈705g ≈820g ≈950g ≈1085g ≈1300g',
                '96*65*150mm',
                '2.5h ~ 14h',
                '11.1v',
                'about 500g - 1300g',
                'more than 2000 times, DOD is 80%',
                '12.6v',
                'standard 1A, fast 5A',
                '8A, about 100W',
                '0~45℃',
                '-20~60℃',
                '-20~45℃'
              ]
            },
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20009.jpg',
              id: '20009',
              leftList: [
                'Cell model',
                'Nominal voltage',
                'Nominal capacity',
                'Maximum charging current',
                'Instantaneous discharge current',
                'Continuous discharge current',
                'Service life',
                'Service life',
                'Weight',
                'Size',
                'Working voltage range',
                'Overcharge protection voltage',
                'Over-discharge protection voltage',
                'Battery temperature protection'
              ],
              rightList: [
                '3.2v 100ah lifepo4',
                '12.8V',
                '200Ah',
                '50A',
                '300A(5S)',
                '100A',
                'greater than: 3000 cycles',
                '-20～45℃',
                'about 18.1kg',
                '450*165*145mm/customized',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '60℃+mn5℃'
              ]
            },
            {
              name: '12V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20010.jpg',
              id: '20010',
              leftList: [
                'Battery model',
                'Nominal capacity',
                'Nominal voltage',
                'Instantaneous discharge current',
                'Maximum charging current',
                'Working voltage range',
                'Overcharge protection voltage',
                'Over-discharge protection voltage',
                'Continuous discharge current',
                'Cycle life',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V100Ah (lithium iron phosphate)',
                '100Ah',
                '12.8V',
                '150A(3S)',
                '20A',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '100A',
                '≥3000 times',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '270*175*180mm / customized',
                '≈9.1Kg'
              ]
            },
            {
              name: '12V100AH suitcase',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20011.jpg',
              id: '20011',
              leftList: [
                'Battery Model',
                'Battery capacity',
                'Nominal voltage',
                'Working voltage',
                'Overcharge protection voltage',
                'Over-discharge protection voltage',
                'Continuous charging current',
                'Continuous discharge current',
                'Cut-off discharge current',
                'Cycle life',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V100Ah Suitcase (Lithium Iron Phosphate)',
                '100Ah',
                '12.8V',
                '10~ 14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '100A',
                '100A',
                '300A（3 seconds）',
                '≥4000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '280*240*155mm / customized',
                '≈9.2Kg'
              ]
            },
            {
              name: '12V300AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20012.jpg',
              id: '20012',
              leftList: [
                'Battery model',
                'Nominal capacity',
                'Nominal voltage',
                'Maximum charging current',
                'Continuous discharge current',
                'Instantaneous discharge current',
                'Working voltage range',
                'Overcharge protection voltage',
                'Over-discharge protection voltage',
                'Battery temperature protection',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12v 300Ah',
                '300Ah',
                '12.8V',
                '200A',
                '200A',
                '600A(5S)',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '70℃+mn5℃',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '522*240*218mm / customized',
                '25.5±0.5kg'
              ]
            },
          ],
          [
            {
              name: '12V50AH sheet metal',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20013.jpg',
              id: '20013',
              leftList: [
                'Battery Model',
                'Battery capacity',
                'Nominal voltage',
                'Charging voltage',
                'Cut-off voltage',
                'Standard charging current',
                'Continuous charging current',
                'Continuous discharge current',
                'Cut-off discharge current',
                'Cycle life',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V50Ah (lithium iron phosphate)',
                '50Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '10A',
                '50A',
                '70A',
                '250A 1-2seconds',
                '≥ 4000 times',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '340*255*85mm(with rod)',
                '≈6.6Kg'
              ]
            },
            {
              name: '12V100AH sheet metal',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20014.jpg',
              id: '20014',
              leftList: [
                'Battery Model',
                'Battery capacity',
                'Nominal voltage',
                'Charging voltage',
                'Cut-off voltage',
                'Standard charging current',
                'Continuous charging current',
                'Continuous discharge current',
                'Cut-off discharge current',
                'Cycle life',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V100Ah（lithium iron phosphate）',
                '12V100Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '20A',
                '100A',
                '100A',
                '500A（1~2seconds）',
                '≥ 4000 times',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '390*260*80mm（with rod）',
                '≈11Kg'
              ]
            },
            {
              name: '12V 120AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20015.jpg',
              id: '20015',
              leftList: [
                'Battery model',
                'Battery capacity',
                'Nominal voltage',
                'Charging voltage',
                'Cut-off voltage',
                'Standard charging current',
                'Continuous charging current',
                'Continuous discharge current',
                'Cut-off discharge current',
                'Cycle life',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V120Ah (lithium iron phosphate)',
                '120Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '20A',
                '100A',
                '120A',
                '500A（1~2seconds）',
                '≥ 4000 times',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '400*250*142mm',
                '≈15.6Kg'
              ]
            },
            {
              name: '12V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20016.jpg',
              id: '20016',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Discharge cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '12V100Ah',
                '100A',
                '200A',
                '14.6V',
                '10.5V',
                '215*170*325mm',
                '≈10Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '12V150AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20017.jpg',
              id: '20017',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '12V150Ah',
                '100A',
                '200A',
                '14.6V',
                '10.5V',
                '215*170*325mm',
                '≈15Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20018.jpg',
              id: '20018',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '12V200Ah',
                '200A',
                '400A',
                '14.6V',
                '10.5V',
                '165*450*240mm',
                '≈38Kg',
                'lithium iron phosphate',
              ]
            },
          ],
          [
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20019.jpg',
              id: '20019',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '12V300AH',
                '300A',
                '600A',
                '14.6V',
                '10.5V',
                '235*485*220mm',
                '≈28Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '12V400Ah',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20020.jpg',
              id: '20020',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '12V300AH',
                '300A',
                '600A',
                '14.6V',
                '10.5V',
                '265*490*220mm',
                '≈40Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '12V400Ah',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20021.jpg',
              id: '20021',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '24V100AH',
                '100A',
                '200A',
                '29.2V',
                '21V',
                '165*450*240mm',
                '≈19Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '24V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20022.jpg',
              id: '20022',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '24V200Ah',
                '200A',
                '400A',
                '29.2V',
                '21V',
                '265*490*220mm',
                '≈40Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '24V300AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20023.jpg',
              id: '20023',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '24V300Ah',
                '300A',
                '600A',
                '29.2V',
                '21V',
                '255*560*285mm',
                '≈72Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '24V400AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20024.jpg',
              id: '20024',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '24V400Ah',
                '300A',
                '600A',
                '29.2V',
                '21V',
                '255*680*320mm',
                '≈88Kg',
                'lithium iron phosphate',
              ]
            },
          ],
          [
            {
              name: '12V outdoor multi-functional lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20025.jpg',
              id: '20025',
              leftList: [
                'Model',
                'Input voltage',
                'Output voltage',
                'Nominal voltage',
                'Continuous output current',
                'Working temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V40Ah',
                '12.6V',
                '12.6V ~ 9.6V',
                '12V',
                '40A',
                '-20℃ ~ 40℃',
                '230*85*150mm',
                '≈3Kg',
              ]
            },
            {
              name: '12V outdoor multi-functional lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20026.jpg',
              id: '20026',
              leftList: [
                'Model',
                'Battery type',
                'Nominal voltage',
                'Limit voltage',
                'Termination voltage',
                'Charging current',
                'Continuous current',
                'Working temperature',
                'Size'
              ],
              rightList: [
                '12V40Ah / 70Ah',
                'lithium polymer battery',
                '12.6V',
                '12.6V',
                '9.5V',
                '5 ~ 8Aflash charge',
                '50A',
                '-20℃ ~ 60℃',
                '245*60*350mm 345*80*350mm'
              ]
            },
            {
              name: '12V outdoor multi-functional lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20027.jpg',
              id: '20027',
              leftList: [
                'Charging mode',
                'Instantaneous discharge current',
                'Continuous discharge current',
                'Charging current',
                'Protection voltage',
                'Protection voltage',
                'Working temperature',
                'Cycle life',
                'Size',
                'Weight'
              ],
              rightList: [
                'constant current and constant voltage',
                '200A',
                '150A',
                '12.6V 5A',
                '210 ~ 220V',
                '10.1V',
                '-20℃ ~ 60℃',
                'more than 2000 times',
                '310*238*51mm',
                'about 4Kg'
              ]
            },
            {
              name: '300W UPS',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20028.jpg',
              id: '20028',
              leftList: [
                'Model',
                'Input voltage',
                'Charging voltage',
                'Output voltage',
                'AC waveform',
                'Operating temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V36Ah',
                '220V',
                '220V',
                '220V/12V/5V',
                'Sine wave current',
                '-20 ~ 40℃',
                '110*280*135mm',
                'About 3.4Kg'
              ]
            },
            {
              name: '12V lead-acid enclosure collection',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20029.jpg',
              id: '20029',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '12V package',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20030.jpg',
              id: '20030',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature',
              ],
              rightList: [
                '',
                '',
                '',
                '',
                '',
              ]
            },
          ],
          [
            {
              name: 'Whitebait Haiba Electric Vehicle Lithium Battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30001.jpg',
              id: '30001',
              leftList: [
                'Cell model',
                'Nominal voltage',
                'Nominal capacity',
                'Size',
                'Weight',
                'Discharge time',
                'Cycle life',
              ],
              rightList: [
                '18650 Li Ion Cell',
                '48V',
                '10Ah/12Ah/15Ah/20Ah/25Ah/30Ah',
                '370*135*85mm',
                '4.5kg-7kg',
                '6-8hours',
                '1000 times',
              ]
            },
            {
              name: 'Electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30002.jpg',
              id: '30002',
              leftList: [
                'Standard',
                'Nominal capacity',
                'Size',
                'Weight',
              ],
              rightList: [
                '48V',
                '10 ~ 18AH',
                '≥3Kg',
                '370*100*160mm',
              ]
            },
            {
              name: 'Crossbeam type electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30003.jpg',
              id: '30003',
              leftList: [
                'Nominal Voltage',
                'Nominal Capacity',
                'Composed Type',
                'Cells',
                'BMS',
                'Fit Motor Power',
                'Charge Current',
                'Charge Cut-off Voltage',
                'Discharge Cut-off Voltage',
                'Discharge Temperature',
                'Charge Temperature',
                'Cycle life',
                'Dimensions',
                'Battery Weight'
              ],
              rightList: [
                '48V',
                '10Ah,12.5Ah',
                '13Series 4Paralles 13S4P/13Series 5Paralles 13S5P',
                'Powerful 18650 3.7V 2500mAh',
                '30Amp continuous 60±10Amp maximum',
                '48V, 500W,750W, 1000W',
                '2-5A',
                '54.6V',
                '39V',
                '-20 to 65°C',
                '0 to 45°C',
                '≥80% capacity after 800 cycles.',
                '430*100*42mm',
                '3kg'
              ]
            },
            {
              name: 'Hailong Electric Vehicle Lithium Battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30004.jpg',
              id: '30004',
              leftList: [
                'Nominal Voltage',
                'Nominal Capacity',
                'Composed Type',
                'Cells',
                'BMS',
                'Fit Motor Power',
                'Charge Current',
                'Charge Cut-off Voltage',
                'Discharge Cut-off Voltage',
                'Cycle life',
                'Discharge Temperature',
                'Charge Temperature',
                'Dimensions',
                'Battery Weight'
              ],
              rightList: [
                '36V',
                '10Ah',
                '10Series 5Paralles 10S5P',
                'Powerful 18650 3.7V 2000mAh',
                '20 Amp continuous 50±10Amp maximum',
                '36V 250W 350W 500W',
                '2-5A',
                '42V',
                '30V',
                '≥80% capacity after 800 cycles.',
                '-20 to 65°C',
                '0 to 45°C',
                '90*111*367mm',
                '3.7kg'
              ]
            },
            {
              name: 'Kettle type electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30005.jpg',
              id: '30005',
              leftList: [
                'Nominal Voltage',
                'Nominal Capacity',
                'Standard Charging Current',
                'Charge Voltage',
                'Max.chargecurrent',
                'Standard Discharge Current',
                'Max.Constant Discharge Current',
                'Max.Pulse Charge Current',
                'DischargingCut-offVoltage',
                'Operating Charge Temperature',
                'Operating Discharge Temperature',
                'StorageTemperature'
              ],
              rightList: [
                '48V',
                '10Ah/12Ah/15Ah',
                ' 2,0',
                '54,6 V',
                '5A',
                '15000 mA',
                '25A',
                '60A/75A',
                '39V',
                '0～45℃',
                '-20～60℃',
                '-10～45℃'
              ]
            },
            {
              name: 'Minicab battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30006.jpg',
              id: '30006',
              leftList: [
                'Model',
                'Nominal voltage',
                'Working current',
                'Cycle life',
                'Charging time',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '48V16Ah',
                '48V',
                '16Ah',
                '600 times 60%',
                '6 ~ 10h',
                '160*150*183mm',
                '≈5.5Kg',
                '18650 ternary lithium'
              ]
            },
          ],
          [
            {
              name: '48V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30007.jpg',
              id: '30007',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '48V32Ah',
                '48V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '315*190*105mm',
                '≈8.7Kg',
                'ternary lithium'
              ]
            },
            {
              name: '60V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30008.jpg',
              id: '30008',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '60V32Ah',
                '60V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '270*160*173mm',
                '≈11Kg',
                'ternary lithium'
              ]
            },
            {
              name: '72V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30009.jpg',
              id: '30009',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '72V32Ah',
                '72V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '290*160*170mm',
                '≈12.6Kg',
                'ternary lithium'
              ]
            },
            {
              name: '72V60AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30010.jpg',
              id: '30010',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '72V60Ah',
                '72V',
                '60Ah',
                '60Ah',
                '-20℃ ~ 45℃',
                '170*230*375mm',
                '≈24.5Kg',
                'ternary lithium'
              ]
            },
            {
              name: '72V70AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30011.jpg',
              id: '30011',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '72V70Ah',
                '72V',
                '70Ah',
                '70Ah',
                '-20℃ ~ 45℃',
                '170*480*235mm',
                '≈31.5Kg',
                'ternary lithium'
              ]
            },
            {
              name: '60V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30012.jpg',
              id: '30012',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '60V100Ah',
                '60V',
                '100Ah',
                '100Ah',
                '-20℃ ~ 45℃',
                '170*540*235mm',
                '≈36.5Kg',
                'ternary lithium'
              ]
            },
          ],
          [
            {
              name: '60V60AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30013.jpg',
              id: '30013',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '60V60Ah',
                '60V',
                '60Ah',
                '60Ah',
                '-20℃ ~ 45℃',
                '170*230*330',
                '≈21.5Kg',
                'ternary lithium'
              ]
            },
            {
              name: '60V50A',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30014.jpg',
              id: '30014',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '60V50Ah',
                '60V',
                '50Ah',
                '50Ah',
                '0℃ ~ 45℃',
                '300*230*170mm',
                '≈19.5Kg',
                'ternary lithium'
              ]
            },
            {
              name: '60V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30015.jpg',
              id: '30015',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '60V40Ah',
                '60V',
                '40Ah',
                '40Ah',
                '0℃ ~ 45℃',
                '300*230*170mm',
                '≈17.8Kg',
                'ternary lithium'
              ]
            },
            {
              name: '72V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30016.jpg',
              id: '30016',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '72V40Ah',
                '72V',
                '40Ah',
                '40Ah',
                '0℃ ~ 45℃',
                '340*230*170mm',
                '≈20Kg',
                'ternary lithium'
              ]
            },
            {
              name: '72V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30017.jpg',
              id: '30017',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '72V100Ah',
                '72V',
                '100Ah',
                '100Ah',
                '-20℃ ~ 45℃',
                '170*600*235mm',
                '≈42.5Kg',
                'ternary lithium'
              ]
            },
            {
              name: 'Electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30018.jpg',
              id: '30018',
              leftList: [
                'Standard',
                'Nominal capacity',
                'Size',
                'Weight',
              ],
              rightList: [
                '48V',
                '10 ~ 20AH',
                '400*165*95mm',
                '≥3Kg',
              ]
            },
          ],
          [
            {
              name: 'Xiaoliying 60V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30019.jpg',
              id: '30019',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '72V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30020.jpg',
              id: '30020',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '48V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30021.jpg',
              id: '30021',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: 'Hailong No. 2 electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30022.jpg',
              id: '30022',
              leftList: [
                'Nominal Voltage',
                'Nominal Capacity',
                'Composed Type',
                'Cells',
                'BMS',
                'Power',
                'Charge Current',
                'Charge Cut-off Voltage',
                'Discharge Cut-off Voltage',
                'Discharge Temperature',
                'Charge Temperature',
                'Dimensions',
                'Battery Weight'
              ],
              rightList: [
                '36V',
                '10Ah',
                '10S5P',
                '18650 3.7V 2000mah',
                '20 Amp continuous 50±10Amp maximum',
                '360W',
                '2-5A',
                '42V',
                '30V',
                '-20~ 65°C',
                '0 ~ 45°C',
                '90*111*367mm',
                'About 3kg'
              ]
            },
            {
              name: '72V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30023.jpg',
              id: '30023',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: 'Simple package electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30024.jpg',
              id: '30024',
              leftList: [
              ],
              rightList: [
              ]
            },
          ],
          [
            {
              name: '60V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30025.jpg',
              id: '30025',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '60V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30026.jpg',
              id: '30026',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '60V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30027.jpg',
              id: '30027',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '48V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30028.jpg',
              id: '30028',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '48V12AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30029.jpg',
              id: '30029',
              leftList: [
                'Standard Voltage',
                'Standard capacity',
                'Discharge protection voltage',
                'Normal working current',
                'Peak working current',
                'Operating temperature',
                'Charging method'
              ],
              rightList: [
                '48V',
                '8 ~ 15AH',
                '38.5V',
                '15A',
                '20A',
                '0℃ ~ 45℃',
                'Constant current and constant voltage'
              ]
            },
            {
              name: '48V10AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30030.jpg',
              id: '30030',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
          ],
          [
            {
              name: '48V9AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30031.jpg',
              id: '30031',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: 'Electric vehicle lithium battery customization',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_40001.jpg',
              id: '100001',
              leftList: [
                'Cell model',

                'Nominal voltage',
                
                'Nominal capacity',
                
                "Charging temperature",
                
                'Discharge temperature',
              ],
              rightList: [
                '18650',
                '48V',
                '10,15,20,25Ah',
                '5~45',
                '-20~60',
              ]
            },
          ]
        ],
      },
      
      productOne: {
        total: 12,
        list: [
          [
            {
              name: '18650-3200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10001.jpg',
              id: '10001',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Maximum charging voltage',
                'Discharge cut-off voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-32R',
                '3200mAh',
                '3.7V',
                '4.2V',
                '2.75V',
                '≤25 milliohms',
                '65.1mm *18.4mm (diameter)',
                '52g±1g'
              ]
            },
            {
              name: '18650-2600mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10002.jpg',
              id: '10002',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-26R',
                '2600mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm x 18.4mm (diameter)',
                '48g ± 1g'
              ]
            },
            {
              name: '18650-2500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10003.jpg',
              id: '10003',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-25R',
                '2500mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm x 18.4mm (diameter)',
                '46g ± 1g'
              ]
            },
            {
              name: '18650-2200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10004.jpg',
              id: '10004',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-22R',
                '2200mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm *18.4mm (diameter)',
                '44g±1g'
              ]
            },
            {
              name: '18650-1500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10005.jpg',
              id: '10005',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-15R',
                '1500mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm x 18.4mm (diameter)',
                '38g ± 1g'
              ]
            },
            {
              name: '18650-1800mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10006.jpg',
              id: '10006',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                '18650-1800mAh',
                '1800mAh',
                '3.7V',
                '2.75 ~ 4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm x 18.4mm (diameter)',
                '40g ± 1g'
              ]
            },
          ],
          [
            {
              name: '21700-4800mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10007.jpg',
              id: '10007',
              leftList: [
                'Battery model',
                'Battery material',
                'Nominal capacity',
                'Battery voltage',
                'Internal resistance of the battery',
                'Battery specifications',
              ],
              rightList: [
                '21700',
                'Li-ion Lithium Ion Battery',
                '4800mAh (milliampere)',
                '3.6V (volts)',
                '11.5-14.5mΩ (milliohms)',
                '21*70 mm',
              ]
            },
            {
              name: '26650-3500mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10008.jpg',
              id: '10008',
              leftList: [
                'Model',
                'Battery Type',
                'Typical Capacity',
                'Cell AC Impedance (1KH)',
                'Nominal Voltage',
                'End-of-charge Voltage',
                'End-of-discharge Voltage',
                'Standard charging current',
                'Standard Discharge Current',
                'Max continuous Discharging Current',
                'Maximum pulse discharge current (long pulse)',
                'Maximum pulse discharge current (short pulse)',
                'Cycle Life',
                'Charging Temperatur',
                'Discharging Temperature',
                'Battery size',
                'Battery weight'
              ],
              rightList: [
                '26650-3500mah',
                'Lifepo4 battery cell',
                '3500mah',
                '20 mΩ',
                '3.2V',
                '3.65 V',
                '2.0V',
                '0.5C(1750 mA)',
                '0.5C(1750 mA)',
                '10500mA',
                '17500mA',
                '28000mA',
                '1 C/100 % DOD≥2000 cycles',
                '0~60 °C',
                '-20~ 60°C',
                '26*65mm',
                'About 90g'
              ]
            },
            {
              name: '26700-4000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10009.jpg',
              id: '10009',
              leftList: [
                'Nominal capacity',
                'Nominal voltage',
                'Charging Voltage',
                'Charging Method',
                'Standard Charge Current',
                'Standard Discharge Current',
                'Discharge Cut-off Voltage',
                'AC internal resistance',
                'Energy Density',
                'Rechargeable time',
                'Working temperature (charging)',
                'Working temperature (discharge)',
                'Cell Dimension',
                'Weight'
              ],
              rightList: [
                '4000mAh (Discharge the cell from 3.65V to 2.0V by 0.5C current)',
                '3.2V',
                '3.65 ± 0.05 V',
                'CC-CV (2000mA, 3.65V, 200mA cut-off)',
                '0.5 C(2000 mA)',
                '0.5 C(2000 mA)',
                '2.0 ± 0.05 V',
                '＜20 mΩ',
                '＞135Wh/kg',
                'up to 2000 times',
                '0 ~ 50 ℃',
                '-10 ~ 60℃',
                'Height: 71 mm（±0.20 mm） Diameter:26.4 mm（±0.20 mm）',
                'About 93g'
              ]
            },
            {
              name: '32700-6000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10010.jpg',
              id: '10010',
              leftList: [
                'Capacity',
                'Voltage',
                'Max discharge current',
                'Discharge current',
                'Internal Resistance',
                'Cycle times',
                'Charging Temperature',
                'Discharging temperature',
                'Storage temperature',
                'Size'
              ],
              rightList: [
                'Max 6000mah',
                '3.2V (limit voltage:2.0-3.65V)',
                '5C',
                '3C',
                'less than 8 mohms',
                'about 3000 times',
                '0~45Centigrade',
                '-20~60Centigrade',
                '-20~45Centigrade',
                '32*70mm'
              ]
            },
            {
              name: '18650-1200mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10011.jpg',
              id: '10011',
              leftList: [
                'Model',
                'Type',
                'Nominal capacity',
                'Nominal voltage',
                'Limit charging voltage',
                'Working voltage',
                'Internal resistance',
                'Rechargeable time',
                'Rechargeable battery',
                'Origin',
                'Dimensions',
                'Weight'
              ],
              rightList: [
              'IRC18650-12R',
              'Lithium Ion 18650',
              '1200mah',
              '3.7V',
              '4.2v',
              '2.75-4.2V',
              '≤38mΩ',
              'up to 500 times',
              'yes',
              'Made in China',
              '65.1 mm x 18.4 mm (diameter)',
              '35 g ± 1 g'
              ]
            },
            {
              name: '18650-2000mAh',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_10012.jpg',
              id: '10012',
              leftList: [
                'Cell model',
                'Nominal capacity',
                'Nominal voltage',
                'Working voltage',
                'Load limit voltage',
                'Internal resistance',
                'Size',
                'Weight'
              ],
              rightList: [
                'IRC18650-20R',
                '2000mAh',
                '3.7V',
                '2.75 ~4.2V',
                '4.2V',
                '≤38 milliohms',
                '65.1mm*18.4mm (diameter)',
                '42g±1g'
              ]
            },
          ]
        ],
      },
      productTwo: {
        total: 30,
        list: [
          [
            {
              name: '300W UPS',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20001.jpg',
              id: '20001',
              leftList: [
                'Model',
                'Input voltage',
                'Charging voltage',
                'Output voltage',
                'AC waveform',
                'Operating temperatue',
                'Size',
                'Weight',
              ],
              rightList: [
                '50Ah500Wh',
                '220V',
                '220V',
                '220V',
                'Sine wave current',
                '-20 ~ 40℃',
                '120*132*192mm',
                'About 3.3Kg',
              ]
            },
            {
              name: '12V25AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20002.jpg',
              id: '20002',
              leftList: [
                'Nominal voltage',
                'Nominal capacity',
                'Output voltage',
                'Weight',
                'Continuous operating current',
                'Maximum instantaneous current',
              ],
              rightList: [
                '12.8V',
                '25000mAh (25AH)',
                '14.6~10.0V',
                'about 2.7kg',
                '25A',
                '100A（1-2S）',
              ]
            },
            {
              name: '12V lead-acid battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20003.jpg',
              id: '20003',
              leftList: [
                'Nominal voltage',
                'Nominal capacity',
                'Battery size',
                'Battery weight',
                'Battery life',
                'Input charging voltage',
                'Input charging current',
              ],
              rightList: [
                '11.1V',
                '7Ah/9Ah/12Ah/15Ah/21Ah',
                '64*98*150mm',
                '500g - 1300g',
                'over 2000 times with 80% DOD',
                '12.6v',
                'standard 1A, fast 5A',
              ]
            },
            {
              name: '12V waterproof battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20004.jpg',
              id: '20004',
              leftList: [
                'Mode',
                'Input voltage',
                'Output voltage',
                'Battery capacity',
                'Battery weight',
                'Continuous operating current'
              ],
              rightList: [
                '12V 20Ah (18650)',
                '12.6V',
                '9.6-12.6V',
                '20Ah',
                'About 1.73kg/1.485kg/1.78kg',
                '20Ah'
              ]
            },
            {
              name: '12V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20005.jpg',
              id: '20005',
              leftList: [
                'Nominal voltage',
                'Nominal capacity',
                'Output voltage',
                'Battery weight',
                'Continuous working current',
                'Maximum instantaneous current',
                'Size',
                'Type',
              ],
              rightList: [
                '11.1V',
                '30000mAh（30Ah）',
                '12.6~9.6V',
                '2200g',
                '0~10A',
                '10A',
                '230mm*55mm*300mm',
                '锂离子',
              ]
            },
            {
              name: '12V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20006.jpg',
              id: '20006',
              leftList: [
                'Nominal voltage',
                'Nominal capacity',
                'Output voltage',
                'Battery weight',
                'Continuous working current',
                'Maximum instantaneous current',
                'Size',
                'Type',
              ],
              rightList: [
                '11.1V',
                '20000mAh（20AH）',
                '12.6~9.6V',
                '1460g',
                '15~20A',
                '30A',
                '120mm*75mm*200mm',
                'Lithium-ion',
              ]
            },
          ],
          [
            {
              name: '12V10AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20007.jpg',
              id: '20007',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Input voltage',
                'Output voltage',
                'Battery weight',
                'Continuous working current',
                'Maximum instantaneous current',
                'Size',
                'Type',
              ],
              rightList: [
                '',
                '11.1V',
                '10400mAh（10.4AH）',
                '12.6V',
                '12.6~9.6V',
                '780g',
                '0~8A',
                '10A',
                '90mm*60mm*156mm',
                'Lithium-ion',
              ]
            },
            {
              name: 'Lithium battery for sprayers',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20008.jpg',
              id: '20008',
              leftList: [
                'Model',
                'Weight',
                'Size',
                'Battery life',
                'Nominal voltage',
                'Battery weight',
                'Cycle life',
                'Input charging voltage',
                'Input charging current',
                'Continuous discharge current',
                'Charging temperature',
                'Discharge temperature',
                'Storage temperature'
              ],
              rightList: [
                '12V6Ah / 8Ah / 10Ah / 12Ah / 15Ah / 20Ah',
                '≈565g ≈705g ≈820g ≈950g ≈1085g ≈1300g',
                '96*65*150mm',
                '2.5h ~ 14h',
                '11.1v',
                'about 500g - 1300g',
                'more than 2000 times, DOD is 80%',
                '12.6v',
                'standard 1A, fast 5A',
                '8A, about 100W',
                '0~45℃',
                '-20~60℃',
                '-20~45℃'
              ]
            },
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20009.jpg',
              id: '20009',
              leftList: [
                'Cell model',
                'Nominal voltage',
                'Nominal capacity',
                'Maximum charging current',
                'Instantaneous discharge current',
                'Continuous discharge current',
                'Service life',
                'Service life',
                'Weight',
                'Size',
                'Working voltage range',
                'Overcharge protection voltage',
                'Over-discharge protection voltage',
                'Battery temperature protection'
              ],
              rightList: [
                '3.2v 100ah lifepo4',
                '12.8V',
                '200Ah',
                '50A',
                '300A(5S)',
                '100A',
                'greater than: 3000 cycles',
                '-20～45℃',
                'about 18.1kg',
                '450*165*145mm/customized',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '60℃+mn5℃'
              ]
            },
            {
              name: '12V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20010.jpg',
              id: '20010',
              leftList: [
                'Battery model',
                'Nominal capacity',
                'Nominal voltage',
                'Instantaneous discharge current',
                'Maximum charging current',
                'Working voltage range',
                'Overcharge protection voltage',
                'Over-discharge protection voltage',
                'Continuous discharge current',
                'Cycle life',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V100Ah (lithium iron phosphate)',
                '100Ah',
                '12.8V',
                '150A(3S)',
                '20A',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '100A',
                '≥3000 times',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '270*175*180mm / customized',
                '≈9.1Kg'
              ]
            },
            {
              name: '12V100AH suitcase',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20011.jpg',
              id: '20011',
              leftList: [
                'Battery Model',
                'Battery capacity',
                'Nominal voltage',
                'Working voltage',
                'Overcharge protection voltage',
                'Over-discharge protection voltage',
                'Continuous charging current',
                'Continuous discharge current',
                'Cut-off discharge current',
                'Cycle life',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V100Ah Suitcase (Lithium Iron Phosphate)',
                '100Ah',
                '12.8V',
                '10~ 14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '100A',
                '100A',
                '300A（3 seconds）',
                '≥4000次',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '280*240*155mm / customized',
                '≈9.2Kg'
              ]
            },
            {
              name: '12V300AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20012.jpg',
              id: '20012',
              leftList: [
                'Battery model',
                'Nominal capacity',
                'Nominal voltage',
                'Maximum charging current',
                'Continuous discharge current',
                'Instantaneous discharge current',
                'Working voltage range',
                'Overcharge protection voltage',
                'Over-discharge protection voltage',
                'Battery temperature protection',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12v 300Ah',
                '300Ah',
                '12.8V',
                '200A',
                '200A',
                '600A(5S)',
                '10.0V-14.6V',
                '14.6V+0.05V',
                '10.0V+0.05V',
                '70℃+mn5℃',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '522*240*218mm / customized',
                '25.5±0.5kg'
              ]
            },
          ],
          [
            {
              name: '12V50AH sheet metal',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20013.jpg',
              id: '20013',
              leftList: [
                'Battery Model',
                'Battery capacity',
                'Nominal voltage',
                'Charging voltage',
                'Cut-off voltage',
                'Standard charging current',
                'Continuous charging current',
                'Continuous discharge current',
                'Cut-off discharge current',
                'Cycle life',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V50Ah (lithium iron phosphate)',
                '50Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '10A',
                '50A',
                '70A',
                '250A 1-2seconds',
                '≥ 4000 times',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '340*255*85mm(with rod)',
                '≈6.6Kg'
              ]
            },
            {
              name: '12V100AH sheet metal',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20014.jpg',
              id: '20014',
              leftList: [
                'Battery Model',
                'Battery capacity',
                'Nominal voltage',
                'Charging voltage',
                'Cut-off voltage',
                'Standard charging current',
                'Continuous charging current',
                'Continuous discharge current',
                'Cut-off discharge current',
                'Cycle life',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V100Ah（lithium iron phosphate）',
                '12V100Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '20A',
                '100A',
                '100A',
                '500A（1~2seconds）',
                '≥ 4000 times',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '390*260*80mm（with rod）',
                '≈11Kg'
              ]
            },
            {
              name: '12V 120AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20015.jpg',
              id: '20015',
              leftList: [
                'Battery model',
                'Battery capacity',
                'Nominal voltage',
                'Charging voltage',
                'Cut-off voltage',
                'Standard charging current',
                'Continuous charging current',
                'Continuous discharge current',
                'Cut-off discharge current',
                'Cycle life',
                'Charging temperature',
                'Exhaust temperature',
                'Storage temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V120Ah (lithium iron phosphate)',
                '120Ah',
                '12.8V',
                '14.4 ~ 14.6V',
                '10V',
                '20A',
                '100A',
                '120A',
                '500A（1~2seconds）',
                '≥ 4000 times',
                '0 ~ 45℃',
                '-20 ~ 60℃',
                '-20 ~ 45℃',
                '400*250*142mm',
                '≈15.6Kg'
              ]
            },
            {
              name: '12V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20016.jpg',
              id: '20016',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Discharge cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '12V100Ah',
                '100A',
                '200A',
                '14.6V',
                '10.5V',
                '215*170*325mm',
                '≈10Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '12V150AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20017.jpg',
              id: '20017',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '12V150Ah',
                '100A',
                '200A',
                '14.6V',
                '10.5V',
                '215*170*325mm',
                '≈15Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20018.jpg',
              id: '20018',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '12V200Ah',
                '200A',
                '400A',
                '14.6V',
                '10.5V',
                '165*450*240mm',
                '≈38Kg',
                'lithium iron phosphate',
              ]
            },
          ],
          [
            {
              name: '12V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20019.jpg',
              id: '20019',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '12V300AH',
                '300A',
                '600A',
                '14.6V',
                '10.5V',
                '235*485*220mm',
                '≈28Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '12V400Ah',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20020.jpg',
              id: '20020',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '12V300AH',
                '300A',
                '600A',
                '14.6V',
                '10.5V',
                '265*490*220mm',
                '≈40Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '12V400Ah',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20021.jpg',
              id: '20021',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '24V100AH',
                '100A',
                '200A',
                '29.2V',
                '21V',
                '165*450*240mm',
                '≈19Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '24V200AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20022.jpg',
              id: '20022',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '24V200Ah',
                '200A',
                '400A',
                '29.2V',
                '21V',
                '265*490*220mm',
                '≈40Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '24V300AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20023.jpg',
              id: '20023',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '24V300Ah',
                '300A',
                '600A',
                '29.2V',
                '21V',
                '255*560*285mm',
                '≈72Kg',
                'lithium iron phosphate',
              ]
            },
            {
              name: '24V400AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20024.jpg',
              id: '20024',
              leftList: [
                'Model',
                'Standard current',
                'Instantaneous current',
                'Charging cut-off voltage',
                'Charging cut-off voltage',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '24V400Ah',
                '300A',
                '600A',
                '29.2V',
                '21V',
                '255*680*320mm',
                '≈88Kg',
                'lithium iron phosphate',
              ]
            },
          ],
          [
            {
              name: '12V outdoor multi-functional lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20025.jpg',
              id: '20025',
              leftList: [
                'Model',
                'Input voltage',
                'Output voltage',
                'Nominal voltage',
                'Continuous output current',
                'Working temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V40Ah',
                '12.6V',
                '12.6V ~ 9.6V',
                '12V',
                '40A',
                '-20℃ ~ 40℃',
                '230*85*150mm',
                '≈3Kg',
              ]
            },
            {
              name: '12V outdoor multi-functional lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20026.jpg',
              id: '20026',
              leftList: [
                'Model',
                'Battery type',
                'Nominal voltage',
                'Limit voltage',
                'Termination voltage',
                'Charging current',
                'Continuous current',
                'Working temperature',
                'Size'
              ],
              rightList: [
                '12V40Ah / 70Ah',
                'lithium polymer battery',
                '12.6V',
                '12.6V',
                '9.5V',
                '5 ~ 8Aflash charge',
                '50A',
                '-20℃ ~ 60℃',
                '245*60*350mm 345*80*350mm'
              ]
            },
            {
              name: '12V outdoor multi-functional lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20027.jpg',
              id: '20027',
              leftList: [
                'Charging mode',
                'Instantaneous discharge current',
                'Continuous discharge current',
                'Charging current',
                'Protection voltage',
                'Protection voltage',
                'Working temperature',
                'Cycle life',
                'Size',
                'Weight'
              ],
              rightList: [
                'constant current and constant voltage',
                '200A',
                '150A',
                '12.6V 5A',
                '210 ~ 220V',
                '10.1V',
                '-20℃ ~ 60℃',
                'more than 2000 times',
                '310*238*51mm',
                'about 4Kg'
              ]
            },
            {
              name: '300W UPS',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20028.jpg',
              id: '20028',
              leftList: [
                'Model',
                'Input voltage',
                'Charging voltage',
                'Output voltage',
                'AC waveform',
                'Operating temperature',
                'Size',
                'Weight'
              ],
              rightList: [
                '12V36Ah',
                '220V',
                '220V',
                '220V/12V/5V',
                'Sine wave current',
                '-20 ~ 40℃',
                '110*280*135mm',
                'About 3.4Kg'
              ]
            },
            {
              name: '12V lead-acid enclosure collection',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20029.jpg',
              id: '20029',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '12V package',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_20030.jpg',
              id: '20030',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature',
              ],
              rightList: [
                '',
                '',
                '',
                '',
                '',
              ]
            },
          ]
        ],
      },
      productThree: {
        total: 31,
        list: [
          [
            {
              name: 'Whitebait Haiba Electric Vehicle Lithium Battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30001.jpg',
              id: '30001',
              leftList: [
                'Cell model',
                'Nominal voltage',
                'Nominal capacity',
                'Size',
                'Weight',
                'Discharge time',
                'Cycle life',
              ],
              rightList: [
                '18650 Li Ion Cell',
                '48V',
                '10Ah/12Ah/15Ah/20Ah/25Ah/30Ah',
                '370*135*85mm',
                '4.5kg-7kg',
                '6-8hours',
                '1000 times',
              ]
            },
            {
              name: 'Electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30002.jpg',
              id: '30002',
              leftList: [
                'Standard',
                'Nominal capacity',
                'Size',
                'Weight',
              ],
              rightList: [
                '48V',
                '10 ~ 18AH',
                '≥3Kg',
                '370*100*160mm',
              ]
            },
            {
              name: 'Crossbeam type electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30003.jpg',
              id: '30003',
              leftList: [
                'Nominal Voltage',
                'Nominal Capacity',
                'Composed Type',
                'Cells',
                'BMS',
                'Fit Motor Power',
                'Charge Current',
                'Charge Cut-off Voltage',
                'Discharge Cut-off Voltage',
                'Discharge Temperature',
                'Charge Temperature',
                'Cycle life',
                'Dimensions',
                'Battery Weight'
              ],
              rightList: [
                '48V',
                '10Ah,12.5Ah',
                '13Series 4Paralles 13S4P/13Series 5Paralles 13S5P',
                'Powerful 18650 3.7V 2500mAh',
                '30Amp continuous 60±10Amp maximum',
                '48V, 500W,750W, 1000W',
                '2-5A',
                '54.6V',
                '39V',
                '-20 to 65°C',
                '0 to 45°C',
                '≥80% capacity after 800 cycles.',
                '430*100*42mm',
                '3kg'
              ]
            },
            {
              name: 'Hailong Electric Vehicle Lithium Battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30004.jpg',
              id: '30004',
              leftList: [
                'Nominal Voltage',
                'Nominal Capacity',
                'Composed Type',
                'Cells',
                'BMS',
                'Fit Motor Power',
                'Charge Current',
                'Charge Cut-off Voltage',
                'Discharge Cut-off Voltage',
                'Cycle life',
                'Discharge Temperature',
                'Charge Temperature',
                'Dimensions',
                'Battery Weight'
              ],
              rightList: [
                '36V',
                '10Ah',
                '10Series 5Paralles 10S5P',
                'Powerful 18650 3.7V 2000mAh',
                '20 Amp continuous 50±10Amp maximum',
                '36V 250W 350W 500W',
                '2-5A',
                '42V',
                '30V',
                '≥80% capacity after 800 cycles.',
                '-20 to 65°C',
                '0 to 45°C',
                '90*111*367mm',
                '3.7kg'
              ]
            },
            {
              name: 'Kettle type electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30005.jpg',
              id: '30005',
              leftList: [
                'Nominal Voltage',
                'Nominal Capacity',
                'Standard Charging Current',
                'Charge Voltage',
                'Max.chargecurrent',
                'Standard Discharge Current',
                'Max.Constant Discharge Current',
                'Max.Pulse Charge Current',
                'DischargingCut-offVoltage',
                'Operating Charge Temperature',
                'Operating Discharge Temperature',
                'StorageTemperature'
              ],
              rightList: [
                '48V',
                '10Ah/12Ah/15Ah',
                ' 2,0',
                '54,6 V',
                '5A',
                '15000 mA',
                '25A',
                '60A/75A',
                '39V',
                '0～45℃',
                '-20～60℃',
                '-10～45℃'
              ]
            },
            {
              name: 'Minicab battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30006.jpg',
              id: '30006',
              leftList: [
                'Model',
                'Nominal voltage',
                'Working current',
                'Cycle life',
                'Charging time',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '48V16Ah',
                '48V',
                '16Ah',
                '600 times 60%',
                '6 ~ 10h',
                '160*150*183mm',
                '≈5.5Kg',
                '18650 ternary lithium'
              ]
            },
          ],
          [
            {
              name: '48V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30007.jpg',
              id: '30007',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '48V32Ah',
                '48V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '315*190*105mm',
                '≈8.7Kg',
                'ternary lithium'
              ]
            },
            {
              name: '60V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30008.jpg',
              id: '30008',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '60V32Ah',
                '60V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '270*160*173mm',
                '≈11Kg',
                'ternary lithium'
              ]
            },
            {
              name: '72V32AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30009.jpg',
              id: '30009',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '72V32Ah',
                '72V',
                '32Ah',
                '32Ah',
                '0℃ ~ 45℃',
                '290*160*170mm',
                '≈12.6Kg',
                'ternary lithium'
              ]
            },
            {
              name: '72V60AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30010.jpg',
              id: '30010',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '72V60Ah',
                '72V',
                '60Ah',
                '60Ah',
                '-20℃ ~ 45℃',
                '170*230*375mm',
                '≈24.5Kg',
                'ternary lithium'
              ]
            },
            {
              name: '72V70AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30011.jpg',
              id: '30011',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '72V70Ah',
                '72V',
                '70Ah',
                '70Ah',
                '-20℃ ~ 45℃',
                '170*480*235mm',
                '≈31.5Kg',
                'ternary lithium'
              ]
            },
            {
              name: '60V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30012.jpg',
              id: '30012',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '60V100Ah',
                '60V',
                '100Ah',
                '100Ah',
                '-20℃ ~ 45℃',
                '170*540*235mm',
                '≈36.5Kg',
                'ternary lithium'
              ]
            },
          ],
          [
            {
              name: '60V60AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30013.jpg',
              id: '30013',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '60V60Ah',
                '60V',
                '60Ah',
                '60Ah',
                '-20℃ ~ 45℃',
                '170*230*330',
                '≈21.5Kg',
                'ternary lithium'
              ]
            },
            {
              name: '60V50A',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30014.jpg',
              id: '30014',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '60V50Ah',
                '60V',
                '50Ah',
                '50Ah',
                '0℃ ~ 45℃',
                '300*230*170mm',
                '≈19.5Kg',
                'ternary lithium'
              ]
            },
            {
              name: '60V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30015.jpg',
              id: '30015',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '60V40Ah',
                '60V',
                '40Ah',
                '40Ah',
                '0℃ ~ 45℃',
                '300*230*170mm',
                '≈17.8Kg',
                'ternary lithium'
              ]
            },
            {
              name: '72V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30016.jpg',
              id: '30016',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '72V40Ah',
                '72V',
                '40Ah',
                '40Ah',
                '0℃ ~ 45℃',
                '340*230*170mm',
                '≈20Kg',
                'ternary lithium'
              ]
            },
            {
              name: '72V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30017.jpg',
              id: '30017',
              leftList: [
                'Model',
                'Nominal voltage',
                'Nominal capacity',
                'Working current',
                'Working temperature',
                'Size',
                'Weight',
                'Material system'
              ],
              rightList: [
                '72V100Ah',
                '72V',
                '100Ah',
                '100Ah',
                '-20℃ ~ 45℃',
                '170*600*235mm',
                '≈42.5Kg',
                'ternary lithium'
              ]
            },
            {
              name: 'Electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30018.jpg',
              id: '30018',
              leftList: [
                'Standard',
                'Nominal capacity',
                'Size',
                'Weight',
              ],
              rightList: [
                '48V',
                '10 ~ 20AH',
                '400*165*95mm',
                '≥3Kg',
              ]
            },
          ],
          [
            {
              name: 'Xiaoliying 60V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30019.jpg',
              id: '30019',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '72V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30020.jpg',
              id: '30020',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '48V40AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30021.jpg',
              id: '30021',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: 'Hailong No. 2 electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30022.jpg',
              id: '30022',
              leftList: [
                'Nominal Voltage',
                'Nominal Capacity',
                'Composed Type',
                'Cells',
                'BMS',
                'Power',
                'Charge Current',
                'Charge Cut-off Voltage',
                'Discharge Cut-off Voltage',
                'Discharge Temperature',
                'Charge Temperature',
                'Dimensions',
                'Battery Weight'
              ],
              rightList: [
                '36V',
                '10Ah',
                '10S5P',
                '18650 3.7V 2000mah',
                '20 Amp continuous 50±10Amp maximum',
                '360W',
                '2-5A',
                '42V',
                '30V',
                '-20~ 65°C',
                '0 ~ 45°C',
                '90*111*367mm',
                'About 3kg'
              ]
            },
            {
              name: '72V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30023.jpg',
              id: '30023',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: 'Simple package electric vehicle lithium battery',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30024.jpg',
              id: '30024',
              leftList: [
              ],
              rightList: [
              ]
            },
          ],
          [
            {
              name: '60V100AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30025.jpg',
              id: '30025',
              leftList: [
              ],
              rightList: [
              ]
            },
            {
              name: '60V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30026.jpg',
              id: '30026',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '60V20AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30027.jpg',
              id: '30027',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '48V30AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30028.jpg',
              id: '30028',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
            {
              name: '48V12AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30029.jpg',
              id: '30029',
              leftList: [
                'Standard Voltage',
                'Standard capacity',
                'Discharge protection voltage',
                'Normal working current',
                'Peak working current',
                'Operating temperature',
                'Charging method'
              ],
              rightList: [
                '48V',
                '8 ~ 15AH',
                '38.5V',
                '15A',
                '20A',
                '0℃ ~ 45℃',
                'Constant current and constant voltage'
              ]
            },
            {
              name: '48V10AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30030.jpg',
              id: '30030',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
          ],
          [
            {
              name: '48V9AH',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_30031.jpg',
              id: '30031',
              leftList: [
                'Cell Model',
                'Nominal voltage',
                'Nominal capacity',
                'Charging temperature',
                'Discharge temperature'
              ],
              rightList: [
                '',
                '',
                '',
                '',
                ''
              ]
            },
          ]
        ],
      },
      productFour: {
        total: 1,
        list: [
          [
            {
              name: 'Electric vehicle lithium battery customization',
              img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/pro_40001.jpg',
              id: '100001',
              leftList: [
                'Cell model',

                'Nominal voltage',
                
                'Nominal capacity',
                
                "Charging temperature",
                
                'Discharge temperature',
              ],
              rightList: [
                '18650',
                '48V',
                '10,15,20,25Ah',
                '5~45',
                '-20~60',
              ]
            },
          ],
        ],
      }
    },
  }
  export default info