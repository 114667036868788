import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

const state = {
  deviceType: "1", // 1电脑 2手机
  languageStatus: "1", // 1中文 2英文
  token: "",
};
const mutations = {
  // 设置设备类型
  setDeviceType(state, type) {
    console.log("设备类型:" + type);
    state.deviceType = type;
  },
  // 设置语言类型
  setLanguageStatus(state, langType) {
    state.languageStatus = langType;
  },
};
export default new Vuex.Store({
  state,
  mutations,
});
