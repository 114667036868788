<template>
  <div 
  class="cardArea"
  :style="{'width': cardWidth + 'rem'}"
  @click="toDetail"
  >
    <img :src="dataObj.img" alt="">
    <div class="shopName">{{dataObj.name}}</div>
    <div class="showMore">探索更多</div>
  </div>
</template>

<script>
export default {
  props: {
    dataObj: Object,
    cardWidth: Number
  },
  methods: {
    toDetail() {
      this.$router.push({
        path: this.dataObj.url,
        query: {
          id: this.dataObj.id
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .cardArea {
    height: 4.14rem;
    margin-top: .2rem;
    background: #FAFAFA;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        width: 80%;
        height: 2.6rem;
    }
    .shopName {
        font-weight: bold;
        font-size: .32rem;
        color: #3A3C43;
    }
    .showMore {
        color: #1FCE76;
        width: max-content;
        font-size: .24rem;
        font-weight: 400;
        margin-top: .34rem;
        padding-bottom: .12rem;
        border-bottom: 1px solid #1FCE76;
    }
  }
</style>