// common模块
import Common from './common/index'
// pages模块
import Pages from './pages/index'
const en = {
  language: {
    name: '中文'
  },
  common: { // common模块
    // common模块下的base部分(页面)里面的英文
    base: Common.Base.EN,
    // common模块下的base2部分(页面)里面的英文
    base2: Common.Base2.EN
  },
  pages: {
    // pages模块下的main部分(页面)里面的英文
    main: Pages.Main.EN,
    // pages模块下的product部分(页面)里面的英文
    product: Pages.Product.EN,
    company: Pages.Company.EN,
    news: Pages.News.EN,
  }
}
export default en