<template>
  <div>
    <top-pic :name="name" :text="$t('common.base.nav_resources_social')" :subText="$t('common.base.nav_products_sub')"></top-pic>
    <div v-if="$store.state.deviceType === '1'" class="contentBox wordWrapClass">
      <div class="list">
        <div class="item wow fadeInUp" v-for="(item, index) in $t('common.base2.list2')" :key="index">
          <div class="itemTop">
            <div class="left">
              <div class="top">{{ item.title }}</div>
              <div class="bot">{{ item.content }}</div>
            </div>
            <div class="right" @click="item.bol = !item.bol">
              <!-- <img v-if="!item.bol" src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/joblisttxt.png" alt="" /> -->
              <div class="unfold" v-if="!item.bol">{{ $t('common.base2.unfold_details') }}</div>
              <div class="packUp" v-else>{{ $t('common.base2.pack_details') }}</div>
              <!-- <img v-else src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/joblisttxt1.png" alt="" /> -->
            </div>
          </div>
          <div class="itemBot" v-if="item.bol" style="">
            {{ item.detail }}
          </div>
          <!-- <div class="resume" v-if="item.bol">投递简历</div> -->
        </div>
      </div>
    </div>
    <div v-else class="phoneContent">
      <div v-for="(item, index) in $t('common.base2.list2')"
      :key="index"
      class="cardArea">
        <div class="topMsg">
          <div class="leftTop">{{ item.title }}</div>
          <div class="rightTop" @click="item.bol = !item.bol">
            <div class="unfold" v-if="!item.bol">{{ $t('common.base2.unfold_details') }}</div>
            <div class="packUp" v-else>{{ $t('common.base2.pack_details') }}</div>
          </div>
        </div>
        <div class="bottomClass">
          {{ item.content }}
        </div>
        <div class="itemBot" v-if="item.bol" style="">
            {{ item.detail }}
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopPic from "@/components/TopPic.vue";
export default {
  name: "IndexPage",
  components: {
    TopPic,
  },
  data() {
    return {
      name: "/social",
    };
  },
  methods: {
    goClass() {},
  },
};
</script>

<style scoped lang="scss">
.wordWrapClass {
  white-space: pre-wrap; 
  word-wrap: break-word
}
.list {
  padding-top: 41px;
  .resume {
    width: 125px;
    height: 38px;
    background: #19cc41;
    line-height: 38px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    margin-top: 22px;
    margin: 22px 0 30px 28px;
  }

  .itemBot {
    padding-left: 28px;
    background: #ffffff;
    font-size: 14px;
    font-weight: 400;
    color: #717171;
    line-height: 10px;
    padding-bottom: 30px;
  }

  .itemTop {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 44px 28px 38px 28px;
    background: #f9f9f9;
  }

  .item {
    background: #fff;
    margin-bottom: 25px;
    font-size: 16px;

    .left {
      .top {
        font-size: 18px;
        font-weight: 600;
        color: #262830;
        padding-bottom: 12px;
      }

      .bot {
        font-size: 14px;
        font-weight: 400;
        color: #717171;
      }
    }
    .right {
      cursor: pointer;
      .unfold,
      .packUp {
        width: 125px;
        height: 38px;
        border: 1px solid #19cc41;
        line-height: 38px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #19cc41;
      }
    }
  }
}

.phoneContent {
  padding: .4rem .4rem .24rem;
  .cardArea {
    min-height: 1.4rem;
    background: #F9F9F9;
    padding: .3rem .26rem;
    font-size: .24rem;
    margin-bottom: .2rem;
    .topMsg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftTop {
        font-weight: 800;
        font-size: .34rem;
        color: #262830;
      }
      .rightTop {
        color: #1FCE76;
        font-weight: 500;
      }
    }
    .bottomClass {
      color: #717171;
      margin-top: .10rem;
    }
    .itemBot {
      padding: .3rem .28rem;
      background: #F9F9F9;
      font-size: .28rem;
      font-weight: 400;
      color: #717171;
      // line-height: 10px;
    }
  }
}

// 兼容手机
@media screen and (max-width: 756px) {
}
</style>
