import Vue from 'vue'
import App from './App.vue'

import store from '@/store'

import '@/assets/js/setRemDev'
// import './assets/js/setRemPro'
import '@/assets/css/base.css'

import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// import 'element-ui/lib/theme-chalk/display.css';
import '@/assets/css/element-variables.scss'

import router from '@/router/index.js'

// import '@/permission' // permission control

Vue.config.productionTip = false

// 语言切换
import ZH from '@/language/zh'
import EN from '@/language/en'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
Vue.use(ElementUI);
const i18n = new VueI18n({
  locale: localStorage.getItem('languageSet') || 'zh',
  fallbackLocale: 'zh',
  messages: {
    'zh': ZH,
    'en': EN
  }
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
