<template>
  <div>
    <top-pic :name="name"></top-pic>
    <div class="detail-box" v-if="product && product.id">
      <div class="detail-box-l">
          <div
            id="smallPic"
            @mouseover="handlerMouseOver"
            @mouseleave="handlerMouseLeave"
            @mousemove="handlerMouseMove">
          <img :src="product.cover"/>
          <!--蒙版元素-跟着鼠标移动的元素-->
          <div id="mask" v-show="show"></div>
        </div>
        <!--大图框-->
        <div id="bigPic" v-show="show">
          <img :src="product.cover" id="bigImg">
        </div>
      </div>
      <div class="detail-box-r">
        <div class="detail-box-r-t wow fadeInUp">{{ product.name }}</div>
        <div class="detail-box-r-item wow fadeInUp" v-for="(item, ind) in product.norms" :key="ind">
          <div>{{ item.normsName }}:</div>
          <span>{{ item.normsContent }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import TopPic from '@/components/TopPic.vue'
import { getProductById } from "@/utils/api";
export default {
  name: "productDetail",
  components: {
    TopPic
  },
  data() {
    return {
      name: '/productDetail',
      productId: '',
      show: false,
      product: {}
    }
  },
  created() {
    // 顶部图片和当前产品id的设置
    if (this.$route.query && this.$route.query.id && this.$route.query.name) {
      this.name = this.$route.query.name
      this.productId = this.$route.query.id
    }
    getProductById({productId:this.productId}).then(res => {
      this.product = res.data
    })
  },
  methods: {
    //鼠标移入事件
    handlerMouseOver() {
      this.show = true;
    },
    //鼠标移出事件
    handlerMouseLeave() {
      this.show = false;
    },
    //鼠标移动事件
    handlerMouseMove(data) {
      const smallPic = document.getElementById("smallPic");
      const bigPic = document.getElementById("bigPic");
      const bigImg = document.getElementById("bigImg");
      const mask = document.getElementById("mask");
      //蒙版边界设置
      let left = data.clientX - smallPic.getBoundingClientRect().left - mask.offsetWidth / 2;
      let top = data.clientY - smallPic.getBoundingClientRect().top - mask.offsetHeight / 2;
      //边界判断
      if (left < 0) {
        left = 0;
      } else if (left > smallPic.clientWidth - mask.offsetWidth) {
        left = smallPic.clientWidth - mask.offsetWidth;
      }
      if (top < -1) {
        top = -1;
      } else if (top > smallPic.clientHeight - mask.offsetHeight) {
        top = smallPic.clientHeight - mask.offsetHeight;
      }
      mask.style.left = left + "px";
      mask.style.top = top + "px";
      let scale = (smallPic.clientWidth - mask.offsetWidth) / (bigImg.offsetWidth - bigPic.clientWidth);
      bigImg.style.left = -left / scale + "px";
      bigImg.style.top = -top / scale + "px";
    },
  }
}
</script>
  
<style scoped lang="scss">
.detail-box {
  display: flex;
  margin: .4rem auto;
  width: 15rem;
  height: auto;
  .detail-box-l {
    width: 6rem;
    height: 6rem;
    border: 3px solid #F2F2F2;
    border-radius: 4px;
    position: relative;
    box-sizing: content-box;
    #smallPic {
      width: 6rem;
      height: 6rem;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
      #mask {
        width: 3rem;
        height: 3rem;
        background: rgba(255, 255, 255, .5);
        border: 1px solid #ddd;
        position: absolute;
        left: 0rem;
        top: 0rem;
      }
    }
    #bigPic {
      width: 6rem;
      height: 6rem;
      border: 1px solid #ddd;
      left: 6rem;
      top: 0rem;
      position: absolute;
      overflow: hidden;
      #bigImg {
        width: 12rem;
        height: 12rem;
        position: absolute;
        left: 0rem;
        top: 0rem;
      }
    }
  }
  .detail-box-r {
    flex: 1;
    .detail-box-r-t {
      font-size: .26rem;
      color: #000;
      margin-left: .2rem;
      line-height: .6rem;
      border-bottom: 1px solid #CFD2CF;
    }
    .detail-box-r-item {
      padding-left: .2rem;
      display: flex;
      font-size: .2rem;
      color: #666;
      height: .4rem;
      align-items: center;
      span {
        margin-left: .15rem;
      }
    }
  }
}

// 兼容手机
@media screen and (max-width: 756px) {
  .detail-box {
    display: flex;
    flex-direction: column;
    margin: .4rem auto;
    padding: 0 .3rem;
    width: 100%;
    height: auto;
    .detail-box-l {
      width: 6rem;
      height: 6rem;
      border: 3px solid #F2F2F2;
      border-radius: 4px;
      position: relative;
      box-sizing: content-box;
      margin: auto;
      #smallPic {
        width: 6rem;
        height: 6rem;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
        #mask {
          width: 3rem;
          height: 3rem;
          background: rgba(255, 255, 255, .5);
          border: 1px solid #ddd;
          position: absolute;
          left: 0rem;
          top: 0rem;
        }
      }
      #bigPic {
        width: 6rem;
        height: 6rem;
        border: 1px solid #ddd;
        left: 0;
        top: 6rem;
        position: absolute;
        overflow: hidden;
        #bigImg {
          width: 12rem;
          height: 12rem;
          position: absolute;
          left: 0rem;
          top: 0rem;
        }
      }
    }
    .detail-box-r {
      .detail-box-r-t {
        font-size: .26rem;
        color: #000;
        line-height: 1.3rem;
        border-bottom: 1px solid #CFD2CF;
      }
      .detail-box-r-item {
        display: flex;
        font-size: .26rem;
        color: #666;
        height: .6rem;
        align-items: center;
        span {
          margin-left: .15rem;
        }
      }
    }
  }
}
</style>