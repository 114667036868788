<template>
  <div class="jt-foot-container wow fadeInUp">
    <!-- <el-collapse v-model="activeNames" @change="handleChange" accordion>
      <el-collapse-item v-for="(item, ind) in navList" :key="ind" :title="item.name" :name="item.id">
        <div v-for="(items, index) in item.children" :key="index" class="jt-nav-son-item" @click="goPages(items)">
          <div class="jt-nav-son-item-t">{{ items.name }}</div>
        </div>
      </el-collapse-item>
    </el-collapse> -->
    <!-- <div class="jt-footmid">
      <div class="jt-footmid-t1">{{ $t('common.base.foot_compay') }}: </div>
      <div class="jt-footmid-t2">
        <div v-for="(item, ind) in listCompany" :key="ind">{{ item.name }}</div>
      </div>
    </div> -->
    <div class="telArea">TEL：0769-8282-7524</div>
    <div>地址:广东省东莞市常平镇桥沥南门路40号4栋101室</div>
    <div class="codeArea">
      <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/code2.jpg" alt="">
    </div>
    <div class="allCompony">
      <div>旗下公司：</div>
      <ul class="rightName">
        <li>湖南盛利高新能源科技有限公司</li>
        <li>湖南盛武新能源科技有限公司</li>
        <li>湖南省盛永能源科技有限公司</li>
        <li>湖南帅凌科技有限公司</li>
        <li>湖南盛远能源科技有限公司</li>
      </ul>
    </div>
    <div class="jt-footbtm">© CopyRight 2023 {{ $t('common.base.shengli') }} All Rights Reserved</div>
    <div class="jt-footbtm">广东盛利高新能源科技有限公司</div>
    <div class="jt-footbtm">备案号： 粤ICP备2023056569号-1</div>
  </div>
</template>

<script>
import { categoryList } from "@/utils/api";
export default {
  name: "PcPage",
  props: {
    category: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      activeNames: [],
      categoryLists: [],
    };
  },
  computed: {
    navList() {
      return [
        {
          // name: '关于盛利',
          name: this.$t('common.base.nav_aboutus'),
          url: '/introduction',
          children: [
            {
              name: this.$t('common.base.nav_aboutus_intoduction'),
              url: '/introduction',
            },
            {
              name: this.$t('common.base.nav_aboutus_course'),
              url: '/course',
            },
            {
              name: this.$t('common.base.nav_aboutus_certificate'),
              url: '/certificate',
            },
            {
              name: this.$t('common.base.nav_aboutus_culture'),
              url: '/culture',
            }
          ]
        },
        {
          name: this.$t('common.base.nav_products'),
          url: '/product?type=0',
          children: this.category
        },
        {
          name: this.$t('common.base.nav_news'),
          url: '/news?type=0',
          children: [
            {
              name: this.$t('common.base.nav_news_companynews'),
              url: '/news?type=1',
            },
            {
              name: this.$t('common.base.nav_news_technical'),
              url: '/news?type=2',
            },
            {
              name: this.$t('common.base.nav_news_problem'),
              url: '/news?type=3',
            }
          ]
        },
        {
          name: this.$t('common.base.nav_resources'),
          url: '/source',
          children: [
            {
              name: this.$t('common.base.nav_resources_talent'),
              url: '/source',
            },
            {
              name: this.$t('common.base.nav_resources_social'),
              url: '/social',
            },
            {
              name: this.$t('common.base.nav_resources_campus'),
              url: '/campus',
            },
            {
              name: this.$t('common.base.nav_resources_staff'),
              url: '/man',
            }
          ]
        },
        {
          name: this.$t('common.base.nav_contact'),
          url: '/contact',
          children: [
            {
              name: this.$t('common.base.nav_contact_contact'),
              url: '/contact',
            },
            {
              name: this.$t('common.base.nav_contact_message'),
              url: '/message',
            },
          ]
        },
      ]
    },
    listCompany() {
      return [
        {
          name: this.$t('common.base.foot_compay_name1')
        },
        {
          name: this.$t('common.base.foot_compay_name2')
        },
        {
          name: this.$t('common.base.foot_compay_name3')
        },
        {
          name: this.$t('common.base.foot_compay_name4')
        },
        {
          name: this.$t('common.base.foot_compay_name5')
        },
        // {
        //   name: this.$t('common.base.foot_compay_name6')
        // },
        // {
        //   name: this.$t('common.base.foot_compay_name7')
        // },
        // {
        //   name: this.$t('common.base.foot_compay_name8')
        // },
      ]
    },
  },
  mounted() {
    // this.getCategoryList()
  },
  methods: {
    getCategoryList() {
      this.categoryLists = []
      categoryList({ language: this.$i18n.locale }).then(res => {
        if (res.code === "000000") {
          res.data.forEach(item => {
            item.child.forEach(it => {
              this.categoryLists.push({
                name: it.productTypeName,
                id: it.id,
                sort: it.sort
              })
            })
          });
          this.categoryLists.forEach((item, index) => {
            item.url = `/product?type=${index + 1}`
          })
          this.categoryLists.sort((a, b) => a.sort - b.sort)
          this.categoryLists.pop()
          console.log(this.categoryLists, 'categoryLists');
        }
      })
    },
    handleChange() { },
    goPages(item) {
      this.$router.push({
        path: item.url,
        query: {
          id: item.id
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.jt-foot-container {
  background: #131916;
  // text-align: center;
  padding: .72rem .4rem .84rem;
  font-size: 0.24rem;
  color: #7B7C7B;

  // ::v-deep .el-collapse-item__header {
  //   padding-left: 0.2rem;
  //   background: #87CA8D;
  //   color: #FFFFFF;
  // }

  // ::v-deep .el-collapse-item__header.is-active {
  //   border-bottom-color: #EBEEF5;
  // }

  // ::v-deep .el-collapse-item__wrap {
  //   background-color: #87CA8D;
  // }
  .allCompony {
    display: flex;
    .rightName {
      margin-left: .08rem;
      li {
        list-style-type: none;
        margin-bottom: .24rem;
      }
    }
  }
  .codeArea {
    width: 1.6rem;
    height: 1.6rem;
    margin: .4rem 0 .32rem;
    img {
      width: 100%;
    }
  }
  .telArea {
    font-weight: 800;
    font-size: .44rem;
    color: #1FCE76;
    margin-bottom: .52rem;
  }
}

.jt-nav-son-item {
  display: flex;
  flex-direction: column;

  .jt-nav-son-item-t {
    margin-top: 0.1rem;
    text-align: left;
    line-height: 0.5rem;
    padding-left: 0.35rem;
    color: #FFFFFF;
    background: #87CA8D;
  }
}

.jt-footmid {
  margin: 0.2rem 0;
  border: 1px solid #FFFFFF;
  padding: 0.3rem 0.3rem 0 0.3rem;
  font-size: 0.18rem;
  color: #FFFFFF;
  display: flex;

  .jt-footmid-t1 {
    font-weight: bold;
  }

  .jt-footmid-t2 {
    flex: 1;
    display: flex;
    flex-wrap: wrap;

    div {
      margin-left: 0.4rem;
      margin-bottom: 0.3rem;
    }
  }
}

.jt-footbtm {
  // line-height: 0.3rem;
  text-align: left;
  margin-top: 0.24rem;
}
</style>