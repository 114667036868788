<template>
  <div class="overHidden">
    <top-pic :name="name" :text="$t('common.base.nav_aboutus_certificate')" :subText="$t('common.base.nav_products_sub')"></top-pic>
    <div class="contentBox phoneClass">
      <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/img/certificate_bg.png" class="image"/>
    </div>
  </div>
</template>
  
<script>
import TopPic from '@/components/TopPic.vue'
export default {
  name: "IndexPage",
  components: {
    TopPic
  },
  data() {
    return {
      page: 1,
      total: 3,
      name: '/certificate',
    }
  },
  computed: {
    zsList() {
      return [
        {
          name: this.$t('pages.company.zs1'),
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companyzs1.jpg'
        },
        {
          name: this.$t('pages.company.zs2'),
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companyzs2.jpg'
        },
        {
          name: this.$t('pages.company.zs3'),
          img: 'https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/companyzs3.jpg'
        }
      ]
    }
  },
  created() {
    console.log(this.$t('pages'))
  },
  methods: {
    goClass() {

    },
    handleSizeChange() {},
    handleCurrentChange() {},
  }
}
</script>
  
<style scoped lang="scss">
.image{
  display: block;
  width: 100%;
  // height: 590px;
  object-fit: cover;
  margin-top: 57px;
  margin-bottom: 60px;
}
  .company-msg {
    width: 15rem;
    margin: .4rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .company-msg-item {
      width: 4.6rem;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 4.6rem;
        height: 3.45rem;
        object-fit: contain;
      }
      div {
        font-size: 0.15rem;
        color: #666;
        line-height: 0.5rem;
      }
    }
  }
  .pagebox {
    display: flex;
    justify-content: center;
    align-content: center;
  }
// 兼容手机
@media screen and (max-width: 756px) {
  .phoneClass {
    overflow: auto;
    .image {
      width: auto;
    }
  }
  .company-msg {
    width: 100%;
    margin: .4rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .company-msg-item {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: .5rem;
      img {
        width: 4.6rem;
        height: 3.45rem;
        object-fit: contain;
      }
      div {
        font-size: 0.15rem;
        color: #666;
        line-height: 0.5rem;
      }
    }
  }
  .pagebox {
    display: none;
  }
}
</style>