<template>
  <div class="container" ref="container">
    <div class="leftBox">
      <img v-show="iconChange" src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/website/index_logow.png" alt="" @click="goHome">
      <img v-show="!iconChange" src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-jt/navlogo.png" alt="" @click="goHome">
    </div>
    <div class="rightBox">
      <div class="leftTabs"
      ref="leftTabs"
      @mouseenter="handleMouseOver"
      @mouseleave="handleMouseLeave">
        <div class="itemBox" 
        
        v-for="(item, index) of headNavList" :key="index">
          <div class="item" @click="goPages(item, index)">
            <p>{{ item.name }}</p>
            <img :class="{ active: current === index }" class="iconImg" src="@/assets/icon/icon_change.png" alt="">
            <!-- <p :class="{ active: current === index }">
            </p> -->
          </div>
          <template v-if="item.children && item.children.length">
            <div class="childrenBox">
              <div class="childItemBox">
                <div class="childItem" v-for="(it, ind) of item.children" :key="ind" @click="goPages(it, index)">
                  <p>{{ it.name }}</p>
                  <p></p>
                </div>
              </div>

            </div>
          </template>
        </div>
      </div>
      <div class="langTabs">
        <!-- <img src="https://slzx-chengc.oss-cn-shenzhen.aliyuncs.com/sljt-webist/icon/lanaguar.png" alt=""> -->
        <!-- <span @click="langSelect('zh')" :class="{ active: lang === 'zh' }">中文</span> -->
        <span @click="langSelect($event,'zh')" :style="{'color': cColor}">中文</span>
        <p></p>
        <span @click="langSelect($event,'en')" :style="{'color': eColor}">EN</span>
      </div>
    </div>
  </div>
</template>

<script>
import { categoryList } from "@/utils/api";
export default {
  props: {
    category: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      categoryLists: [],
      current: '',
      lang: localStorage.getItem('languageSet') || 'zh',
      iconChange: true,
      notTop: false,
      pageChange: false,
      cColor: localStorage.getItem('languageSet') == 'zh' ? '#CBCBCB' : '#262830',
      eColor: localStorage.getItem('languageSet') == 'en' ? '#CBCBCB' : '#262830'
    }
  },
  computed: {
    headNavList() {
      return [
        {
          name: this.$t("common.base.nav_index"),
          url: "/",
          children: [],
        },
        {
          name: this.$t("common.base.nav_aboutus"),
          url: "/introduction",
          children: [
            {
              name: this.$t("common.base.nav_aboutus_intoduction"),
              url: "/introduction",
            },
            {
              name: this.$t("common.base.nav_aboutus_course"),
              url: "/course",
            },
            {
              name: this.$t("common.base.nav_aboutus_certificate"),
              url: "/certificate",
            },
            {
              name: this.$t("common.base.nav_aboutus_culture"),
              url: "/culture",
            },
          ]
        },
        {
          name: this.$t("common.base.nav_products"),
          url: "/product?type=0",
          children: this.category
        },
        {
          name: this.$t("common.base.nav_news"),
          url: "/news?type=0",
          children: [
            {
              name: this.$t("common.base.nav_news_companynews"),
              url: "/news?type=1",
            },
            {
              name: this.$t("common.base.nav_news_technical"),
              url: "/news?type=2",
            },
            {
              name: this.$t("common.base.nav_news_problem"),
              url: "/news?type=3",
            },
          ],
        },
        {
          name: this.$t("common.base.nav_resources"),
          url: "/source",
          children: [
            {
              name: this.$t("common.base.nav_resources_talent"),
              url: "/source",
            },
            {
              name: this.$t("common.base.nav_resources_social"),
              url: "/social",
            },
            {
              name: this.$t("common.base.nav_resources_campus"),
              url: "/campus",
            },
            {
              name: this.$t("common.base.nav_resources_staff"),
              url: "/man",
            },
          ],
        },
        {
          name: this.$t("common.base.nav_contact"),
          url: "/contact",
          children: [
            {
              name: this.$t("common.base.nav_contact_contact"),
              url: "/contact",
            },
            {
              name: this.$t("common.base.nav_contact_message"),
              url: "/message",
            },
          ],
        }
      ]
    }
  },
  watch: {
    '$route': {
      handler: function(newVal,oldVal) {
        if (newVal.path !== oldVal.path) {
          
          this.headNavList.forEach((item,index) => {
            if (item.url == newVal.path || item.url.includes(newVal.path)) {
              if (newVal.path === "/") return this.current = 0
              this.current = index
            } else {
              let arr = item.children.filter(item2=>{
                if (item2.url == newVal.path || item2.url.includes(newVal.path)) {
                  return item2
                }
              })
              if (arr.length > 0) {
                this.current = index
              }
            }
          })
        }
      },
      deep: true
    },
  },
  mounted() {
    // this.getCategoryList()
    this.pageChange = true
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: { 
    changeColor(n) {
      // console.log(n)
      if (this.lang === 'zh') {
        this.eColor = this.cColor
        this.cColor == '#CBCBCB' ? this.cColor = '#262830' : this.cColor = '#CBCBCB'
      } else {
        this.cColor = this.eColor
        this.eColor == '#CBCBCB' ? this.eColor = '#262830' : this.eColor = '#CBCBCB'
      }
    },
    handleScroll() {
      if (window.scrollY === 0) {
        if (this.pageChange) return
        this.$refs.container.style.background = ''
        this.$refs.leftTabs.style.color = '#fff'
        this.iconChange = true
        if (this.$i18n.locale == this.lang) this.changeColor(1)
        this.notTop = false
        return
      }
      this.pageChange = false
      this.$refs.container.style.background = '#fff'
      this.$refs.leftTabs.style.color = '#040404'
      if (!this.notTop && this.$i18n.locale == this.lang) this.changeColor(2)
      this.notTop = true
      this.iconChange = false
    },
    handleMouseOver() {
      this.$refs.container.style.background = '#fff'
      this.$refs.leftTabs.style.color = '#040404'
      if (!this.notTop) this.changeColor(3)
      this.iconChange = false
    },
    handleMouseLeave() {
      if (window.scrollY === 0) {
        this.$refs.container.style.background = ''
        this.$refs.leftTabs.style.color = '#fff'
        if (!this.notTop) this.changeColor(4)
        this.iconChange = true
      }
      
    },
    getCategoryList() {
      this.categoryLists = []
      categoryList({ language: this.$i18n.locale }).then(res => {
        if (res.code === "000000") {
          console.log(res.data,994040494)
          res.data.forEach(item => {
            item.child.forEach(it => {
              this.categoryLists.push({
                name: it.productTypeName,
                id: it.id,
                sort: it.sort
              })
            })
          });
          this.categoryLists.forEach((item, index) => {
            item.url = `/product?type=${index + 1}`
          })
          this.categoryLists.sort((a, b) => a.sort - b.sort)
          console.log(this.categoryLists, 'categoryLists');
        }
      })
    },
    goPages(item, index) {
      this.pageChange = true
      this.current = index
      this.$router.push({
        path: item.url,
        query: {
          id: item.id
        }
      })
    },
    goHome() {
      this.pageChange = true
      this.$router.push('/')
      this.current = 0
    },
    langSelect(e,lang) {
      if (lang == this.lang) return 
      e.stopPropagation()
      this.lang = lang
      this.changeColor()
      this.$i18n.locale = lang;
      localStorage.setItem("languageSet", this.$i18n.locale);
      this.getCategoryList()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100vw;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.6rem;
  position: fixed;
  z-index: 10;

  .leftBox {
    text-align: center;
    display: flex;
    align-items: center;
    // flex: 1;
    margin-right: 1.2rem;

    img {
      width: 1.48rem;
      height: 0.44rem;
    }
  }

  .leftBox:hover {
    cursor: pointer;
  }

  .rightBox {
    flex: 1;
    display: flex;
    align-items: center;

    .leftTabs {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;

      .itemBox {
        flex: 1;
        white-space: nowrap;
        .item {
          height: 0.8rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          p {
            &:first-child {
              font-weight: 400;
              font-size: 0.16rem;
              margin-bottom: 0.04rem;
            }

            &:last-child {
              // width: 0.21rem;
              // height: 0.05rem;
              // background: #00B83C;
              // border-radius: 0.03rem;
              // opacity: 0;
            }
          }
          .iconImg {
            opacity: 0;
          }

          .active {
            opacity: 1 !important;
          }
        }

        .childrenBox {
          width: 100vw;
          position: fixed;
          top: 0.8rem;
          left: 0;
          right: 0;
          z-index: 9;
          background-color: #FFFFFF;
          display: none;

          .childItemBox {
            display: flex;
            align-items: center;
            justify-content: center;

            .childItem {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding: 0.2rem 0.5rem;

              p {
                &:first-child {
                  font-weight: 400;
                  font-size: 0.16rem;
                  color: #040404;
                  margin-bottom: 0.04rem;
                }

                &:last-child {
                  width: 0.21rem;
                  height: 0.05rem;
                  // background: #00B83C;
                  // border-radius: 0.03rem;
                  opacity: 0;
                }
              }
            }

            .childItem:hover {
              cursor: pointer;

              p {
                &:first-child {
                  font-weight: 600;
                }

                &:last-child {
                  opacity: 1;
                  transition: 0.5s;
                }
              }
            }
          }
        }
      }

      .itemBox:hover {
        .item {
          cursor: pointer;

          p {
            &:first-child {
              font-weight: 600;
            }
          }
        }

        .childrenBox {
          display: block;
          border-top: 1px solid #f1f1f1;
        }
      }
    }

    .langTabs {
      display: flex;
      align-items: center;
      height: 0.8rem;
      line-height: 0.8rem;
      margin-left: 3.55rem;

      img {
        width: 0.18rem;
        height: 0.18rem;
        margin-right: 0.1rem;
      }

      span {
        font-weight: 400;
        font-size: 0.16rem;
        color: #CBCBCB;
      }

      .active {
        color: #262830;
      }

      span:hover {
        cursor: pointer;
      }

      p {
        width: 1px;
        height: 0.12rem;
        background-color: #1C1C1C;
        margin: 0 0.1rem;
      }
    }
  }
}
</style>