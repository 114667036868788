
import axios from '@/utils/request';// 这个就是我们上面的http.js文件
// let subPhoneUs = (data)=>post('/slzx/api/tassociatePartner/submitTassociatePartner',data)
// let companyList = (data)=>post('/slzx/api/training/articleTrainingList',data)

export function articleTrainingList(data, options) {
	return axios({
		url: '/training/articleTrainingList',
		method:'post' ,
		data,
		...options
	})
}
export function getTrainingById(params, options) {
	return axios({
		url: '/training/getTrainingById',
		method:'get' ,
		params,
		...options
	})
}
export function categoryList(data, options) {
	return axios({
		url: '/productType/getList',
		method:'post' ,
		data,
		...options
	})
}


export function submitArticleConcat(data, options) {
	return axios({
		url: '/article/concat/submitArticleConcat',
		method:'post' ,
		data,
		...options
	})
}

export function productGetPage(data, options) {
	return axios({
		url: '/product/getPage',
		method:'post' ,
		data,
		...options
	})
}

export function getProductById(params, options) {
	return axios({
		url: '/product/getProductById',
		method:'get' ,
		params,
		...options
	})
}

// export {
//     companyList,
//     subPhoneUs
// }