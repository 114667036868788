<template>
  <div class="pict wow fadeInUp" id="mapContainer" style="width: 100%;flex: 1 1 0;margin-bottom: 20px;"></div>
</template>
<script>
// import siteArr from './img/site'
export default {
  data() {
    return {
      allBusinessList: null, //根据条件获取商户数量
      map: null,
      canvas: null,
      markets: [
  {
    id: 1,
    lng: 114.07103,
    lat: 22.54865,
    
  }],
    }
  },
  created() {},
  mounted() {
    const that = this
    // 2. 创建地图示例
    that.map = new window.BMapGL.Map('mapContainer')
    let point = new window.BMapGL.Point(114.060175, 22.955438)
    // 4. 地图初始化，同时设置地图展示级别
    that.map.centerAndZoom(point, 13)
    // 5. 开启鼠标滚轮缩放
    that.map.enableScrollWheelZoom(true)
    let scaleCtrl = new window.BMapGL.ScaleControl() // 添加比例尺控件
    that.map.addControl(scaleCtrl)
    let zoomCtrl = new window.BMapGL.ZoomControl() // 添加缩放控件
    that.map.addControl(zoomCtrl)
    let cityCtrl = new window.BMapGL.CityListControl() // 添加城市列表控件
    that.map.addControl(cityCtrl);
    let locationCtrl = new window.BMapGL.LocationControl()
    that.map.addControl(locationCtrl);
    // 绑定点击事件
    let geoc = new window.BMapGL.Geocoder();
    this.map.addEventListener('click', function(e) {
      var pt = e.latlng;
      geoc.getLocation(pt, function (rs) {
        let addComp = rs.addressComponents;
        let str = addComp.province + addComp.city + addComp.district + addComp.street + addComp.streetNumber;
        console.log(str)
      });
    });

    that.addmarkets();
  },
  methods: {
    mapSite(e){
      let that = this;
      let opts = {
            width: 320, // 信息窗口宽度
            height: 110, // 信息窗口高度
          };
          let obj = { lng: 114.060175, lat: 22.955438}
          let domS =
            `<div>东莞市盛利能源科技有限公司(总部)<div>` +
            `<div>总部电话: <span  >0769-8271-7542</span></div>` +
            `<div>地址: <span  >广东省东莞市常平镇桥沥南门路40号4栋101室</span></div>` 
            // `</div>
            //   <div style='margin-top:30px; text-align:center;'>
            //     <button id='btn' style='background: #63C26B; width:70px; border-radio:3px; border: 0;color: #fff; font-weight: bold;'>确定</button>
            //   </div>
            // </div>`;
          let infoWindow = new window.BMapGL.InfoWindow(domS, opts); // 创建信息窗口对象
          that.map.openInfoWindow(infoWindow, obj); // 打开信息窗口
          // setTimeout(() => {
          //   document.getElementById("btn").onclick = () => {
          //     // 确认事件
          //       that.longitude = String(lng).substr(0,9);
          //       that.latitude = String(lat).substr(0,9);
          //     that.mapBol = false;
          //   };
          // }, 100);
          // 后面是阻止事件冒泡的代码
          if (e && e.domEvent) {
            e.domEvent.stopPropagation();
          }
          if (e&& e.stopPropagation) {
            //W3C阻止冒泡方法
            e.stopPropagation();
          } else if(e){
            e.cancelBubble = true; //IE阻止冒泡方法
          }
          return false;
    },
    addmarkets() {
      const that = this
      let arr = that.markets
      arr.forEach((item) => {
        // console.log(item)
        // let icon = new window.BMapGL.Icon(require('./img/gui.png'), new window.BMapGL.Size(30,30));
        //设置标注的经纬度
        // let marker = new window.BMapGL.Marker(new window.BMapGL.Point(item.lng, item.lat),{ icon: icon });
        let marker = new window.BMapGL.Marker(new window.BMapGL.Point(114.060175, 22.955438));
        // marker.customData({"Size": "30"})
        let clabel = new window.BMapGL.Label(item.id, {offset: new window.BMapGL.Size(20, 0)}); //创建marker点的标记,这里注意下,因为百度地图可以对label样式做编辑,所以我这里吧重要的id放在了label(然后再隐藏)   
        clabel.setStyle({ display: "none" });//对label 样式隐藏    
        marker.setLabel(clabel);  //把label设置到maker上  
        this.mapSite();
        // marker.setTitle(item.address); //这里设置maker的title (鼠标放到marker点上,会出现它的title,所以我这里把name,放到title里) 
        marker.addEventListener('click', (e) => {
          this.mapSite(e);
        });
        // 把标注添加到地图上
        that.map.addOverlay(marker);
   
      // .onclick();
      })
    },
  },
}
</script>
<style scoped>
.pict {
  width: 1500px;
  height: 440px;
  overflow: hidden;
  margin: 0;
  font-family: '微软雅黑';
}
@media screen and (max-width: 756px) {
  .pict {
  width: 100%;
  height: 3.42rem;
  overflow: hidden;
  margin: 0;
  font-family: '微软雅黑';
}
  }
</style>
